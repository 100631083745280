// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Modal, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import ApiUrl from "../Environment/ApiUrl";
// import { toast } from "react-toastify";

// function Leave() {
//   const [showModal, setShowModal] = useState(false);
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [employees, setEmployees] = useState([]);
//   const [leaveData, setLeaveData] = useState([]); // New state for leave data
//   const [addData, setAddData] = useState({
//     branch_id: "",
//     empid: "",
//     type: "",
//     date: "",
//     from_date: "",
//     to_date: "",
//     reason: "",
//     status: "",
//   });

//   // Fetch Branch data
//   const fetchBranchData = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/Branch");
//       setResponseDatas(response.data);
//     } catch (error) {
//       console.error("Error fetching branch data:", error.message);
//     }
//   };

//   // Fetch Employee data
//   const fetchEmployeeData = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/employees");
//       setEmployees(response.data);
//     } catch (error) {
//       console.error("Error fetching employee data:", error.message);
//     }
//   };

//   // Fetch Leave data
//   const fetchLeaveData = async () => {
//     try {
//       const response = await axios.get(ApiUrl + "/leave"); // Replace with your API endpoint for fetching leave data
//       setLeaveData(response.data);
//     } catch (error) {
//       console.error("Error fetching leave data:", error.message);
//     }
//   };

//   useEffect(() => {
//     fetchBranchData();
//     fetchEmployeeData();
//     fetchLeaveData(); // Call to fetch leave data when component mounts
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setAddData({
//       ...addData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = { ...addData };
//       const response = await axios.post(ApiUrl + "/leave", formData);
//       console.log("Leave Added:", response);
//       setShowModal(false);
//       toast.success("Leave added successfully!");
//       setAddData({
//         branch_id: "",
//         empid: "",
//         type: "",
//         date: "",
//         from_date: "",
//         to_date: "",
//         reason: "",
//         status: "",
//       });
//       fetchLeaveData(); // Refresh the leave data after adding a new leave
//     } catch (error) {
//       console.error("Error adding leave:", error.message);
//       toast.error("Error adding leave!");
//     }
//   };

//   return (
//     <>
//       <div className="top">
//         <div className="" style={{ marginTop: "-80px" }}>
//           <div className="row mb-3">
//             <div className="col-lg-11">
//               <Link to="/dashboard" className="text-secondary dashBoard_text">
//                 <b>Dashboard / </b>
//               </Link>
//               <Link to="" className="text-danger dashBoard_text">
//                 <b>Leave</b>
//               </Link>
//             </div>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-lg-9 d-flex">
//             <h5 className="mt-2">Leave Details</h5>
//             <div className="mx-3">
//               <button
//                 className="btn btn-success add mx-3"
//                 onClick={() => setShowModal(true)}
//               >
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>

//         <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className="form-label text-dark mt-2">Show : </label>
//               <div>
//                 <select
//                   className="form-control w-100 mx-2"
//                   // onChange={(e) => {
//                   //   setItemsPerPage(e.target.value);
//                   // }}
//                 >
//                   <option value="10">10</option>
//                   <option value="20">20</option>
//                   <option value="50">50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">
//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                       placeholder="Search ..."
//                       type="search"
//                       // value={searchQuery}
//                       // onChange={handleSearchChange}
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Table */}
//         <div className="card">
//           <div className="mt-1">
//             <table className="table table-striped">
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   <th>Branch</th>
//                   <th>Staff Id</th>
//                   <th>Date</th>
//                   <th>From Date</th>
//                   <th>To Date</th>
//                   <th>Reason</th>
//                   <th>Status</th>
//                   <th className="text-center">Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {/* Map through the leaveData and display each leave record */}
//                 {leaveData.map((leave, index) => (
//                   <tr key={leave._id}>
//                     <td>{index + 1}</td>
//                     <td>
//                       {leave.branch_id ? leave.branch_id.branch_name : "N/A"}
//                     </td>
//                     <td>{leave.empid ? leave.empid.empid : "N/A"}</td>
//                     <td>{leave.date}</td>
//                     <td>{leave.from_date}</td>
//                     <td>{leave.to_date}</td>
//                     <td>{leave.reason}</td>
//                     <td>{leave.status}</td>
//                     <td className="text-center">
//                       <button className="btn btn-primary mx-1">
//                         <i class="fa-solid fa-pen-to-square"></i>
//                       </button>
//                       <button className="btn btn-danger mx-1">
//                         <i class="fa-solid fa-trash"></i>
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Modal for adding a new leave */}
//         <Modal show={showModal} onHide={() => setShowModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Add Leave</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             {/* Branch Selection */}
//             <div className="form-group">
//               <label>Branch</label>
//               <select
//                 name="branch_id"
//                 className="form-select"
//                 value={addData.branch_id}
//                 onChange={handleInputChange}
//                 required
//               >
//                 <option value="">Select Branch</option>
//                 {responseDatas.map((item) => (
//                   <option key={item._id} value={item._id}>
//                     {item.branch_id} - {item.branch_name}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             {/* Employee Selection */}
//             <div className="form-group">
//               <label>Employee</label>
//               <select
//                 name="empid"
//                 className="form-select"
//                 value={addData.empid}
//                 onChange={handleInputChange}
//                 required
//               >
//                 <option value="">Select Employee</option>
//                 {employees.map((emp) => (
//                   <option key={emp._id} value={emp._id}>
//                     {emp.name} - {emp.empid}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             {/* Leave Type */}
//             <div className="form-group">
//               <label>Leave Type</label>
//               <select
//                 name="type"
//                 className="form-select"
//                 value={addData.type}
//                 onChange={handleInputChange}
//                 required
//               >
//                 <option value="">Select Type</option>
//                 <option value="SickLeave">Sick Leave</option>
//                 <option value="CasualLeave">Casual Leave</option>
//                 <option value="Others">Others</option>
//               </select>
//             </div>

//             {/* Leave Dates */}
//             <div className="form-group">
//               <label>Date</label>
//               <input
//                 type="date"
//                 name="date"
//                 className="form-control"
//                 value={addData.date}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>

//             <div className="form-group">
//               <label>From Date</label>
//               <input
//                 type="date"
//                 name="from_date"
//                 className="form-control"
//                 value={addData.from_date}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>

//             <div className="form-group">
//               <label>To Date</label>
//               <input
//                 type="date"
//                 name="to_date"
//                 className="form-control"
//                 value={addData.to_date}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>

//             {/* Reason */}
//             <div className="form-group">
//               <label>Reason</label>
//               <input
//                 type="text"
//                 name="reason"
//                 className="form-control"
//                 value={addData.reason}
//                 onChange={handleInputChange}
//                 required
//               />
//             </div>

//             {/* Status */}
//             <div className="form-group">
//               <label>Status</label>
//               <select
//                 name="status"
//                 className="form-select"
//                 value={addData.status}
//                 onChange={handleInputChange}
//                 required
//               >
//                 <option value="">Select Status</option>
//                 <option value="Approved">Approved</option>
//                 <option value="Pending">Pending</option>
//                 <option value="Rejected">Rejected</option>
//               </select>
//             </div>
//           </Modal.Body>

//           <Modal.Footer>
//             <Button
//               className="cancelbut"
//               variant="secondary"
//               onClick={() => setShowModal(false)}
//             >
//               Cancel
//             </Button>
//             <Button
//               className="submitbut"
//               variant="primary"
//               onClick={handleSubmit}
//             >
//               Submit
//             </Button>
//           </Modal.Footer>
//         </Modal>
//       </div>
//     </>
//   );
// }

// export default Leave;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";

function Leave() {
  const [showModal, setShowModal] = useState(false);
  const [responseDatas, setResponseDatas] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [leaveData, setLeaveData] = useState([]); // New state for leave data
  const [addData, setAddData] = useState({
    branch_id: "",
    empid: "",
    type: "",
    date: "",
    from_date: "",
    to_date: "",
    reason: "",
    status: "",
  });

  // New states for update and delete modals
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Fetch Branch data
  const fetchBranchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Branch");
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching branch data:", error.message);
    }
  };

  // Fetch Employee data
  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/employees");
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employee data:", error.message);
    }
  };

  // Fetch Leave data
  const fetchLeaveData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/leave");
      setLeaveData(response.data);
    } catch (error) {
      console.error("Error fetching leave data:", error.message);
    }
  };

  useEffect(() => {
    fetchBranchData();
    fetchEmployeeData();
    fetchLeaveData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddData({
      ...addData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = { ...addData };
      const response = await axios.post(ApiUrl + "/leave", formData);
      toast.success("Leave added successfully!");
      setAddData({
        branch_id: "",
        empid: "",
        type: "",
        date: "",
        from_date: "",
        to_date: "",
        reason: "",
        status: "",
      });
      fetchLeaveData();
      setShowModal(false);
    } catch (error) {
      toast.error("Error adding leave!");
    }
  };

  // Handle Update Leave
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = { ...addData };
      const response = await axios.put(
        ApiUrl + `/leave/${selectedLeave._id}`,
        formData
      );
      toast.success("Leave updated successfully!");
      fetchLeaveData();
      setShowUpdateModal(false);
      setSelectedLeave(null);
    } catch (error) {
      toast.error("Error updating leave!");
    }
  };

  // Handle Delete Leave
  const handleDelete = async () => {
    try {
      await axios.delete(ApiUrl + `/leave/${selectedLeave._id}`);
      toast.success("Leave deleted successfully!");
      fetchLeaveData();
      setShowDeleteModal(false);
      setSelectedLeave(null);
    } catch (error) {
      toast.error("Error deleting leave!");
    }
  };

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Leave</b>
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-9 d-flex">
            <h5 className="mt-2">Leave Details</h5>
            <div className="mx-3">
              <button
                className="btn addBtn add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  // onChange={(e) => {
                  //   setItemsPerPage(e.target.value);
                  // }}
                >
                  {/* <option value='5'>5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      // value={searchQuery}
                      // onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Branch</th>
                  <th>Staff Id</th>
                  <th>Date</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Reason</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {leaveData.map((leave, index) => (
                  <tr key={leave._id}>
                    <td>{index + 1}</td>
                    <td>
                      {leave.branch_id ? leave.branch_id.branch_name : "N/A"}
                    </td>
                    <td>{leave.empid ? leave.empid.empid : "N/A"}</td>
                    <td>{new Date(leave.date).toLocaleDateString()}</td>{" "}
                    {/* Format Date here */}
                    <td>
                      {new Date(leave.from_date).toLocaleDateString()}
                    </td>{" "}
                    {/* Format Date here */}
                    <td>{new Date(leave.to_date).toLocaleDateString()}</td>{" "}
                    {/* Format Date here */}
                    <td>{leave.reason}</td>
                    <td>{leave.status}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-primary mx-1"
                        onClick={() => {
                          setShowUpdateModal(true);
                          console.log("Edit button clicked for ID:", leave._id);
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btn btn-danger mx-1"
                        onClick={() => {
                          setSelectedLeave(leave);
                          setShowDeleteModal(true);
                          console.log(
                            "Delete button clicked for ID:",
                            leave._id
                          );
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal for adding a new leave */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Leave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Branch Selection */}
            <div className="form-group">
              <label>Branch</label>
              <select
                name="branch_id"
                className="form-select"
                value={addData.branch_id}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Branch</option>
                {responseDatas.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.branch_id} - {item.branch_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Employee Selection */}
            <div className="form-group">
              <label>Employee</label>
              <select
                name="empid"
                className="form-select"
                value={addData.empid}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Employee</option>
                {employees.map((emp) => (
                  <option key={emp._id} value={emp._id}>
                    {emp.name} - {emp.empid}
                  </option>
                ))}
              </select>
            </div>

            {/* Leave Type */}
            <div className="form-group">
              <label>Leave Type</label>
              <select
                name="type"
                className="form-select"
                value={addData.type}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Type</option>
                <option value="SickLeave">Sick Leave</option>
                <option value="CasualLeave">Casual Leave</option>
                <option value="Others">Others</option>
              </select>
            </div>

            {/* Leave Dates */}
            <div className="form-group">
              <label>Date</label>
              <input
                type="date"
                name="date"
                className="form-control"
                value={addData.date}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>From Date</label>
              <input
                type="date"
                name="from_date"
                className="form-control"
                value={addData.from_date}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>To Date</label>
              <input
                type="date"
                name="to_date"
                className="form-control"
                value={addData.to_date}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Reason */}
            <div className="form-group">
              <label>Reason</label>
              <input
                type="text"
                name="reason"
                className="form-control"
                value={addData.reason}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Status */}
            <div className="form-group">
              <label>Status</label>
              <select
                name="status"
                className="form-select"
                value={addData.status}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Status</option>
                <option value="Approved">Approved</option>
                <option value="Pending">Pending</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Update Leave Modal */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Leave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Same as above but filled with selectedLeave data */}
            <div className="form-group">
              <label>Branch</label>
              <select
                name="branch_id"
                className="form-select"
                value={addData.branch_id}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Branch</option>
                {responseDatas.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.branch_id} - {item.branch_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Employee Selection */}
            <div className="form-group">
              <label>Employee</label>
              <select
                name="empid"
                className="form-select"
                value={addData.empid}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Employee</option>
                {employees.map((emp) => (
                  <option key={emp._id} value={emp._id}>
                    {emp.name} - {emp.empid}
                  </option>
                ))}
              </select>
            </div>

            {/* Leave Type */}
            <div className="form-group">
              <label>Leave Type</label>
              <select
                name="type"
                className="form-select"
                value={addData.type}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Type</option>
                <option value="SickLeave">Sick Leave</option>
                <option value="CasualLeave">Casual Leave</option>
                <option value="Others">Others</option>
              </select>
            </div>

            {/* Leave Dates */}
            <div className="form-group">
              <label>Date</label>
              <input
                type="date"
                name="date"
                className="form-control"
                value={addData.date}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>From Date</label>
              <input
                type="date"
                name="from_date"
                className="form-control"
                value={addData.from_date}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>To Date</label>
              <input
                type="date"
                name="to_date"
                className="form-control"
                value={addData.to_date}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Reason */}
            <div className="form-group">
              <label>Reason</label>
              <input
                type="text"
                name="reason"
                className="form-control"
                value={addData.reason}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Status */}
            <div className="form-group">
              <label>Status</label>
              <select
                name="status"
                className="form-select"
                value={addData.status}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Status</option>
                <option value="Approved">Approved</option>
                <option value="Pending">Pending</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowUpdateModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleUpdateSubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Leave</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this leave record?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="danger"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Leave;
