// import React, { useState } from "react";
// import { Modal, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";

// function Office_Expense() {
//   const [showModal, setShowModal] = useState(false);

//   return (
//     <>
//       <div className="top">
//         <div className="" style={{ marginTop: "-80px" }}>
//           <div className="row mb-3">
//             <div className="col-lg-11">
//               <Link to="/dashboard" className="text-secondary dashBoard_text">
//                 <b>Dashboard / </b>
//               </Link>
//               <Link to="" className="text-danger dashBoard_text">
//                 <b>Office Expense</b>
//               </Link>
//             </div>
//           </div>
//         </div>
//         {/* Table */}
//         <div className="d-flex">
//           <h5 className="mt-2">Office Expense</h5>
//           <div className="mx-3">
//             <button
//               className="btn btn-success add mx-3"
//               onClick={() => setShowModal(true)}
//             >
//               Add
//             </button>
//           </div>
//         </div>

//         {/* ------------ search and per page ----------------------------*/}

//         <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className="form-label text-dark mt-2">Show : </label>
//               <div>
//                 <select className="form-control w-100 mx-2">
//                   {/* <option value='5'>5</option> */}
//                   <option value="10">10</option>
//                   <option value="20">20</option>
//                   <option value="50">50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">
//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                       placeholder="Search ..."
//                       type="search"
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* --------------------------------end------------------------------------------- */}
//         <div className="card">
//           <div className="mt-1">
//             <table className="table table-striped ">
//               <thead>
//                 <tr>
//                   <th>S.No</th>

//                   <th>Name</th>
//                   <th>Mobile</th>
//                   <th>Email</th>
//                   <th>Company Name</th>
//                   <th className="text-center">Action</th>
//                 </tr>
//               </thead>

//               <tbody>
//                 <tr>
//                   <td>1</td>
//                   <td>Sri</td>
//                   <td>7441855263</td>
//                   <td>sri@gmail.com</td>
//                   <td>ATS</td>
//                   <td>
//                     <button className="btn updelete mx-2">
//                       <i className="fa-solid fa-pen-to-square"></i>
//                     </button>

//                     <button className="btn delete">
//                       <i className="fa-solid fa-trash-can"></i>
//                     </button>
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Modal for adding a new user */}
//         <Modal show={showModal} onHide={() => setShowModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Add Office Expense </Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <div className="container">
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div className="form-group">
//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">Name</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">Mobile</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">Email</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">Company Name</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">IFSC Code</label>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="form-group">
//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">Address</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">Service</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">Account Number</label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                       <label className="">Bank Branch Name</label>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Modal.Body>

//           <Modal.Footer>
//             <Button
//               className="cancelbut"
//               variant="secondary"
//               onClick={() => setShowModal(false)}
//             >
//               Cancel
//             </Button>
//             <Button className="submitbut" variant="primary">
//               Submit
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         {/* _------------------------------pagination button -------------------------------- */}

//         <div className="mt-3 pb-5">
//           <div className="row">
//             <div className="col-lg-10 col-sm-12">
//               <nav className="d-flex float-right">
//                 <ul className="pagination justify-content-center">
//                   <li className="">
//                     <button className="page-link">Previous</button>
//                   </li>

//                   <li className="">
//                     <button className="page-link ">1</button>
//                   </li>

//                   <li className="">
//                     <button className="page-link ">Next</button>
//                   </li>
//                 </ul>
//               </nav>{" "}
//             </div>
//             <div className="col-lg-2 col-sm-12 mt-1">
//               <div className="float-end">
//                 <span className="text-dark ">Page 1 of 1</span>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* ---------------------------------------end ---------------------------------- */}
//       </div>
//     </>
//   );
// }

// export default Office_Expense;



import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


//  attention  this is backend file name called expense_details 

function Office_Expense() {
    const [responseData, setResponseData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [generateempidData, setgenerateempidData] = useState([]);
    const [previllages, setprevillages] = useState({});
    const [branch, setbranch] = useState([]);
    const [role_id, setroleid] = useState("");

    const [addData, setAddData] = useState({
        branch_id: "",
        expense_code: "",
        name: "",
        mobileno: "",
        email_id: "",
        company_name: "",

        address: "",
        service: "",
        account_no: "",
        bank_branch_name: "",
        ifsc_code: "",
    });

    const [updateData, setUpdateData] = useState({
        branch_id: "",
        expense_code: "",
        name: "",
        mobileno: "",
        email_id: "",
        company_name: "",

        address: "",
        service: "",
        account_no: "",
        bank_branch_name: "",
        ifsc_code: "",
    });

    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        gettoken();
        fetchD();
        fetchDataed();
    }, []);


    const fetchD = async () => {
        try {
            const response = await axios.get(apiUrl + "/generate_expense_code");
            console.log("Response from server generate:", response.data.empid);
            setgenerateempidData(response.data.expense_code);
            addData.expense_code = response.data.expense_code;
            console.log("response", response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };



    const gettoken = async () => {
        const token = localStorage.getItem("token");
        // console.log("the toekn in the localStorage is : ",token);
        try {
            const response = await axios.post(apiUrl + "/get_detail", {
                token: token,
            });
            console.log("token detail from server:", response.data);
            // setResponseData(response.data);

            console.log("The role id is : ", response.data.detail.rolename);
            setroleid(response.data.detail.rolename);
            console.log("the role_id is : ", role_id);

            localStorage.setItem("role_id", response.data.detail.rolename);

            const id = response.data.detail.rolename;

            // getpages();

            if (id) {
                try {
                    const response = await axios.get(apiUrl + `/role_2/${id}`);

                    console.log("the reponse list in brach is : ", response.data.modules);

                    const modules = response.data.modules;

                    const filtered_access = modules.filter(
                        (item) => item.name == "Branch"
                    );

                    console.log("the modules is : ", filtered_access[0].actions);

                    setprevillages(filtered_access[0].actions);

                    console.log("the assigned previllages is : ", previllages.add);
                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/Officeexpense");
            console.log("Response from server:", response.data);
            setResponseData(response.data.reverse());
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const fetchDataed = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Response from server :", response.data);
            setbranch(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const handlesubmit = async (e) => {
        e.preventDefault();

        // if (!validateFields()) {
        //     return;
        // }
        try {
            const formData = {
                branch_id: addData.branch_id,
                expense_code: addData.expense_code,
                name: addData.name,
                mobileno: addData.mobileno,
                email_id: addData.email_id,
                company_name: addData.company_name,

                address: addData.address,
                service: addData.service,
                account_no: addData.account_no,
                bank_branch_name: addData.bank_branch_name,
                ifsc_code: addData.ifsc_code,
            };
            const response = await axios.post(apiUrl + "/Officeexpense", formData);
            console.log("add:", response);
            setShowModal(false);
            toast.success("added successfully!");
            setAddData({
                branch_id: "",
                expense_code: "",
                name: "",
                mobileno: "",
                email_id: "",
                company_name: "",

                address: "",
                service: "",
                account_no: "",
                bank_branch_name: "",
                ifsc_code: "",
            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };

    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (_id, data) => {
        setUpdateId(_id);
        console.log(" the data to updata", data);
        setUpdateShowModal(true);
        setUpdateData(data);
    };

    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                branch_id: updateData.branch_id,
                expense_code: updateData.expense_code,
                name: updateData.name,
                mobileno: updateData.mobileno,
                email_id: updateData.email_id,
                company_name: updateData.company_name,

                address: updateData.address,
                service: updateData.service,
                account_no: updateData.account_no,
                bank_branch_name: updateData.bank_branch_name,
                ifsc_code: updateData.ifsc_code,
            };
            console.log("the update submit data", formData);
            const response = await axios.put(
                apiUrl + `/Officeexpense/${updateid}`,
                formData
            );
            console.log("response for update", response);
            setUpdateShowModal(false);
            toast.success("Update successfully!");

            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                apiUrl + `/Officeexpense/${getdeleteid}`
            );
            console.log("delete data ", response);
            toast.error("Delete successfully!");
            setDeleteShowModals(false);
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };

    const [deleteShowModals, setDeleteShowModals] = useState(false);
    const handleCloseModal = () => {
        setDeleteShowModals(false);
    };

    const [getdeleteid, setdeleteid] = useState("");
    const [getdeletename, setdeletename] = useState("");

    const handleOpenModal = (_id, expense_code) => {
        console.log(" the id", _id);
        console.log(" the name", expense_code);
        setdeleteid(_id);
        setdeletename(expense_code);
        setDeleteShowModals(true);
    };

    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                (typeof value === "string" &&
                    value.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    // the pagination  end ---------------------------------------------------------------------

    // const [errors, setErrors] = useState({});

    // const validateFields = () => {
    //     let tempErrors = {};
    //     let isValid = true;

    //     if (!addData.expense_code) {
    //         tempErrors.expense_code = "expense Code is required.";
    //         isValid = false;
    //     }

    //     if (!addData.name) {
    //         tempErrors.name = "Name is required.";
    //         isValid = false;
    //     }
    //     setErrors(tempErrors);
    //     return isValid;
    // };

    return (
        <>
            <div className="top">
                <div className="" style={{ marginTop: "-80px" }}>
                    <div className="row mb-3">
                        <div className="col-lg-11">
                            <Link to="/dashboard" className="text-secondary dashBoard_text">
                                <b>Dashboard / </b>
                            </Link>
                            <Link to="" className="text-danger dashBoard_text">
                                <b>Office Expense</b>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* Table */}
                <div className="d-flex">
                    <h5 className="mt-2">Office Expense</h5>
                    <div className="mx-3">
                        {/* {previllages.add && ( */}
                            <button
                                className="btn addBtn add mx-3"
                                onClick={() => setShowModal(true)}
                            >
                                Add
                            </button>
                        {/* )} */}
                    </div>
                </div>

                {/* ------------ search and per page ----------------------------*/}

                <div className="mb-3 mt-3">
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                            <label className="form-label text-dark mt-2">Show : </label>
                            <div>
                                <select
                                    className="form-control w-100 mx-2"
                                    onChange={(e) => {
                                        setItemsPerPage(e.target.value);
                                    }}
                                >
                                    {/* <option value='5'>5</option> */}
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-4 col-sm-1"></div>
                        <div className="col-lg-3 col-md-4 col-sm-7">
                            <div className="w-full flex-1">
                                <form>
                                    <div className="relative">
                                        <input
                                            className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                            placeholder="Search ..."
                                            type="search"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --------------------------------end------------------------------------------- */}
                <div className="card">
                    <div className="mt-1">
                        <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th>S.No</th>

                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Company Name</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {filtered.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>

                                        <td>{item.name}</td>
                                        <td>{item.mobileno}</td>
                                        <td>{item.email_id}</td>
                                        <td>{item.company_name}</td>
                                        <td className="text-center">
                                            {/* {previllages.edit && ( */}
                                                <button
                                                    className="btn updelete mx-2"
                                                    onClick={() => handleUpdate(item._id, item)}
                                                >
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            {/* )} */}

                                            {/* {previllages.delete && ( */}
                                                <button
                                                    className="btn delete"
                                                    onClick={() =>
                                                        handleOpenModal(item._id, item.expense_code)
                                                    }
                                                >
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </button>
                                            {/* )} */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Modal for adding a new user */}
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Office Expense </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">

                                        <div className="floating-label">
                                            <select
                                                className="form-select"
                                                value={addData.branch_id}
                                                onChange={(e) =>
                                                    setAddData({ ...addData, branch_id: e.target.value })
                                                }
                                                required
                                            >
                                                <option value="">Select Branch</option>
                                                {branch.map((item, index) => (
                                                    <option key={index} value={item._id}>
                                                        {item.branch_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={addData.branch_id ? "filled" : ""}>
                                                Select Branch
                                            </label>

                                        </div>



                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.name}
                                                onChange={(e) =>
                                                    setAddData({ ...addData, name: e.target.value })
                                                }
                                                required
                                            />
                                            <label className={addData.name ? "filled" : ""}>
                                                Name
                                            </label>
                                            {/* {errors.name && (
                                                <div className="error-text">{errors.name}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.mobileno}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        mobileno: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.mobileno ? "filled" : ""}>
                                                Mobile
                                            </label>
                                            {/* {errors.mobileno && (
                                                <div className="error-text">{errors.mobileno}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.email_id}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        email_id: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.email_id ? "filled" : ""}>
                                                Email
                                            </label>
                                            {/* {errors.email_id && (
                                                <div className="error-text">{errors.email_id}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.company_name}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        company_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.company_name ? "filled" : ""}>
                                                Company Name
                                            </label>
                                            {/* {errors.company_name && (
                                                <div className="error-text">{errors.company_name}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.ifsc_code}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        ifsc_code: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.ifsc_code ? "filled" : ""}>
                                                IFSC Code
                                            </label>
                                            {/* {errors.ifsc_code && (
                                                <div className="error-text">{errors.ifsc_code}</div>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">


                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.address}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        address: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.address ? "filled" : ""}>
                                                Address
                                            </label>
                                            {/* {errors.address && (
                                                <div className="error-text">{errors.address}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.service}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        service: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.service ? "filled" : ""}>
                                                Service
                                            </label>
                                            {/* {errors.service && (
                                                <div className="error-text">{errors.service}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.account_no}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        account_no: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={addData.account_no ? "filled" : ""}>
                                                Account Number
                                            </label>
                                            {/* {errors.account_no && (
                                                <div className="error-text">{errors.account_no}</div>
                                            )} */}
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={addData.bank_branch_name}
                                                onChange={(e) =>
                                                    setAddData({
                                                        ...addData,
                                                        bank_branch_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label
                                                className={addData.bank_branch_name ? "filled" : ""}
                                            >
                                                Bank Branch Name
                                            </label>
                                            {/* {errors.bank_branch_name && (
                                                <div className="error-text">
                                                    {errors.bank_branch_name}
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="cancelbut"
                            variant="secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="submitbut"
                            variant="primary"
                            onClick={handlesubmit}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={isUpdateshowModal}
                    onHide={() => setUpdateShowModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Office Expense</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Other input fields */}

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">


                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                placeholder=""
                                                type="text"
                                                value={updateData.branch_name}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        branch_id: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.branch_id ? "filled" : ""}>
                                                Branch
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.name}
                                                onChange={(e) =>
                                                    setUpdateData({ ...updateData, name: e.target.value })
                                                }
                                                required
                                            />
                                            <label className={updateData.name ? "filled" : ""}>
                                                Name
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder=""
                                                value={updateData.mobileno}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        mobileno: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.mobileno ? "filled" : ""}>
                                                Mobile no
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.email_id}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        email_id: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.email_id ? "filled" : ""}>
                                                Email Id
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.company_name}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        company_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.company_name ? "filled" : ""}>
                                                Company Name
                                            </label>
                                        </div>





                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.address}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        address: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.address ? "filled" : ""}>
                                                Address
                                            </label>
                                        </div>



                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">





                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.service}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        service: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.service ? "filled" : ""}>
                                                Service
                                            </label>
                                        </div>

                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.account_no}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        account_no: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.account_no ? "filled" : ""}>
                                                Account No
                                            </label>
                                        </div>
                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.bank_branch_name}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        bank_branch_name: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.bank_branch_name ? "filled" : ""}>
                                                Branch Name
                                            </label>
                                        </div>
                                        <div className="floating-label">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                value={updateData.ifsc_code}
                                                onChange={(e) =>
                                                    setUpdateData({
                                                        ...updateData,
                                                        ifsc_code: e.target.value,
                                                    })
                                                }
                                                required
                                            />
                                            <label className={updateData.ifsc_code ? "filled" : ""}>
                                                IFSC Code
                                            </label>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            className="cancelbut"
                            variant="secondary"
                            onClick={() => setUpdateShowModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="submitbut"
                            variant="primary"
                            onClick={handleupdatesubmit}
                        >
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* ----------------delete */}

                <Modal
                    className="modal"
                    show={deleteShowModals}
                    onHide={handleCloseModal}
                >
                    <Modal.Header>
                        <p>Are you sure you want to Delete?</p>
                    </Modal.Header>
                    <Modal.Body>
                        <label className="form-label d-flex">
                            <h5 className="mx-1"> Name :</h5> <h5>{getdeletename} </h5>
                        </label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="cancelbut"
                            variant="secondary"
                            onClick={handleCloseModal}
                        >
                            Close
                        </Button>
                        <Button
                            className="submitbut"
                            variant="primary"
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* _------------------------------pagination button -------------------------------- */}

                <div className="mt-3 pb-5">
                    <div className="row">
                        <div className="col-lg-10 col-sm-12">
                            <nav className="d-flex float-right">
                                <ul className="pagination justify-content-center">
                                    <li
                                        className={` page-item ${currentPage === 1 ? "disabled" : ""
                                            }`}
                                    >
                                        <button className="page-link" onClick={goToPrevPage}>
                                            Previous
                                        </button>
                                    </li>
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                            key={i}
                                            className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                                }`}
                                        >
                                            <button
                                                className="page-link "
                                                onClick={() => paginate(i + 1)}
                                            >
                                                {i + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li
                                        className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                            }`}
                                    >
                                        <button className="page-link " onClick={goToNextPage}>
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>{" "}
                        </div>
                        <div className="col-lg-2 col-sm-12 mt-1">
                            <div className="float-end">
                                <span className="text-dark ">
                                    Page {currentPage} of {totalPages}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------end ---------------------------------- */}
            </div>
        </>
    );
}

export default Office_Expense;
