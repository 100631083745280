import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "../../assets/css/updelete.css";
import "../../assets/css/user.css";
import { Link } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";

function User() {
  const [responseData, setResponseData] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [getrole, setrole] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [showModalspassword, setShowModalspassword] = useState(false);
  const [addData, setAddData] = useState({
    branch_id: "",
    username: "",
    email: "",
    mobile: "",
    designation: "",
    password: "",
    status: "",
    role: "",
    emp_name: "",
  });

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    username: "",
    email: "",
    mobile: "",
    designation: "",
    password: "",
    status: "",
    role: "",
  });

  // const [checkboxdata, setcheckboxdata] = useState("staff");

  const [empdata, setempdata] = useState([]);
  const [rolename, setrolename] = useState([]);

  useEffect(() => {
    gettoken();
    const rolename = localStorage.getItem("rolename");
    setrolename(rolename);
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/user");
      console.log("Response from server:empid_detail", response);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchDatas();
    fetchDatased();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDatased = async () => {
    try {
      const response = await axios.get(ApiUrl + "/role_2");
      console.log("Response from server :", response.data);
      setrole(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(ApiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      console.log("The role id is : ", response.data.detail.rolename);
      setroleid(response.data.detail.rolename);
      console.log("the role_id is : ", role_id);
      localStorage.setItem("role_id", response.data.detail.rolename);
      const id = response.data.detail.rolename;
      if (id) {
        try {
          const response = await axios.get(ApiUrl + `/role_2/${id}`);
          console.log("the reponse list in brach is : ", response.data.modules);
          const modules = response.data.modules;
          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );
          console.log("the modules is : ", filtered_access[0].actions);
          setprevillages(filtered_access[0].actions);
          console.log("the assigned previllages is : ", previllages.add);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("addd data", addData);
      const formData = {
        branch_id: addData.branch_id,
        username: addData.username,
        email: addData.email,
        mobile: addData.mobile,
        password: addData.password,
        status: addData.status,
        role: addData.role,
        emp_name: addData.emp_name,
        designation: addData.designation,
      };

      console.log("the addData is : ", addData);
      console.log("the form data is : ", formData);
      const response = await axios.post(ApiUrl + "/user", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("User added successfully!");
      setAddData({
        branch_id: "",
        username: "",
        email: "",
        mobile: "",
        designation: "",
        password: "",
        status: "",
        role: "",
        emp_name: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setUpdateData({ ...updateData, password: newPassword });
    console.log(":this is my length console", newPassword.length > 0);
    setIsPasswordChanged(newPassword.length > 0);
  };

  const [updateid, setUpdateId] = useState("");
  const [designationnew, setdesignationnew] = useState("");

  const [empdetail, setempdetail] = useState("");
  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log(" the data to update", data);
    console.log(" the data to update empid_detail", data.empid_detail);
    setempdetail(data.empid_detail);
    console.log(
      " the data to update designation",
      data.designation[0]?.designation
    );
    setdesignationnew(data.designation[0]?.designation || "");
    setUpdateData({
      branch_id: data.branch_id,
      username: data.username,
      email: data.email,
      mobile: data.mobile,
      designation: data.designation[0]?._id || "", // Use _id for matching in the dropdown
      password: data.password,
      status: data.status,
      role: data.role,
    });

    setUpdateShowModal(true); // Show the update modal
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id[0],
        username: updateData.username,
        email: updateData.email,
        mobile: updateData.mobile,
        designation: updateData.designation,
        password: updateData.password,
        status: updateData.status,
        role: updateData.role,
      };
      if (isPasswordChanged) {
        console.log("this is true called ");
        console.log("this is isPasswordChanged", isPasswordChanged);
        console.log("the update submit data", formData);
        const response = await axios.put(
          ApiUrl + `/password/${updateid}`,
          formData
        );
        console.log("response for update", response);
        setUpdateShowModal(false);
        toast.success("Update successfully!");
        fetchData();
        setIsPasswordChanged(false);
      } else {
        console.log("this is false called ");
        console.log("this is isPasswordChanged", isPasswordChanged);
        console.log("formData.designation", formData.designation);
        const response = await axios.put(
          ApiUrl + `/user/${updateid}`,
          formData
        );
        console.log("response for update", response);
        setUpdateShowModal(false);
        toast.success("Update successfully!");
        fetchData();
      }
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModals(false);
  };

  const handleCloseModalpassword = () => {
    setShowModalspassword(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, username) => {
    console.log(" the id", _id);
    console.log(" the name", username);
    setdeleteid(_id);
    setdeletename(username);
    setShowModals(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(ApiUrl + `/user/${getdeleteid}`);
      toast.success("Deleted successfully!");
      setShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting role:", error.message);
    }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  const handleAdditionalChange = async (e) => {
    const selectedBranchId = e.target.value; // Get the selected value from the dropdown
    console.log(
      "Additional change function called with value:",
      selectedBranchId
    );
    try {
      const response = await axios.get(
        ApiUrl + `/staff_based_on_branch/${selectedBranchId}`
      );
      console.log("Response from server selectedBranchId:", response.data);
      setempdata(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleAdditionalChanges = async (e) => {
    const selectedBranchIded = e.target.value; // Get the selected value from the dropdown
    console.log(
      "Additional change function called with value:",
      selectedBranchIded
    );
    try {
      const response = await axios.put(
        ApiUrl + `/staff_based_on_branch/${selectedBranchIded}`
      );
      console.log("Response from server selectedBranchId:", response.data);
      setempdata(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // useEffect(() => {
  //   staffdatas();
  //   desigantiondata();
  // }, []);
  // const [staff, setstaff] = useState([]);
  // const [designation, setDesignation] = useState([]);

  // async function staffdatas() {
  //   const responsed = await axios.get(ApiUrl + `/staff`);
  //   console.log("responsed", responsed);
  //   setstaff(responsed.data);
  // }

  // async function desigantiondata() {
  //   const responsed = await axios.get(ApiUrl + `/designation`);
  //   console.log("responsed", responsed);
  //   setDesignation(responsed.data);
  // }

  const [staff, setStaff] = useState([]);
  const [designation, setDesignation] = useState([]);


  // Fetch staff and designation data when the component mounts
  useEffect(() => {
    fetchStaffData();
    fetchDesignationData();
  }, []);

  // Fetch staff data
  const fetchStaffData = async () => {
    try {
      console.log("Request URL: ", ApiUrl + "/staff"); // Log the request URL for debugging
      const response = await axios.get(ApiUrl + "/staff");
      console.log("Response from staff:", response);
      setStaff(response.data); // Update the state with staff data
    } catch (error) {
      // Handle errors and log them
      handleError(error);
    }
  };

  // Fetch designation data
  const fetchDesignationData = async () => {
    try {
      console.log("Request URL: ", ApiUrl + "/designation"); // Log the request URL for debugging
      const response = await axios.get(ApiUrl + "/designation");
      console.log("Response from designation:", response);
      setDesignation(response.data); // Update the state with designation data
    } catch (error) {
      // Handle errors and log them
      handleError(error);
    }
  };

  // Error handling function
  const handleError = (error) => {
    if (error.response) {
      console.error("Error Response Data:", error.response.data);
      console.error("Error Response Status:", error.response.status);
      console.error("Error Response Headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error Request Data:", error.request);
    } else {
      console.error("Error Message:", error.message);
    }
    console.error("Error Config:", error.config);
  };


  const handleChange = async (e) => {
    const selectemp = e.target.value;
    console.log("thw selectemp ", selectemp);
    try {
      const response = await axios.get(ApiUrl + `/staff/${selectemp}`);
      const empdatased = response.data;
      setempdata(response.data);
      console.log(" the setadddata is after ", empdatased);
      setAddData((prevState) => ({
        ...prevState,
        username: empdatased[0]?.empid || "",
        email: empdatased[0]?.email || "",
        mobile: empdatased[0]?.mobile || "",
        emp_name: empdatased[0]?.name || "",
        designation: empdatased[0]?.designation[0]?._id || "",
      }));
      console.log(addData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  

  const [isStaffChecked, setIsStaffChecked] = useState(true);
  const [isManagementChecked, setIsManagementChecked] = useState(false);

  const handleStaffCheckboxChange = (e) => {
    setIsManagementChecked(false);
    setShowBranch(e.target.checked);
    setAddData((prevState) => ({
      ...prevState,
      branch_id: "",
      username: "",
      email: "",
      mobile: "",
      designation: "",
      password: "",
      status: "",
      role: "",
      emp_name: "",
    }));
  };

  const handleManagementCheckboxChange = (e) => {
    setIsManagementChecked(e.target.checked);
    setIsStaffChecked(false);
    setShowBranch(!e.target.checked);
    setAddData((prevState) => ({
      ...prevState,
      branch_id: "",
      username: "",
      email: "",
      mobile: "",
      designation: "",
      password: "",
      status: "",
      role: "",
      emp_name: "",
    }));
  };

  // const [showDesignation, setShowDesignation] = useState(true);
  const [showBranch, setShowBranch] = useState(true);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [loginstatus, setloginstatus] = useState("");
  const [modalData, setModalData] = useState({
    username: "",
    login_status: "",
  });

  const handleCloseModallogin = () => {
    setIsModalOpened(false);
  };

  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>User</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className=" col-lg-9 d-flex">
          <h5 className="mt-2">User Details</h5>
          <div className="mx-3">
            <button
              className="btn addBtn add mx-3"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}

      {/* Table */}
      <div className="card">
        <div className="mt-2">
          <table className="table table-striped responsive">
            <thead>
              <tr>
                <th>S.No</th>
                {rolename === "superadmin" && <th>Branch</th>}{" "}
                <th>User Name</th>
                <th>Staff Name</th>
                <th>Mobile No</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1} </td>
                  {rolename === "superadmin" && (
                    <td>{item.branch_id[0]?.branch_name}</td>
                  )}{" "}
                  <td>{item.username}</td>
                  <td>{item.emp_name ? item.emp_name : "-"}</td>
                  <td>{item.mobile}</td>
                  {item.status === "active" ? (
                    <td className="text-center">
                      <p className="text-white green_label">{item.status}</p>
                    </td>
                  ) : (
                    <td className="text-center">
                      <p className="text-white red_label">{item.status}</p>
                    </td>
                  )}
                  <td className="text-center">
                    <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdate(item._id, item)}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                      className="btn delete"
                      onClick={() => handleOpenModal(item._id, item.username)}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for adding a new user */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row   ">
            <div className="col-lg-6">
              <div className="row mb-3">
                <div className="col-lg-6">
                  <td className="management">
                    <input
                      type="checkbox"
                      checked={isManagementChecked}
                      onChange={handleManagementCheckboxChange}
                    />
                  </td>
                  <th className="px-1">Management</th>
                </div>
                <div className="col-lg-5">
                  <td className="staff">
                    <input
                      type="checkbox"
                      checked={isStaffChecked}
                      onChange={handleStaffCheckboxChange}
                    />
                  </td>
                  <th className="px-1">Staff</th>
                </div>
              </div>
              <div className="form-group">
                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  {showBranch && (
                    <select
                      className="form-select"
                      value={addData.branch_id}
                      onChange={(e) => {
                        setAddData({ ...addData, branch_id: e.target.value });
                        handleAdditionalChange(e); // Call the additional function here
                      }}
                      required
                    >
                      <option value="">Select Branch</option>
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item._id}>
                           {item.branch_id} - {item.branch_name}
                        </option>
                      ))}
                    </select>
                  )}
                  <label className={addData.branch_id ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  {isManagementChecked ? (
                    <input
                      className="form-control"
                      type="email"
                      placeholder="User Name"
                      value={addData.username}
                      onChange={(e) =>
                        setAddData({ ...addData, username: e.target.value })
                      }
                      required
                    />
                  ) : (
                    <select
                      className="form-select"
                      value={addData.username}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Emp
                      </option>
                      {empdata.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.empid} - {item.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <label className={addData.username ? "filled" : ""}>
                    User Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="email"
                    placeholder=""
                    value={addData.email}
                    onChange={(e) =>
                      setAddData({ ...addData, email: e.target.value })
                    }
                    required
                  />
                  <label className={addData.email ? "filled" : ""}>Email</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={addData.mobile}
                    onChange={(e) =>
                      setAddData({ ...addData, mobile: e.target.value })
                    }
                    required
                  />
                  <label className={addData.mobile ? "filled" : ""}>
                    Mobile No
                  </label>
                </div>
              </div>
            </div>
            {console.log(staff, "llllll--90")}
            <div className="col-lg-6 ">
              <div className="form-group">
                <div className="floating-label">
                  {/* {showDesignation && ( */}
                  <select
                    className="form-control"
                    value={addData.designation}
                    onChange={(e) =>
                      setAddData({ ...addData, designation: e.target.value })
                    }
                    required
                  >
                    <option value="" disabled>
                      Select Designation
                    </option>
                    {designation.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.designation}
                      </option>
                    ))}
                  </select>
                  {/* )} */}

                  <label className={addData.designation ? "filled" : ""}>
                    {addData.designation
                      ? "Selected Designation"
                      : "Select Designation"}
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="password"
                    placeholder=""
                    value={addData.password}
                    onChange={(e) =>
                      setAddData({ ...addData, password: e.target.value })
                    }
                    required
                  />
                  <label className={addData.password ? "filled" : ""}>
                    Password
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.role}
                    onChange={(e) => {
                      setAddData({ ...addData, role: e.target.value });
                    }}
                    required
                  >
                    <option value="">Select Role </option>
                    {getrole.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.rolename}
                      </option>
                    ))}
                  </select>
                  <label className={addData.role ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-control"
                    placeholder=""
                    value={addData.status}
                    onChange={(e) =>
                      setAddData({ ...addData, status: e.target.value })
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                  <label className={addData.status ? "filled" : ""}></label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ----------------update */}

      {/* /////////////////////////////////////////////////////// */}
      <div className="custom-modal-container">
        <Modal
          show={isModalOpened}
          onHide={handleCloseModallogin} // Correctly closes the modal
          className="custom-modal" // Apply a custom class
        >
          <Modal.Header closeButton>
            <Modal.Title>Login Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Username Input Field */}
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    value={modalData.username || ""}
                    onChange={(e) =>
                      setUpdateData({ ...modalData, username: e.target.value })
                    }
                    required
                    disabled
                  />
                  <label className={modalData.username ? "filled" : ""}>
                    User Name
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                {/* Login Status Select */}
                <div className="floating-label">
                  <select
                    className="form-control"
                    value={loginstatus?.toString() || ""}
                    onChange={(e) => setloginstatus(e.target.value === "true")}
                    required
                  >
                    <option value="">Login Status</option>
                    <option value="true">Online</option>
                    <option value="false">Offline</option>
                  </select>
                  <label className={loginstatus !== undefined ? "filled" : ""}>
                    Select Status
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModallogin}>
              Cancel
            </Button>
            {/* <Button variant="primary" onClick={handleUpdateloginstatus}>
              Update
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>

      {/* ///////////////////////////////////////////////////////////////////////// */}

      <Modal
        show={isUpdateshowModal}
        onHide={() => setUpdateShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                {empdetail && empdetail.length > 0 && (
                  <div className="floating-label">
                    <label className="form-label">
                      <b></b>
                    </label>
                    <select
                      className="form-select"
                      value={updateData.branch_id}
                      onChange={(e) => {
                        setUpdateData({
                          ...updateData,
                          branch_id: e.target.value,
                        }); // Update the state
                        handleAdditionalChanges(e); // Call the additional function here
                      }}
                      required
                    >
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item.branch_id}>
                          {item.branch_id}
                        </option>
                      ))}
                    </select>
                    <label
                      className={updateData.branch_id ? "filled" : ""}
                    ></label>
                  </div>
                )}
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.username}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, username: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.username ? "filled" : ""}>
                    User Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="email"
                    placeholder=""
                    value={updateData.email}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, email: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.email ? "filled" : ""}>
                    Email
                  </label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    type="tel"
                    value={updateData.mobile}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, mobile: e.target.value })
                    }
                    required
                  />
                  <label className={addData.mobile ? "filled" : ""}>
                    Mobile
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.designation}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        designation: e.target.value,
                      });
                    }}
                    required
                  >
                    {/* <option value="">Select Designation</option> */}
                    {designation.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.designation}
                      </option>
                    ))}
                  </select>
                  {/* <label className={updateData.designation ? "filled" : ""}>Designation</label> */}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="password"
                    placeholder=""
                    value={updateData.password}
                    onChange={handlePasswordChange}
                    required
                  />
                  <label className={updateData.password ? "filled" : ""}>
                    Password
                  </label>
                  {isPasswordChanged && (
                    <p>Password field has been modified.</p>
                  )}
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.role}
                    onChange={(e) => {
                      setUpdateData({ ...updateData, role: e.target.value });
                    }}
                    required
                  >
                    <option value="">Select Role </option>
                    {getrole.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.rolename}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.role ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-control"
                    value={updateData.status}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, status: e.target.value })
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                  <label className={updateData.status ? "filled" : ""}></label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal className="modal" show={showModals} onHide={handleCloseModal}>
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1"> User Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* password change */}

      <Modal
        className="modal"
        show={showModalspassword}
        onHide={handleCloseModalpassword}
      >
        <Modal.Header>
          <p>Super Admin Password Change</p>
        </Modal.Header>
        <Modal.Body>
          <input type="text"></input>
          <label>Password</label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModalpassword}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${
                      currentPage === i + 1 ? "actived" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------end ---------------------------------- */}
    </div>
  );
}

export default User;
