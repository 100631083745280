// import React, { useState } from "react";
// import { Modal, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";

// function Travel_Allowance() {
//   const [showModal, setShowModal] = useState(false);

//   return (
//     <div className="top">
//       <div className="" style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-12">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>

//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Staff / </b>
//             </Link>

//             <Link to="" className="text-danger dashBoard_text">
//               <b>Allowance </b>
//             </Link>
//           </div>
//         </div>
//         {/* <div className="d-flex"> */}
//         <div className="row ">
//           <div className="col-lg-10 d-flex">
//             <h5 className="mt-2">Allowance</h5>

//             <button
//               className="btn addBtn add mx-2"
//               onClick={() => setShowModal(true)}
//             >
//               Add{" "}
//             </button>
//           </div>
//           <div className="col-lg-2">
//             <button className="btn  btn-secondary">Allowance Charge</button>
//           </div>
//         </div>
//         {/* </div> */}
//       </div>

//       {/* ------------ search and per page ----------------------------*/}

//       <div className="mb-3 mt-3">
//         <div className="row">
//           <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//             <label className="form-label text-dark mt-2">Show : </label>
//             <div>
//               <select className="form-control w-100 mx-2">
//                 {/* <option value='5'>5</option> */}
//                 <option value="10">10</option>
//                 <option value="20">20</option>
//                 <option value="50">50</option>
//               </select>
//             </div>
//           </div>
//           <div className="col-lg-7 col-md-4 col-sm-1"></div>
//           <div className="col-lg-3 col-md-4 col-sm-7">
//             <div className="w-full flex-1">
//               <form>
//                 <div className="relative">
//                   <input
//                     className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                     placeholder="Search ..."
//                     type="search"
//                   />
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* --------------------------------end------------------------------------------- */}

//       <div className="card">
//         <div className="mt-1">
//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>S.No</th>
//                 <th>Name</th>
//                 <th>Date</th>
//                 <th>Type</th>
//                 <th>Travel Mode</th>
//                 <th>Amount</th>
//                 <th>Status</th>
//                 <th className="text-center">Action</th>
//               </tr>
//             </thead>

//             <tbody></tbody>
//           </table>
//         </div>
//       </div>
//       {/* Modal for adding a new user */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add Allowance </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="form-group">
//             <div className="floating-label">
//               <input
//                 className="form-control"
//                 type="text"
//                 placeholder=""
//                 disabled
//                 required
//               />
//               <label className="">Staff Name</label>
//             </div>
//             <div className="floating-label">
//               <input
//                 className="form-control"
//                 type="date"
//                 placeholder=""
//                 required
//               />
//               <label className="">Visit Date</label>
//             </div>
//             <div className="floating-label">
//               <select className="form-control">
//                 <option value="" disabled>
//                   Select Allowance Type
//                 </option>
//                 <option value="Travel">Travel</option>
//                 <option value="Food">Food</option>
//                 <option value="Stay">Stay or Accommodation</option>
//               </select>
//             </div>
//             <div className="floating-label">
//               <select className="form-select" required>
//                 <option value="">Select Travel Type</option>
//               </select>

//               <div></div>
//             </div>

//             <div className="floating-label">
//               <input
//                 className="form-control"
//                 type="text"
//                 placeholder=""
//                 required
//               />
//               <label className="">Tickets / Bill No</label>
//             </div>

//             <div className="floating-label">
//               <input
//                 className="form-control"
//                 type="text"
//                 placeholder=""
//                 required
//               />
//               <label className="">From</label>
//             </div>

//             <div className="floating-label">
//               <input
//                 className="form-control"
//                 type="text"
//                 placeholder=""
//                 required
//               />
//               <label className="">To</label>
//             </div>

//             <div className="floating-label">
//               <input
//                 className="form-control"
//                 type="text"
//                 placeholder=""
//                 required
//               />
//               <label className="">Distance (Kilometer)</label>
//             </div>

//             <div className="floating-label">
//               <input
//                 className="form-control"
//                 type="text"
//                 placeholder=""
//                 required
//               />
//               <label className="">Amount</label>
//             </div>

//             <>
//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   required
//                 />
//                 <label className="">Location</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   required
//                 />
//                 <label className="">Bill</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   required
//                 />
//                 <label className="">Amount</label>
//               </div>
//             </>

//             <>
//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   required
//                 />
//                 <label className="">Location</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   required
//                 />
//                 <label className="">Bill</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   required
//                 />
//                 <label className="">Amount</label>
//               </div>
//             </>

//             <div className="floating-label">
//               <input
//                 className="form-control"
//                 type="text"
//                 placeholder=""
//                 required
//               />
//               <label className="">Remarks</label>
//             </div>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button className="cancelbut" variant="secondary">
//             Cancel
//           </Button>
//           <Button className="submitbut" variant="primary">
//             Submit
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* update modal start */}

//       {/* ----------------delete */}

//       {/* ---------------------------------------end ---------------------------------- */}

//       {/* <Modal className="modal" show={infoShowModal} onHide={handleInfoClose}>
//         <Modal.Header>
//           <p>Allowance Preview</p>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="row">
//             <div className="col-lg-5">
//               <div className="card p-3 mb-4">
//                 <img
//                   className=""
//                   alt=""
//                   // src={`${apiUrl}/uploads/${responseStaff[0]?.photo_upload || ""}`}
//                   src={require("../../assets/img/photo.jfif")}
//                 ></img>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">ID</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   storedUsername
//                   disabled
//                   required
//                 />
//                 <label className="">Name</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">Designation</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">Allowance Type</label>
//               </div>
//             </div>

//             <div className="col-lg-7">
//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
                 
//                   disabled
//                   required
//                 />
//                 <label className="">Travel Type</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">Tickets / Bill No</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">From</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">To</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">Distance</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">Location</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">Bill</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">Amount</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   disabled
//                   required
//                 />
//                 <label className="">Remarks</label>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button className="cancelbut" variant="secondary">
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal> */}
//     </div>
//   );
// }

// export default Travel_Allowance;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../Environment/ApiUrl";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Travel_Allowance() {
  const [responseData, setResponseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});
  const [addData, setAddData] = useState({
    idlocal: "",
    from: "",
    to: "",
    travel_distance: "",
    visitor_staff: "",
    date: "",
    allowance_type: "",
    transport_mode: "",
    distance: "",
    ticket_bill: "",
    amount: "",
    status: "",
    food_location: "",
    food_bill: "",
    stay_location: "",
    stay_bill: "",
    remarks: "",
  });

  const [updateData, setUpdateData] = useState({
    idlocal: "",
    from: "",
    to: "",
    travel_distance: "",
    visitor_staff: "",
    date: "",
    allowance_type: "",
    transport_mode: "",
    distance: "",
    ticket_bill: "",
    amount: "",
    status: "",
    food_location: "",
    food_bill: "",
    stay_location: "",
    stay_bill: "",
    remarks: "",
  });

  useEffect(() => {
    fetchData();

    gettoken();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(apiUrl + "/travel_allowance", {
  //       params: { data: localStorage.getItem("branch_id") },
  //     });
  //     console.log("Response from server:", response.data);
  //     setResponseData(response.data.reverse());
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };



  const fetchData = async () => {
    try {
      // Retrieve branch details and employee name from localStorage
      const branchId = localStorage.getItem("branch_id");
      const branchName = localStorage.getItem("branch_name");
      const empName = localStorage.getItem("username");
  
      // Check if the condition to fetch all data is met
      if (branchId === null || branchName === "Branches" || empName === undefined) {
        // Fetch all attendance data if condition is true
        const response = await axios.get(apiUrl + "/travel_allowance");
        console.log("Response from server with all attendance data:", response.data);
        setResponseData(response.data.reverse());
      } else {
        // Fetch data based on branchId and empid (from localStorage)
        const response = await axios.get(apiUrl + "/travel_allowance", {
          params: { data: branchId },
        });
        console.log("Response from server with filtered attendance data:", response.data);
  
        // Get the empid from localStorage
        const localEmpid = localStorage.getItem("username");
  
        // Filter data based on the empid from localStorage
        const filteredData = response.data.filter(item => item.name === localEmpid);
  
        setResponseData(filteredData.reverse());
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  




  const gettoken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      setroleid(response.data.detail.rolename);
      localStorage.setItem("role_id", response.data.detail.rolename);
      const id = response.data.detail.rolename;

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);
          const modules = response.data.modules;
          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );
          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        name: idlocal,
        date: addData.date,
        allowance_type: allowance_type,
        transport_mode: addData.transport_mode,
        from: addData.from,
        to: addData.to,
        distance: addData.distance,
        ticket_bill: addData.ticket_bill,
        amount: addData.amount,
        status: "Applied",
        food_location: addData.food_location,
        food_bill: addData.food_bill,
        stay_location: addData.stay_location,
        stay_bill: addData.stay_bill,
        remarks: addData.remarks,
      };
      setAllowance_Type("");
      console.log("the form data ", formData);
      const response = await axios.post(apiUrl + "/travel_allowance", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        name: "",
        date: "",
        allowance_type: "",
        transport_mode: "",
        from: "",
        to: "",
        distance: "",
        ticket_bill: "",
        amount: "",
        status: "",
        food_location: "",
        food_bill: "",
        stay_location: "",
        stay_bill: "",
        remarks: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };


  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);
  
    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };






  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        apiUrl + `/travel_allowance/${getdeleteid}`
      );
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  const [allowance_type, setAllowance_Type] = useState("");

  const handleAllowanceChange = (event) => {
    setAllowance_Type(event.target.value);
    setAddData((prevData) => ({
      ...prevData,
      amount: "",
      transport_mode: "",
      from: "",
      to: "",
      remarks: "",
    }));
  };

  const handleAllowanceChanges = (event) => {
    const selectedAllowanceType = event.target.value;
    setUpdateData((prevData) => ({
      ...prevData,
      allowance_type: selectedAllowanceType,
      transport_mode: "",
      from: "",
      to: "",
      distance: "",
      amount: "",
      food_location: "",
      food_bill: "",
      remarks: "",
      ticket_bill: "",
    }));
  };

  const [idlocal, setlocalid] = useState([]);
  useEffect(() => {
    fetchData();
    const id_local = localStorage.getItem("username");
    setlocalid(id_local);
  }, []);

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [infoShowModal, setInfoShowModal] = useState(false);

  // const handleOpenInfo = async (_id) => {
  //   console.log(" the data to updata   onclick _id   ", _id);
  //   console.log(" the data to updata   onclick Update id   ", responseData);
  //   const allowance_response = await axios.get(
  //     apiUrl + `/travel_allowance/${_id}`
  //   );
  //   console.log("reponse is ok", allowance_response);
  //   setUpdateData(allowance_response.data);

  //   setInfoShowModal(true);
  // };

  const [responseStaff, setResponseStaff] = useState("");

  const [responseStaffss, setResponseStaffss] = useState("");

  const fetchStaff = async () => {
    try {
      const responsedstaff = await axios.get(apiUrl + `/staff`);
      console.log("Response staff datas :", responsedstaff.data);

      const storedUsername = localStorage.getItem("username");
      console.log("this 123", storedUsername);

      const datanew = responsedstaff.data.find((data) => {
        return data.empid === storedUsername;
      });

      console.log("this 123 is datanew", datanew);
      setResponseStaff(responsedstaff.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  const handleOpenInfo = async (_id, item) => {
    console.log(" the data to update onclick _id   ", _id);
    console.log(" the data to update onclick item   ", item);
    console.log(" the data to update onclick item   ", item.name);
    const dataname = item.name;
    console.log(" the data to update onclick responseStaff   ", responseStaff);
    // const selectedTravelMode = responseStaff.find(
    //   (item) => item.empid == item.name
    // );
    console.log(
      " the data to update onclick responseStaff 1233  ",
      responseStaffss
    );
    const selectedTravelMode = responseStaff.filter(
      (item) => item.empid == dataname
    );

    setResponseStaffss(selectedTravelMode);
    console.log(
      " the data to update onclick item selectedTravelMode  ",
      selectedTravelMode
    );

    const allowance_response = await axios.get(
      apiUrl + `/travel_allowance/${_id}`
    );
    console.log("response is ok", allowance_response);
    setUpdateData(allowance_response.data); // This should set the correct values for updateData
    setInfoShowModal(true);
  };

  const handleInfoClose = () => {
    setInfoShowModal(false);
  };

  const handleOpenModal = (_id, name) => {
    // if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", name);
      setdeleteid(_id);
      setdeletename(name);
      setDeleteShowModals(true);
    // } else {
    //   toast.error("You don't have the permission");
    // }
  };

  useEffect(() => {
    // Set the current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().split("T")[0];
    setAddData((prevData) => ({
      ...prevData,
      date: currentDate, // Set the default date
    }));
  }, []);

  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [updateid, setUpdateId] = useState("");

  // const handleupdatesubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const formData = {
  //       name: idlocal,
  //       // mobile_no: updateData.mobile_no,
  //       from: updateData.from || "",
  //       to: updateData.to || "",
  //       travel_distance: updateData.travel_distance || "",
  //       date: updateData.date || "",
  //       allowance_type: updateData.allowance_type || "",
  //       transport_mode: updateData.transport_mode || "",
  //       distance: updateData.distance || "",
  //       ticket_bill: updateData.ticket_bill || "",
  //       amount: updateData.amount || "",
  //       status: updateData.status || "",
  //       food_location: updateData.food_location || "",
  //       food_bill: updateData.food_bill || "",
  //       stay_location: updateData.stay_location || "",
  //       stay_bill: updateData.stay_bill || "",
  //       remarks: updateData.remarks || "",
  //     };
  //     console.log("the update submit data", formData);
  //     const response = await axios.put(
  //       apiUrl + `/travel_allowance/${updateid}`,
  //       formData
  //     );
  //     console.log("response for update", response);
  //     setUpdateShowModal(false);
  //     toast.success("Update successfully!");
  //     fetchData();
  //   } catch (error) {
  //     console.error("Error updating data:", error.message);
  //   }
  // };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: idlocal,
      mobile_no: updateData.mobile_no,
      from: updateData.from || "",
      to: updateData.to || "",
      travel_distance: updateData.travel_distance || "",
      date: updateData.date || "",
      allowance_type: updateData.allowance_type || "",
      transport_mode: updateData.transport_mode || "",
      distance: updateData.distance || "",
      ticket_bill: updateData.ticket_bill || "",
      amount: updateData.amount || "",
      status: updateData.status || "",
      food_location: updateData.food_location || "",
      food_bill: updateData.food_bill || "",
      stay_location: updateData.stay_location || "",
      stay_bill: updateData.stay_bill || "",
      remarks: updateData.remarks || "",
    };
    console.log("Submit Data: ", formData);
    try {
      const response = await axios.put(
        apiUrl + `/travel_allowance/${updateid}`,
        formData
      );
      console.log("Response for update: ", response);
      setUpdateShowModal(false);
      toast.success("Update successful!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleUpdate = (_id, data) => {
    // if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log(" the data to updata   onclick data   ", data);
      console.log(" the data to updata   onclick course id   ", data.course_id);

      console.log(
        " the data to updata   onclick course id   ",
        data.allowance_type
      );
      
      setUpdateShowModal(true);
      setUpdateData({
        name: idlocal,
        mobile_no: data.mobile_no,
        from: data.from,
        to: data.to,
        travel_distance: data.travel_distance,
        date: data.date,
        allowance_type: data.allowance_type,
        transport_mode: data.transport_mode,
        distance: data.distance,
        ticket_bill: data.ticket_bill,
        amount: data.amount,
        status: data.status,
        food_location: data.food_location,
        food_bill: data.food_bill,
        stay_location: data.stay_location,
        stay_bill: data.stay_bill,
        remarks: data.remarks,
      });
    // } else {
    //   toast.error("You don't have the permission");
    // }
  };

  const navigate = useNavigate();

  const [responsetype, setResponseType] = useState([]);

  const fetchDatased = async () => {
    try {
      const responsetypes = await axios.get(apiUrl + "/charge");
      setResponseType(responsetypes.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchDatased();
  }, []);

  const getStatusLabelClass = (status) => {
    switch (status) {
      case "Applied":
        return "bg-primary1 text-white1 ";
      case "Verified":
        return "bg-warning1 text-white1 ";
      case "Approved":
        return "bg-success1 text-white1 ";
      default:
        return "";
    }
  };

  const handleDistanceChange = (e) => {
    const newDistance = e.target.value;
    yourFunctionToHandleDistance(newDistance);

    setAddData({ ...addData, distance: newDistance });
    setUpdateData({ ...updateData, distance: newDistance });
  };

  const yourFunctionToHandleDistance = (distance) => {
    console.log("Distance value changed to:", distance);

    const value = addData.per_km_charges * distance;
    console.log("multiple is ok", value);
    addData.amount = value;

    const updateDataValue = updateData.per_km_charges * distance;
    console.log("multiple is ok for updateData:", updateDataValue);
    updateData.amount = updateDataValue;
  };

  const getPerKmCharges = (selectedMode) => {
    const selectedTravelMode = responsetype.find(
      (item) => item.travel_mode === selectedMode
    );
    return selectedTravelMode ? selectedTravelMode.per_km_charges : "";
  };

  // const handleSelectChange = (e) => {
  //   const selectedMode = e.target.value;
  //   const perKmCharges = getPerKmCharges(selectedMode);

  //   addData.distance = "";
  //   addData.amount = "";

  //   setAddData({
  //     ...addData,
  //     transport_mode: selectedMode,
  //     per_km_charges: perKmCharges,
  //   });
  // };

  const handleSelectChange = (e) => {
    const selectedMode = e.target.value;
    const perKmCharges = getPerKmCharges(selectedMode);

    // Check if you are dealing with addData or updateData
    if (updateData.allowance_type === "Travel") {
      setUpdateData({
        ...updateData,
        transport_mode: selectedMode,
        per_km_charges: perKmCharges,
        from: "",
        to: "",
        distance: "",
        amount: "",
        remarks: "",
        ticket_bill: "",
      });
    } else {
      setAddData({
        ...addData,
        transport_mode: selectedMode,
        per_km_charges: perKmCharges,
      });
    }
  };

  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>

            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>

            <Link to="" className="text-danger dashBoard_text">
              <b>Allowance </b>
            </Link>
          </div>
        </div>
        {/* <div className="d-flex"> */}
        <div className="row ">
          <div className="col-lg-10 d-flex">
            <h5 className="mt-2">Allowance</h5>
            {/* {previllages.add && ( */}
              <button
                className="btn addBtn  add mx-2"
                onClick={() => setShowModal(true)}
              >
                Add{" "}
              </button>
            {/* )} */}
          </div>
          <div className="col-lg-2">
            <button
              className="btn  btn-secondary"
              onClick={() => navigate("/charge")}
            >
              Allowance Charge
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                {/* <option value='5'>5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Date</th>
                <th>Type</th>
                <th>Travel Mode</th>
                <th>Amount</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>

            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>{item.name}</td>
                  <td>{formatDatefitching(item.date)}</td>
                  <td>{item.allowance_type}</td>
                  <td>{item.transport_mode}</td>
                  <td>{item.amount}</td>
                  <td className="text-center hideres">
                    <p
                      className={`text-white ${getStatusLabelClass(
                        item.status
                      )}`}
                    >
                      {item.status}
                    </p>
                  </td>
                  <td className="text-center">
                    {/* {previllages.edit && ( */}
                      <button
                        className="btn updelete mx-2"
                        onClick={() => handleUpdate(item._id, item)}
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </button>
                    {/* )} */}
                    {/* {previllages.delete && ( */}
                      <button
                        className="btn delete"
                        onClick={() => handleOpenModal(item._id, item.name)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    {/* )} */}

                    <button
                      className="btn  mx-2"
                      onClick={() => handleOpenInfo(item._id, item)}
                    >
                      <i className="fa-solid fa-circle-info"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal for adding a new user */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Allowance </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={idlocal || ""}
                onChange={(e) =>
                  setAddData({
                    ...idlocal,
                    name: e.target.value,
                  })
                }
                disabled
                required
              />
              <label className={addData.idlocal ? "filled" : ""}>
                Staff Name
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="date"
                placeholder=""
                value={addData.date}
                onChange={(e) =>
                  setAddData({ ...addData, date: e.target.value })
                }
                required
              />
              <label className={addData.date ? "filled" : ""}>Visit Date</label>
            </div>

            <div className="floating-label">
              <select
                className="form-control"
                value={allowance_type}
                onChange={handleAllowanceChange}
              >
                <option value="" disabled>
                  Select Allowance Type
                </option>
                <option value="Travel">Travel</option>
                <option value="Food">Food</option>
                <option value="Stay">Stay or Accommodation</option>
              </select>
            </div>

            {allowance_type === "Travel" && (
              <>
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.transport_mode}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="">Select Travel Type</option>
                    {responsetype.map((item, index) => (
                      <option key={index} value={item.travel_mode}>
                        {item.travel_mode}
                      </option>
                    ))}
                  </select>
                  {addData.per_km_charges && (
                    <div>
                      {/* <p>Per Km Charges: {addData.per_km_charges}</p> */}
                    </div>
                  )}
                </div>

                {(addData.transport_mode === "Train" ||
                  addData.transport_mode === "Flight") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.ticket_bill}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          ticket_bill: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.ticket_bill ? "filled" : ""}>
                      Tickets / Bill No
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.from}
                    onChange={(e) =>
                      setAddData({ ...addData, from: e.target.value })
                    }
                    required
                  />
                  <label className={addData.from ? "filled" : ""}>From</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.to}
                    onChange={(e) =>
                      setAddData({ ...addData, to: e.target.value })
                    }
                    required
                  />
                  <label className={addData.to ? "filled" : ""}>To</label>
                </div>

                {(addData.transport_mode === "Bike" ||
                  addData.transport_mode === "Car") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.distance}
                      onChange={handleDistanceChange}
                      required
                    />
                    <label className={addData.distance ? "filled" : ""}>
                      Distance (Kilometer)
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({ ...addData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            {allowance_type === "Food" && (
              <>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.food_location}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        food_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.food_location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.food_bill}
                    onChange={(e) =>
                      setAddData({ ...addData, food_bill: e.target.value })
                    }
                    required
                  />
                  <label className={addData.food_bill ? "filled" : ""}>
                    Bill
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            {allowance_type === "Stay" && (
              <>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.stay_location}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        stay_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.stay_location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.stay_bill}
                    onChange={(e) =>
                      setAddData({ ...addData, stay_bill: e.target.value })
                    }
                    required
                  />
                  <label className={addData.stay_bill ? "filled" : ""}>
                    Bill
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({ ...addData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.food_location ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={addData.remarks}
                onChange={(e) =>
                  setAddData({ ...addData, remarks: e.target.value })
                }
                required
              />
              <label className={addData.remarks ? "filled" : ""}>Remarks</label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => {
              setShowModal(false);
              setAllowance_Type("");
            }}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update modal start */}

      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Allowance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={idlocal}
                onChange={(e) =>
                  setUpdateData({
                    ...idlocal,
                    name: e.target.value,
                  })
                }
                disabled
                required
              />
              <label className={updateData.idlocal ? "filled" : ""}>
                Staff Name
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="date"
                placeholder=""
                value={updateData.date}
                onChange={(e) =>
                  setUpdateData({ ...updateData, date: e.target.value })
                }
                required
                disabled
              />
              <label className={updateData.date ? "filled" : ""}>
                Visit Date
              </label>
            </div>

            {/* <p>{allowance_type}</p> */}
            <div className="floating-label">
              <select
                className="form-control"
                value={updateData.allowance_type}
                onChange={handleAllowanceChanges}
              >
                <option value="" disabled>
                  Select Allowance Type
                </option>
                <option value="Travel">Travel</option>
                <option value="Food">Food</option>
                <option value="Stay">Stay or Accommodation</option>
              </select>
            </div>

            {updateData.allowance_type === "Travel" && (
              <>
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.transport_mode}
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="">Select Travel Type</option>
                    {responsetype.map((item, index) => (
                      <option key={index} value={item.travel_mode}>
                        {item.travel_mode}
                      </option>
                    ))}
                  </select>
                </div>

                {(updateData.transport_mode === "Train" ||
                  updateData.transport_mode === "Flight") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.ticket_bill}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          ticket_bill: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.ticket_bill ? "filled" : ""}>
                      Tickets / Bill No
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.from}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, from: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.from ? "filled" : ""}>
                    From
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.to}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, to: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.to ? "filled" : ""}>To</label>
                </div>

                {/* {(updateData.transport_mode === "Bike" ||
                  updateData.transport_mode === "Car") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.distance}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          distance: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.distance ? "filled" : ""}>
                      Distance (Kilometer)
                    </label>
                  </div>
                )} */}

                {(updateData.transport_mode === "Bike" ||
                  updateData.transport_mode === "Car") && (
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.distance}
                      onChange={handleDistanceChange}
                      required
                    />
                    <label className={updateData.distance ? "filled" : ""}>
                      Distance (Kilometer)
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            {updateData.allowance_type === "Food" && (
              <>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.food_location}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        food_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.food_location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.food_bill}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        food_bill: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.food_bill ? "filled" : ""}>
                    Bill
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            {updateData.allowance_type === "Stay" && (
              <>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.stay_location}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        stay_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.stay_location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Bill
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        amount: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>
              </>
            )}

            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={updateData.remarks}
                onChange={(e) =>
                  setUpdateData({ ...updateData, remarks: e.target.value })
                }
                required
              />
              <label className={updateData.remarks ? "filled" : ""}>
                Remarks
              </label>
            </div>

            <div className="floating-label">
              <select
                className="form-control"
                value={updateData.status}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    status: e.target.value,
                  })
                }
                required
              >
                <option value="">Choose Status</option>

                <option value="Verified">Verified</option>
                <option value="Approved">Approved</option>
              </select>
              <label className={updateData.status ? "filled" : ""}>
                {updateData.status ? "Status" : ""}
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* update modal end */}

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ---------------------------------------end ---------------------------------- */}

      <Modal className="modal" show={infoShowModal} onHide={handleInfoClose}>
        <Modal.Header>
          <p>Allowance Preview</p>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-5">
              <div className="card p-3 mb-4">
                <img
                  className=""
                  alt=""
                  // src={`${apiUrl}/uploads/${responseStaff[0]?.photo_upload || ""}`}
                  src={`${apiUrl}/uploads/${
                    responseStaffss[0]?.photo_upload ||
                    require("../../assets/img/photo.jfif")
                  }`}
                  style={{height: '75px'}}
                ></img>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.name}
                  disabled
                  required
                />
                <label className="">ID</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={responseStaffss[0]?.name}
                  storedUsername
                  disabled
                  required
                />
                <label className="">Name</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={responseStaffss[0]?.designation[0]?.designation}
                  disabled
                  required
                />
                <label className="">Designation</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.allowance_type}
                  disabled
                  required
                />
                <label className="">Allowance Type</label>
              </div>
            </div>

            <div className="col-lg-7">
              {updateData.allowance_type === "Travel" ? (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.transport_mode}
                    disabled
                    required
                  />
                  <label className="">Travel Type</label>
                </div>
              ) : null}

              {(updateData.transport_mode === "Train" ||
                updateData.transport_mode === "Flight") && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.ticket_bill}
                    disabled
                    required
                  />
                  <label className="">Tickets / Bill No</label>
                </div>
              )}

              {updateData.allowance_type === "Travel" ? (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.from}
                    disabled
                    required
                  />
                  <label className="">From</label>
                </div>
              ) : null}

              {updateData.allowance_type === "Travel" ? (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.to}
                    disabled
                    required
                  />
                  <label className="">To</label>
                </div>
              ) : null}

              {(updateData.transport_mode === "Bike" ||
                updateData.transport_mode === "Car") && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.distance}
                    disabled
                    required
                  />
                  <label className="">Distance</label>
                </div>
              )}

              {(updateData.allowance_type === "Food" ||
                updateData.allowance_type === "Stay") && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.food_location}
                    disabled
                    required
                  />
                  <label className="">Location</label>
                </div>
              )}


{(updateData.allowance_type === "Food" ||
                updateData.allowance_type === "Stay") && (
                  <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.food_bill}
                    disabled
                    required
                  />
                  <label className="">Bill</label>
                </div>
              )}


              

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.amount}
                  disabled
                  required
                />
                <label className="">Amount</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.remarks}
                  disabled
                  required
                />
                <label className="">Remarks</label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleInfoClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Travel_Allowance;
