// import React, { useState } from "react";
// import { Modal, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";

// function Designation() {
//     const [showModal, setShowModal] = useState(false);
//   return (
//     <>
//       <div className="top">
//         <div className="" style={{ marginTop: "-80px" }}>
//           <div className="row mb-3">
//             <div className="col-lg-11">
//               <Link to="/dashboard" className="text-secondary dashBoard_text">
//                 <b>Dashboard / </b>
//               </Link>
//               <Link to="" className="text-danger dashBoard_text">
//                 <b>Designation</b>
//               </Link>
//             </div>
//           </div>
//         </div>

//         {/* Table */}
//         <div className="d-flex">
//           <h5 className="mt-2">Designation</h5>
//           <div className="mx-3">
//             <button className="btn btn-success add mx-3"  onClick={() => setShowModal(true)}>Add</button>
//           </div>
//         </div>

//         {/* ------------ search and per page ----------------------------*/}

//         <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className="form-label text-dark mt-2">Show : </label>
//               <div>
//                 <select className="form-control w-100 mx-2">
//                   {/* <option value='5'>5</option> */}
//                   <option value="10">10</option>
//                   <option value="20">20</option>
//                   <option value="50">50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">
//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                       placeholder="Search ..."
//                       type="search"
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* --------------------------------end------------------------------------------- */}
//         <div className="card">
//           <div className="mt-1">
//             <table className="table table-striped">
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   <th>Branch</th>
//                   <th>Designation</th>
//                   <th>Department</th>
//                   <th className="text-center">Action</th>
//                 </tr>
//               </thead>
//               <tbody></tbody>
//             </table>
//           </div>
//         </div>

//         {/* Modal for adding a new user */}
//         <Modal show={showModal} onHide={() => setShowModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Add Designation</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <div className="form-group">
//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   required
//                 />
//                 <label className="">Designation</label>
//               </div>

//               <div className="floating-label">
//                 <input
//                   className="form-control"
//                   type="text"
//                   placeholder=""
//                   required
//                 />
//                 <label className="">Department</label>
//               </div>
//             </div>
//           </Modal.Body>

//           <Modal.Footer>
//             <Button className="cancelbut" variant="secondary">
//               Cancel
//             </Button>
//             <Button className="submitbut" variant="primary">
//               Submit
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         {/* _------------------------------pagination button -------------------------------- */}

//         <div className="mt-3 pb-5">
//           <div className="row">
//             <div className="col-lg-10 col-sm-12">
//               <nav className="d-flex float-right">
//                 <ul className="pagination justify-content-center">
//                   <li className="">
//                     <button className="page-link">Previous</button>
//                   </li>

//                   <li className="">
//                     <button className="page-link ">1</button>
//                   </li>

//                   <li className="">
//                     <button className="page-link ">Next</button>
//                   </li>
//                 </ul>
//               </nav>{" "}
//             </div>
//             <div className="col-lg-2 col-sm-12 mt-1">
//               <div className="float-end">
//                 <span className="text-dark ">Page 1 of 1</span>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* ---------------------------------------end ---------------------------------- */}
//       </div>
//     </>
//   );
// }

// export default Designation;



import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import ApiUrl from "../Environment/ApiUrl";

function Designation() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [designationData, setDesignationData] = useState([]);
  const [addData, setAddData] = useState({ designation: "", department: "" });
  const [updateData, setUpdateData] = useState({ designation: "", department: "" });
  const [selectedId, setSelectedId] = useState(null);

  // Fetch Designations
  const fetchDesignations = async () => {
    try {
      const response = await axios.get(ApiUrl + "/designation");
      setDesignationData(response.data);
    } catch (error) {
      console.error("Error fetching designations:", error.message);
    }
  };

  useEffect(() => {
    fetchDesignations();
  }, []);

  // Handle Add Modal Input Changes
  const handleAddInputChange = (e) => {
    const { name, value } = e.target;
    setAddData({
      ...addData,
      [name]: value,
    });
  };

  // Handle Update Modal Input Changes
  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData({
      ...updateData,
      [name]: value,
    });
  };

  // Handle Submit Add Data
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(ApiUrl + "/designation", addData);
      setShowAddModal(false);
      fetchDesignations(); // Refresh the list after adding
    } catch (error) {
      console.error("Error adding designation:", error.message);
    }
  };

  // Handle Submit Update Data
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(ApiUrl + "/designation/" + selectedId, updateData);
      setShowUpdateModal(false);
      fetchDesignations(); // Refresh the list after updating
    } catch (error) {
      console.error("Error updating designation:", error.message);
    }
  };

  // Handle Delete
  const handleDelete = async () => {
    try {
      const response = await axios.delete(ApiUrl + "/designation/" + selectedId);
      setShowDeleteModal(false);
      fetchDesignations(); // Refresh the list after deleting
    } catch (error) {
      console.error("Error deleting designation:", error.message);
    }
  };

  return (
    <>
      <div className="top">
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Designation</b>
            </Link>
          </div>
        </div>

        {/* Table */}
        <div className="d-flex">
          <h5 className="mt-2">Designation</h5>
          <div className="mx-3">
            <button className="btn addBtn add mx-3" onClick={() => setShowAddModal(true)}>
              Add
            </button>
          </div>
        </div>

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  // onChange={(e) => {
                  //   setItemsPerPage(e.target.value);
                  // }}
                >
                  {/* <option value='5'>5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      // value={searchQuery}
                      // onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Designation</th>
                  <th>Department</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {designationData.map((designation, index) => (
                  <tr key={designation._id}>
                    <td>{index + 1}</td>
                    <td>{designation.designation}</td>
                    <td>{designation.department}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-primary mx-1"
                        onClick={() => {
                          setSelectedId(designation._id);
                          setUpdateData({
                            designation: designation.designation,
                            department: designation.department,
                          });
                          setShowUpdateModal(true);
                          console.log(
                            "Delete button clicked for ID:",
                            designation._id
                          );
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btn btn-danger mx-1"
                        onClick={() => {
                          setSelectedId(designation._id);
                          setShowDeleteModal(true);
                          console.log(
                            "Delete button clicked for ID:",
                            designation._id
                          );
                        }}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Add Modal */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Designation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Designation"
                name="designation"
                value={addData.designation}
                onChange={handleAddInputChange}
              />
              <input
                type="text"
                className="form-control"
                placeholder="Department"
                name="department"
                value={addData.department}
                onChange={handleAddInputChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Update Modal */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Designation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Designation"
                name="designation"
                value={updateData.designation}
                onChange={handleUpdateInputChange}
              />
              <input
                type="text"
                className="form-control"
                placeholder="Department"
                name="department"
                value={updateData.department}
                onChange={handleUpdateInputChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdateSubmit}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this designation?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Designation;
