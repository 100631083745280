// // PrivateRoute.js
// import React from 'react';
// import { Navigate } from 'react-router-dom';

// const PrivateRoute = ({ element: Component }) => {
//   const token = localStorage.getItem('token'); // Check if token exists in localStorage

//   return token ? Component : <Navigate to="/" />; // Redirect to login if no token
// };

// export default PrivateRoute;



// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component }) => {
  const token = localStorage.getItem('token'); // Check if token exists in localStorage

  return token ? Component : <Navigate to="/" />; // Redirect to login if no token
};

export default PrivateRoute;
