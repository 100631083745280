
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiUrl from "../Environment/ApiUrl";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

function EventTable() {
  const [events, setEvents] = useState([]);
  const [addData, setAddData] = useState({
    id: "",
    date: "",
    college_name: "",
    department: "",
    event_title: "",
    program_timing: "",
    status: "",
    branch_id: "",
    empid: "",
    staff_name: "",
    event_type: "",
    remarks: "",
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null); // To store the event that needs to be deleted

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(apiUrl + "/events");
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEvents();
    fetchBranchData();
    fetchCalendarData();
  }, []);

  // Fetch branch data
  const fetchBranchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/Branch`);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  // Fetch employee data based on selected branch
  const fetchEmployeeData = async (branchId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/staff_based_on_branch/${branchId}`
      );
      setEmpData(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  //   const handleUpdateModal = (eventId) => {
  //     const event = events.find((e) => e._id === eventId);
  //     if (event) {
  //       setAddData({
  //         id: event._id,
  //         date: event.date,
  //         college_name: event.college_name,
  //         department: event.department,
  //         event_title: event.event_title,
  //         program_timing: event.program_timing,
  //         status: event.status,
  //         branch_id: event.branch_id,
  //         empid: event.staff_name ? event.staff_name._id : "",
  //         staff_name: event.staff_name ? event.staff_name.name : "",
  //         event_type: event.event_type,
  //         remarks: event.remarks || "",
  //       });
  //       setShowUpdateModal(true);
  //     }
  //   };

  const handleUpdateModal = (eventId) => {
    const event = events.find((e) => e._id === eventId);
    if (event) {
      setAddData({
        id: event._id,
        date: event.date,
        college_name: event.college_name,
        department: event.department,
        event_title: event.event_title,
        program_timing: event.program_timing,
        status: event.status,
        branch_id: event.branch_id,
        empid: event.staff_name ? event.staff_name._id : "", // Set empid to staff's ID
        staff_name: event.staff_name ? event.staff_name.name : "", // Set staff_name to staff's name
        event_type: event.event_type,
        remarks: event.remarks || "",
      });
      setShowUpdateModal(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      !addData.date ||
      !addData.college_name ||
      !addData.department ||
      !addData.program_timing ||
      !addData.event_title ||
      !addData.branch_id ||
      !addData.empid ||
      !addData.event_type ||
      !addData.status
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    const eventData = {
      ...addData,
      staff_name: addData.empid,
    };

    try {
      if (addData.id) {
        await axios.put(`${apiUrl}/events/${addData.id}`, eventData);
        toast.success("Event updated successfully!");
      } else {
        await axios.post(apiUrl + `/events`, eventData);
        toast.success("Event added successfully!");
      }

      const response = await axios.get(apiUrl + "/events");
      setEvents(response.data);

      setShowUpdateModal(false);
      resetAddData();
    } catch (error) {
      console.error("Error posting/updating event:", error.message);
      toast.error("Error updating event.");
    }
  };

  const resetAddData = () => {
    setAddData({
      id: "",
      date: "",
      college_name: "",
      department: "",
      event_title: "",
      program_timing: "",
      status: "",
      branch_id: "",
      empid: "",
      staff_name: "",
      event_type: "",
      remarks: "",
    });
  };

  function handleOpenModal(id) {
    const event = events.find((e) => e._id === id);
    setEventToDelete(event); // Set the event to be deleted
    setDeleteShowModals(true);
  }

  const handleCloseModal = () => {
    setDeleteShowModals(false);
    setEventToDelete(null); // Clear the event to be deleted
  };

  async function handleDelete() {
    try {
      if (eventToDelete) {
        const response = await axios.delete(
          apiUrl + `/events/${eventToDelete._id}`
        );
        toast.success("Event deleted successfully!");
        setDeleteShowModals(false);
        fetchCalendarData(); // Re-fetch data to refresh the table
      }
    } catch (error) {
      console.error("Error deleting event:", error.message);
      toast.error("Error Deleting Event.");
    }
  }

  const fetchCalendarData = async () => {
    try {
      const response = await axios.get(apiUrl + `/events`);
      setCalendarData(response.data);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  return (
    <>
      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/staff" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>
            <Link to="/event" className="text-danger dashBoard_text">
              <b>Event / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Event Table</b>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <h5 className="mt-1">Event Table</h5>
      </div>

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Event</th>
                <th>Staff</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {events.length === 0 ? (
                <tr>
                  <td colSpan="5">No events available</td>
                </tr>
              ) : (
                events.map((event, index) => (
                  <tr key={event._id}>
                    <td>{index + 1}</td>
                    <td>{event.date ? event.date : "N/A"}</td>
                    <td>{event.event_title}</td>
                    <td>{event.staff_name ? event.staff_name.name : "N/A"}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleUpdateModal(event._id)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btn btn-danger mx-3"
                        onClick={() => handleOpenModal(event._id)}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Update Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Inputs for updating event data */}
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    name="date"
                    value={addData.date}
                    onChange={handleInputChange}
                    required
                  />
                  <label>Date</label>
                </div>
                <div className="floating-label">
                  <select
                    className="form-select"
                    name="branch_id"
                    value={addData.branch_id}
                    onChange={(e) => {
                      handleInputChange(e);
                      fetchEmployeeData(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select Branch</option>
                    {responseDatas.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.branch_name}
                      </option>
                    ))}
                  </select>
                  {/* <label>Select Branch</label> */}
                </div>

                <div className="floating-label">
                  {/* <select
                    className="form-select"
                    name="empid"
                    value={addData.empid}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Staff</option>
                    {empData.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.empid} - {item.name}
                      </option>
                    ))}
                  </select> */}
                  <select
                    className="form-select"
                    name="empid"
                    value={addData.empid}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Staff</option>
                    {empData.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.empid} - {item.name}
                      </option>
                    ))}
                  </select>

                  {/* <label>Select Staff</label> */}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="college_name"
                    value={addData.college_name}
                    onChange={handleInputChange}
                    required
                  />
                  <label>College Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="event_title"
                    value={addData.event_title}
                    onChange={handleInputChange}
                    required
                  />
                  <label>Event Title</label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="department"
                    value={addData.department}
                    onChange={handleInputChange}
                    required
                  />
                  <label>Department</label>
                </div>
                <div className="floating-label">
                  <select
                    className="form-select"
                    name="event_type"
                    value={addData.event_type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Event Type</option>
                    <option value="Seminar">Seminar</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Corporate Trainings">
                      Corporate Trainings
                    </option>
                  </select>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    name="program_timing"
                    value={addData.program_timing}
                    onChange={handleInputChange}
                    required
                  />
                  <label>Program Time</label>
                </div>
                <div className="floating-label">
                  <select
                    className="form-control"
                    name="status"
                    value={addData.status}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Scheduled">Scheduled</option>
                    <option value="InProgress">InProgress</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Name :</h5>{" "}
            <h5>{eventToDelete ? eventToDelete.event_title : "N/A"}</h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EventTable;
