import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";

function Attendance() {
  const [showModal, setShowModal] = useState(false);

  const [responseDatas, setResponseDatas] = useState([]);

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Attendance</b>
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-9">
            <div className="d-flex ">
              <h4 className="mt-2">Attendance</h4>
              <div className="mx-3">
                <button
                  className="btn addBtn add mx-3"
                  onClick={() => setShowModal(true)}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <div className="col-lg-1">
            <button className="btn  btn-danger">Overall</button>
          </div>

          <div className="col-lg-2">
            <div>
              <input
                type="file"
                id="file-upload"
                accept=".xls,.xlsx"
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload" className="btn btn-secondary">
                File Upload
              </label>
            </div>
          </div>
        </div>

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select className="form-control w-100 mx-2">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8"
                      placeholder="Search ..."
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Branch</th>
                  <th>Staff Id </th>
                  <th>Name </th>
                  <th>Date</th>
                  <th>Log In</th>
                  <th>Log Out</th>
                  <th>Working Hour</th>
                  <th className="text-center" id="tablebutton">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>

        {/* Add Modal */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <select className="form-select" required>
                        <option value="">Select Branch</option>
                        {responseDatas.map((item, index) => (
                          <option key={index} value={item._id}>
                             {item.branch_id} - {item.branch_name}
                          </option>
                        ))}
                      </select>
                      {/* <label className="">Select Branch</label> */}
                    </div>

                    <div className="floating-label">
                      <label className="form-label"></label>
                      <select className="form-select">
                        <option value="">Select Staff Type</option>
                      </select>
                    </div>

                    <div className="floating-label">
                      <input className="form-control" type="text" />
                      <label className="">Name</label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <input className="form-control" type="date" required />
                      <label className="">Date</label>
                    </div>

                    <div className="floating-label">
                      <input type="time" className="form-control" />
                      <label className="">Log In</label>
                    </div>

                    {/* Logout Time */}

                    <div className="floating-label">
                      <input
                        type="time"
                        className="form-control"
                        disabled
                        required
                      />
                      <label className="">Logout</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modalbutton">
                <Button
                  variant="secondary"
                  className="mt-3 cancelbut"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
                <button
                  type="submit"
                  className="btn btn-primary mt-3 mx-2 submitbut"
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* _------------------------------pagination button -------------------------------- */}
        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li className="">
                    <button className="page-link">Previous</button>
                  </li>

                  <li className="">
                    <button className="page-link ">1</button>
                  </li>

                  <li className="">
                    <button className="page-link ">Next</button>
                  </li>
                </ul>
              </nav>{" "}
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark ">Page 1 of 1</span>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------------end ---------------------------------- */}
      </div>
    </>
  );
}

export default Attendance;
