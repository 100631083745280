

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
// import apiUrl from "../../environment/Api";
import apiUrl from "./../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Contact_Leads() {
  const [responseData, setResponseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);

  const [isUpdatescheduleShowModal, setUpdatescheduleShowModal] =
    useState(false);
  const [responsesource, setResponsesource] = useState([]);
  const [responsepurpose, setResponsepurpose] = useState([]);
  const [category, setcategory] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [selected_category, setselected_category] = useState("");

  const [staff, setstaff] = useState("");
  const [role_id, setroleid] = useState("");

  const [previllages, setprevillages] = useState({});

  const [addData, setAddData] = useState({
    idlocal: "",
    visitor_staff: "",
    mobile_no: "",
    email: "",
    department: "",
    designation: "",
    company_name: "",
    visit_date: "",
    company_location: "",
    notes: "",
  });

  const [updateData, setUpdateData] = useState({
    idlocal: "",
    visitor_staff: "",
    mobile_no: "",
    email: "",
    department: "",
    designation: "",
    company_name: "",
    visit_date: "",
    company_location: "",
    notes: "",
  });

  const [updateDataschedule, setUpdateDataschedule] = useState({
    idlocal: "",
    visitor_staff: "",
    mobile_no: "",
    email: "",
    department: "",
    designation: "",
    company_name: "",
    visit_date: "",
    company_location: "",
    notes: "",
  });

  useEffect(() => {
    fetchData();
    gettoken();
    fetch();
    fetchDa();
    Enquirypurpose();
    fetchDatag();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/update_lead_with_empid");
      console.log("Response from server conatct :", response.data);
      console.log("Response from server:12345", response.data[0]?.course_id);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDatag = async () => {
    try {
      const response = await axios.get(apiUrl + "/staff");
      console.log("Response from server:", response.data);

      setstaff(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDa = async () => {
    try {
      const responsesources = await axios.get(apiUrl + "/source_type");
      console.log("Response from Source Type:", responsesources.data);
      setResponsesource(responsesources.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const Enquirypurpose = async () => {
    try {
      const responsepurposes = await axios.get(apiUrl + "/enquiry_purpose");
      console.log("Response from Enquiry purpose:", responsepurposes.data);

      setResponsepurpose(responsepurposes.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetch = async () => {
    try {
      const response = await axios.get(apiUrl + "/category_list");
      console.log("Response from server:", response.data);
      console.log("Response from category list :", category);
      setcategory(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    // console.log("the toekn in the localStorage is : ",token);
    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      // setResponseData(response.data);

      console.log("The role id is : ", response.data.detail.rolename);
      setroleid(response.data.detail.rolename);
      console.log("the role_id is : ", role_id);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      // getpages();

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);

          console.log("the reponse list in brach is : ", response.data.modules);

          const modules = response.data.modules;

          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );

          console.log("the modules is : ", filtered_access[0].actions);

          setprevillages(filtered_access[0].actions);

          console.log("the assigned previllages is : ", previllages.add);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        name: idlocal,
        visitor_staff: addData.visitor_staff,
        mobile_no: addData.mobile_no,
        email: addData.email,
        department: addData.department,
        designation: addData.designation,
        company_name: addData.company_name,
        visit_date: addData.visit_date,
        company_location: addData.company_location,
        notes: addData.notes,
      };
      const response = await axios.post(apiUrl + "/contact_leads", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        name: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    // if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log(" the data to updata   onclick data   ", data);
      console.log(" the data to updata   onclick course id   ", data.course_id);
      setUpdateShowModal(true);
      setUpdateData({
        name: idlocal,
        visitor_staff: data.visitor_staff,
        mobile_no: data.mobile_no,
        email: data.email,
        department: data.department,
        designation: data.designation,
        company_name: data.company_name,
        visit_date: data.visit_date,
        company_location: data.company_location,
        notes: data.notes,
      });

      // updateData.course_id = "00187"

      console.log(" the data to updata   onclick   12", updateData.course_id);
    // } else {
    //   toast.error("You don't have the permission");
    // }
  };

  // schedule update data
  const [updateidschedule, setUpdateIdschedule] = useState("");

  const handlescheduleUpdate = (_id, data) => {
    setUpdateIdschedule(_id);
    console.log(" the data to updata", data);

    const courses = responseDatas.find(
      (item) => item.course_name === data.Prefered_course
    );
    console.log("the courserr12", courses);
    if (courses) {
      const coursedata = courses.category_list;
      console.log("the courserr12", courses);
      console.log("the course category_list", coursedata);

      // Set category data to state
      setUpdateDataschedule((prevData) => ({
        ...prevData,
        category: coursedata,
      }));

      setUpdateDataschedule((prevData) => ({
        ...prevData, // Spread the previous state
        course_id: updateDataschedule.Prefered_course, // Update only the course_id field
      }));
    } else {
      console.log("No matching course found for Prefered_course.");
    }

    console.log("filterUpdateData___category ", updateDataschedule.category);

    setUpdatescheduleShowModal(true);
    setUpdateDataschedule({
      name: idlocal,
      visitor_staff: data.visitor_staff,
      mobile_no: data.mobile_no,
      email: data.email,
      department: data.department,
      designation: data.designation,
      company_name: data.company_name,
      visit_date: data.visit_date,
      company_location: data.company_location,
      notes: data.notes,
    });
  };

  
  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        name: idlocal,
        visitor_staff: updateData.visitor_staff,
        mobile_no: updateData.mobile_no,
        email: updateData.email,
        department: updateData.department,
        designation: updateData.designation,
        company_name: updateData.company_name,
        visit_date: updateData.visit_date,
        company_location: updateData.company_location,
        notes: updateData.notes,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        apiUrl + `/contact_leads/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  // schedule

  const handleupdatesubmitschedule = async (e) => {
    e.preventDefault();

    setUpdateDataschedule((prevData) => ({
      ...prevData, // Spread the previous state
      course_id: updateDataschedule.course_id, // Update only the course_id field
    }));

    try {
      const formData = {
        name: idlocal,
        visitor_staff: updateDataschedule.visitor_staff,
        mobile_no: updateDataschedule.mobile_no,
        email: updateDataschedule.email,
        department: updateDataschedule.department,
        designation: updateDataschedule.designation,
        company_name: updateDataschedule.company_name,
        visit_date: updateDataschedule.visit_date,
        company_location: updateDataschedule.company_location,
        notes: updateDataschedule.notes,
      };
      console.log("the update schedule  submit data", formData);
      const response = await axios.post(apiUrl + `/leads`, formData);
      console.log("response for update", response);
      setUpdatescheduleShowModal(false);
      toast.success("Schedule post successfully!");

      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        apiUrl + `/contact_leads/${getdeleteid}`
      );
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleOpenModal = (_id, name) => {
    // if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", name);
      setdeleteid(_id);
      setdeletename(name);
      setDeleteShowModals(true);
    // } else {
    //   toast.error("You don't have the permission");
    // }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  const handleAdditionalChange = async (e) => {
    const selectcategory = e.target.value; // Get the selected value from the dropdown

    setselected_category(e.target.value);

    fetchDatas_2(e.target.value);
    console.log(
      "Additional change function called with value:",
      selectcategory
    );
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async (selected_category_2) => {
    try {
      const response = await axios.get(apiUrl + "/course");
      console.log("Response from server:123", response.data);
      console.log("Response from category list :", category);
      console.log("the selected_category is : ", selected_category_2);

      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDatas_2 = async (selected_category_2) => {
    try {
      const response = await axios.get(
        apiUrl + "/category_course/" + selected_category_2
      );
      console.log("Response from server:", response.data);
      if (response && response.data) {
        const data = response.data;

        setResponseDatas(data);
      } else {
        console.error("No data received from server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  function handleChangeSatus(e) {
    const course = responseDatas.filter(
      (item, index) => item.course_id == e.target.value
    );
    console.log(course, "course---");
    setAddData({
      ...addData,
      course_id: e.target.value,
      selectcourse: course[0]?.course_name || "Course Not Found", // Fallback if no course is found
    });
  }

  const [selectedCourse, setSelectedCourse] = useState(
    updateData.Prefered_course || ""
  );

  const [selectedCoursed, setSelectedCoursed] = useState(
    updateDataschedule.Prefered_course || ""
  );

  // Handle the change event

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setUpdateData({ ...updateData, Prefered_course: event.target.value });
  };

  const handleCourseChangedd = (e) => {
    setSelectedCoursed(e.target.value);
    setUpdateDataschedule({
      ...updateDataschedule,
      Prefered_course: e.target.value,
    });
  };

  const handleStaffChange = (e) => {
    setAddData({ ...addData, visitor_staff: e.target.value });
    setUpdateData({ ...updateData, visitor_staff: e.target.value });
  };
  const [idlocal, setlocalid] = useState([]);

  useEffect(() => {
    fetchData();
    const id_local = localStorage.getItem("username");
    setlocalid(id_local);
  }, []);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
    setAddData({ visit_date: currentDate });
  }, []);

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="#" className="text-secondary dashBoard_text">
                <b>Leads / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Contact Leads</b>
              </Link>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="d-flex">
          <h5 className="mt-2">Contact Leads</h5>
          <div className="mx-3">
            {/* {previllages.add && ( */}
              <button
                className="btn addBtn add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
            {/* )} */}
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                  }}
                >
                  {/* <option value='5'>5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------end------------------------------------------- */}
        <div className="card">
          <div className="mt-1">
            <table className="table table-striped ">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Faculty Name</th>
                  <th>Mobile</th>
                  <th>Company Name</th>
                  <th>Visit Date</th>
                  <th>Staff</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.visitor_staff}</td>
                    <td>{item.mobile_no}</td>
                    <td>{item.company_name}</td>
                    <td>{formatDatefitching(item.visit_date)}</td>
                    <td>{item.name}</td>
                    <td className="text-center">
                      {/* <button className='btn updelete mx-2' onClick={() => handleUpdate(item._id, item)}><i class="fa-solid fa-pen-to-square"></i></button> */}

                      {/* {previllages.edit && ( */}
                        <button
                          className="btn updelete mx-2"
                          onClick={() => handleUpdate(item._id, item)}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      {/* )} */}

                      {/* {previllages.edit && ( */}
                        <button
                          className="btn updelete mx-2"
                          onClick={() => handlescheduleUpdate(item._id, item)}
                        >
                          <i class="fa-solid fa-eye"></i>
                        </button>
                      {/* )} */}

                      {/* {previllages.delete && ( */}
                        <button
                          className="btn delete"
                          onClick={() => handleOpenModal(item._id, item.name)}
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      {/* )} */}

                      {/* <button className='btn delete'
                                                      onClick={() => handleOpenModal(item._id, item.branch_name)}
                                                  > <i class="fa-solid fa-trash-can"></i></button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal for adding a new user */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Contact Leads</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={idlocal || ""} // Use an empty string if idlocal is null or undefined
                      onChange={(e) =>
                        setAddData({ ...addData, idlocal: e.target.value })
                      }
                      disabled
                      required
                    />
                    <label className={idlocal ? "filled" : ""}>Staff</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.visitor_staff}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          visitor_staff: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={addData.visitor_staff ? "filled" : ""}>
                      Faculty Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.mobile_no}
                      onChange={(e) =>
                        setAddData({ ...addData, mobile_no: e.target.value })
                      }
                      required
                    />
                    <label className={addData.mobile_no ? "filled" : ""}>
                      Mobile No
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="Email"
                      placeholder=""
                      value={addData.email}
                      onChange={(e) =>
                        setAddData({ ...addData, email: e.target.value })
                      }
                      required
                    />
                    <label className={addData.email ? "filled" : ""}>
                      Email
                    </label>
                  </div>

                  {/* <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={addData.visit_date}
                      onChange={(e) =>
                        setAddData({ ...addData, visit_date: e.target.value })
                      }
                      required
                    />
                    <label className={addData.visit_date ? "filled" : ""}>
                      Visit Date
                    </label>
                  </div> */}

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={addData.visit_date}
                      onChange={(e) =>
                        setAddData({ ...addData, visit_date: e.target.value })
                      }
                      required
                    />
                    <label className={addData.visit_date ? "filled" : ""}>
                      Visit Date
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.department}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        department: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.department ? "filled" : ""}>
                    Department
                  </label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.designation}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        designation: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.designation ? "filled" : ""}>
                    Designation
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.company_name}
                    onChange={(e) =>
                      setAddData({ ...addData, company_name: e.target.value })
                    }
                    required
                  />
                  <label className={addData.company_name ? "filled" : ""}>
                    Company Name
                  </label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.company_location}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        company_location: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.company_location ? "filled" : ""}>
                  Company Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.notes}
                    onChange={(e) =>
                      setAddData({
                        ...addData,
                        notes: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.notes ? "filled" : ""}>
                    Remarks
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handlesubmit}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isUpdateshowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Contact Leads</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={idlocal}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          idlocal: e.target.value,
                        })
                      }
                      disabled
                      required
                    />
                    <label className={idlocal ? "filled" : ""}>Staff</label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.visitor_staff}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          visitor_staff: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.visitor_staff ? "filled" : ""}>
                      Faculty Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.mobile_no}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          mobile_no: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.mobile_no ? "filled" : ""}>
                      Mobile_no
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.email}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, email: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.email ? "filled" : ""}>
                      Email
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={
                        updateData.visit_date
                          ? formatDatefitching(updateData.visit_date)
                          : ""
                      }
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          visit_date: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.visit_date ? "filled" : ""}>
                      visit Date
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.department}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          department: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.department ? "filled" : ""}>
                      Department
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.designation}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          designation: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.designation ? "filled" : ""}>
                      Designation
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.company_name}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          company_name: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.company_name ? "filled" : ""}>
                    Company Name
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.company_location}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          company_location: e.target.value,
                        })
                      }
                      required
                    />
                    <label
                      className={updateData.company_location ? "filled" : ""}
                    >
                      Company Location
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.notes}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          notes: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.notes ? "filled" : ""}>
                      Remarks
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleupdatesubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Contact Leads schedule  =================================================================================*/}

        <Modal
          show={isUpdatescheduleShowModal}
          onHide={() => setUpdatescheduleShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title> Add Schedule Contact Leads</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateDataschedule.name}
                      onChange={(e) =>
                        setUpdateDataschedule({
                          ...updateDataschedule,
                          name: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label className={updateDataschedule.name ? "filled" : ""}>
                      Staff
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateDataschedule.visitor_staff}
                      onChange={(e) =>
                        setUpdateDataschedule({
                          ...updateDataschedule,
                          visitor_staff: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label
                      className={
                        updateDataschedule.visitor_staff ? "filled" : ""
                      }
                    >
                      Faculty Name
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateDataschedule.mobile_no}
                      onChange={(e) =>
                        setUpdateDataschedule({
                          ...updateDataschedule,
                          mobile_no: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label
                      className={updateDataschedule.mobile_no ? "filled" : ""}
                    >
                      Mobile_no
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateDataschedule.email}
                      onChange={(e) =>
                        setUpdateDataschedule({
                          ...updateDataschedule,
                          email: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label className={updateDataschedule.email ? "filled" : ""}>
                      Email
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      placeholder=""
                      value={
                        updateDataschedule.visit_date
                          ? formatDatefitching(updateDataschedule.visit_date)
                          : ""
                      }
                      onChange={(e) =>
                        setUpdateDataschedule({
                          ...updateDataschedule,
                          visit_date: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label
                      className={
                        updateDataschedule.Enquiry_date ? "filled" : ""
                      }
                    >
                      Visit Date
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.department}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          department: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label className={updateData.department ? "filled" : ""}>
                      Department
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.designation}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          designation: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label className={updateData.designation ? "filled" : ""}>
                      Designation
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.company_name}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          company_name: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label className={updateData.company_name ? "filled" : ""}>
                      Company Name
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.company_location}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          company_location: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label
                      className={updateData.company_location ? "filled" : ""}
                    >
                      Company Location
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.notes}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          notes: e.target.value,
                        })
                      }
                      required
                      disabled
                    />
                    <label className={updateData.notes ? "filled" : ""}>
                      Remarks
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdatescheduleShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleupdatesubmitschedule}
            >
              Sumbit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ----------------delete */}

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to Delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1">Name :</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* _------------------------------pagination button -------------------------------- */}

        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li
                    className={` page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={goToPrevPage}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li
                      key={i}
                      className={`mx-2 page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link "
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link " onClick={goToNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>{" "}
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark ">
                  Page {currentPage} of {totalPages}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------------end ---------------------------------- */}
      </div>
    </>
  );
}

export default Contact_Leads;
