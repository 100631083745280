import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";

function NewEnquiry() {
  const [showModal, setShowModal] = useState(false);
  const [updateShowModal, setUpdateShowModal] = useState(false);
  const [branch, setBranch] = useState([]);
  const [purpose, setpurpose] = useState([]);
  const [source, setsource] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [addData, setAddData] = useState({
    branch_id: "",
    name: "",
    company_name: "",
    designation: "",
    official_email: "",
    alternate_email: "",
    official_mobile: "",
    alternate_mobile: "",
    location: "",
    notes: "",
    source_type: "",
    enquiry_date: "",
    purpose_enquiry: "",
    followups_status: "New",
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  // Fetch data from API
  useEffect(() => {
    fetchData();
    fetchDatased();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/leadsnew");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDatased = async () => {
    try {
      const response = await axios.get(ApiUrl + "/branch");
      const responsepurposes = await axios.get(ApiUrl + "/enquiry_purpose");
      const responsesourses = await axios.get(ApiUrl + "/source_type");
      setsource(responsesourses.data);
      setpurpose(responsepurposes.data);
      setBranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const formdata = {
        branch_id: addData.branch_id,
        name: addData.name,
        company_name: addData.company_name,
        designation: addData.designation,
        official_email: addData.official_email,
        alternate_email: addData.alternate_email,
        official_mobile: addData.official_mobile,
        alternate_mobile: addData.alternate_mobile,
        location: addData.location,
        notes: addData.notes,
        source_type: addData.source_type,
        enquiry_date: addData.enquiry_date,
        purpose_enquiry: addData.purpose_enquiry,
        followups_status: "New",
      };
      console.log("Form Data:", formdata);

      const responseFormData = await axios.post(ApiUrl + "/leads", formdata);
      console.log("Response from POST:", responseFormData);
      setShowModal(false);
      toast.success("Added successfully!");
      fetchData(); // Refresh the data after adding
      setAddData({
        branch_id: "",
        name: "",
        company_name: "",
        designation: "",
        official_email: "",
        alternate_email: "",
        official_mobile: "",
        alternate_mobile: "",
        location: "",
        notes: "",
        source_type: "",
        enquiry_date: "",
        purpose_enquiry: "",
        followups_status: "New",
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to add enquiry.");
    }
  };

  const handleupdate = () => {

  }

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleOpenModal = (_id, name) => {
    console.log(" the id", _id);
    setdeleteid(_id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  const handleUpdateModal = (_id) => {
    console.log("the Id is :", _id);
    setUpdateShowModal(true);
  }


  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(ApiUrl + `/leads/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  useEffect(() => {
    // Set the current date as the default value for enquiry_date
    const currentDate = new Date().toISOString().split("T")[0]; // Get the current date in yyyy-mm-dd format
    setAddData((prevData) => ({
      ...prevData,
      enquiry_date: currentDate,
    }));
  }, []);

  const getStatusLabelClass = (status) => {
    switch (status) {
      case "New":
        return "bg-primary1 text-white1";

      default:
        return "";
    }
  };

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="/" className="text-secondary dashBoard_text">
                <b>Leads / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>New Enquiry</b>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 d-flex">
            <h5 className="mt-2">New Enquiry</h5>
            <div className="mx-3">
              <button
                className="btn addBtn add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
            </div>
          </div>
        </div>

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                  }}
                >
                  {/* <option value='5'>5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={searchQuery}
                      // onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Enquiry Type</th>
                  <th>Enquiry Date</th>
                  <th>Source Type</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name || "N/A"}</td>
                    <td>{item.purpose_enquiry?.enquiry_purpose}</td>
                    <td>{item.enquiry_date || "N/A"}</td>
                    <td>{item.source_type_details[0]?.source_type}</td>
                    
                    <td>
                      <p
                        className={`text-center ${getStatusLabelClass(
                          item.followups_status
                        )}`}
                      >
                        {item.followups_status}
                      </p>
                    </td>

                    <td className="text-center">
                      <button className="btn updelete" onClick={() => handleUpdateModal(item._id, item.branch_name)}>
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btn delete mx-1"
                        onClick={() =>
                          handleOpenModal(item._id, item.branch_name)
                        }
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal Popup Start */}
        <div className="insermodal">
          <Modal
            className="addmodals"
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>New Enquiry</Modal.Title>
            </Modal.Header>
            <form onSubmit={handlesubmit}>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="floating-label mt-1">
                      <select
                        className="form-select"
                        value={addData.branch_id}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            branch_id: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select Branch</option>
                        {branch.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.branch_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="floating-label">
                      <select
                        className="form-select"
                        value={addData.purpose_enquiry}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            purpose_enquiry: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select Purpose Enquiry</option>
                        {purpose.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.enquiry_purpose}{" "}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.name}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            name: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.name ? "filled" : ""}>
                        Name
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.company_name}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            company_name: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.company_name ? "filled" : ""}>
                        Company Name
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.designation}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            designation: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.designation ? "filled" : ""}>
                        Designation
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="email"
                        placeholder=""
                        value={addData.official_email}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            official_email: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.official_email ? "filled" : ""}>
                        Official Email
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.alternate_email}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            alternate_email: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.alternate_email ? "filled" : ""}
                      >
                        Alternate Email
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.official_mobile}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            official_mobile: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.official_mobile ? "filled" : ""}
                      >
                        Official Mobile
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.alternate_mobile}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            alternate_mobile: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.alternate_mobile ? "filled" : ""}
                      >
                        Alternate Mobile
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.location}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            location: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.location ? "filled" : ""}>
                        Location
                      </label>
                    </div>

                    <div className="floating-label">
                      <textarea
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.notes}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            notes: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.notes ? "filled" : ""}>
                        Notes
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="date"
                        placeholder=""
                        value={addData.enquiry_date}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            enquiry_date: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.enquiry_date ? "filled" : ""}>
                        Enquiry Date
                      </label>
                    </div>

                    <div className="floating-label mt-1">
                      <label className="form-label">
                        <b></b>
                      </label>
                      <select
                        className="form-select"
                        placeholder=""
                        value={addData.source_type}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            source_type: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select Source Type</option>
                        {source.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.source_type}
                          </option>
                        ))}
                      </select>
                      <label
                        className={addData.source_type ? "filled" : ""}
                      ></label>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cancelbut"
                  variant="secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
                <Button className="submitbut" type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>

      {/* update modal */}

       <div className="insermodal">
          <Modal
            className="addmodals"
            show={updateShowModal}
            onHide={() => setUpdateShowModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update New Enquiry</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleupdate}>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="floating-label mt-1">
                      <select
                        className="form-select"
                        value={addData.branch_id}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            branch_id: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select Branch</option>
                        {branch.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.branch_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="floating-label">
                      <select
                        className="form-select"
                        value={addData.purpose_enquiry}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            purpose_enquiry: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select Purpose Enquiry</option>
                        {purpose.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.enquiry_purpose}{" "}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.name}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            name: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.name ? "filled" : ""}>
                        Name
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.company_name}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            company_name: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.company_name ? "filled" : ""}>
                        Company Name
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.designation}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            designation: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.designation ? "filled" : ""}>
                        Designation
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="email"
                        placeholder=""
                        value={addData.official_email}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            official_email: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.official_email ? "filled" : ""}>
                        Official Email
                      </label>
                    </div>
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.alternate_email}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            alternate_email: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.alternate_email ? "filled" : ""}
                      >
                        Alternate Email
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.official_mobile}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            official_mobile: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.official_mobile ? "filled" : ""}
                      >
                        Official Mobile
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.alternate_mobile}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            alternate_mobile: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.alternate_mobile ? "filled" : ""}
                      >
                        Alternate Mobile
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.location}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            location: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.location ? "filled" : ""}>
                        Location
                      </label>
                    </div>

                    <div className="floating-label">
                      <textarea
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.notes}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            notes: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.notes ? "filled" : ""}>
                        Notes
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="date"
                        placeholder=""
                        value={addData.enquiry_date}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            enquiry_date: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.enquiry_date ? "filled" : ""}>
                        Enquiry Date
                      </label>
                    </div>

                    <div className="floating-label mt-1">
                      <label className="form-label">
                        <b></b>
                      </label>
                      <select
                        className="form-select"
                        placeholder=""
                        value={addData.source_type}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            source_type: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select Source Type</option>
                        {source.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.source_type}
                          </option>
                        ))}
                      </select>
                      <label
                        className={addData.source_type ? "filled" : ""}
                      ></label>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cancelbut"
                  variant="secondary"
                  onClick={() => setUpdateShowModal(false)}
                >
                  Cancel
                </Button>
                <Button className="submitbut" type="submit" variant="primary">
                  Update
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>

      {/* update modal */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">New Enquiry : </h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewEnquiry;
