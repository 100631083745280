// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar"; // Assuming Sidebar is a component that wraps the pages
import Dashboard from "./components/pages/Dashboard";
import Role from "./components/pages/Role";
import AddRole from "./components/pages/AddRole";
import User from "./components/pages/User";
import PrivateRoute from "./components/SignupLogin/PrivateRoute "; // Import PrivateRoute
import Login from "./components/SignupLogin/Login";
import Branch from "./components/pages/Branch";
import Contact_Leads from "./components/pages/Contact_Leads";
import Enquiry from "./components/pages/Enquiry";
import NewEnquiry from "./components/pages/NewEnquiry";
import Followsups from "./components/pages/Followups";
import Funnel from "./components/pages/Funnel";
import WalkInDetails from "./components/pages/WalkInDetails";
import Sourcetype from "./components/pages/Sourcetype";
import EnquiryPurpose from "./components/pages/EnquiryPurpose";
import Completed from "./components/pages/Completed";
import Ongoing from "./components/pages/Ongoing";
import Travel_Allowance from "./components/pages/Travel_Allowance";
import Designation from "./components/pages/Designation";
import Leave from "./components/pages/Leave";
import SalaryDetails from "./components/pages/SalaryDetails";
import Holiday from "./components/pages/Holiday";
import PurchaseOrder from "./components/pages/PurchaseOrder";
import Staff from "./components/pages/Staff";
import Attendance from "./components/pages/Attendance";
import Supplier from "./components/pages/Supplier";
import Stock from "./components/pages/Stock";
import SalesOrder from "./components/pages/SalesOrder";
import Quotation from "./components/pages/Quotation";
import Customer from "./components/pages/Customer";
import SalesInvoice from "./components/pages/SalesInvoice";
import AddSalesInvoice from "./components/pages/AddSalesInvoice";
import UpcomingServices from "./components/pages/UpcomingServices";
import AddPurchaseOrder from "./components/pages/AddPurchaseOrder";
import Products from "./components/pages/Products";
import Category from "./components/pages/Category";
import OngoingServices from "./components/pages/OngoingServices";
import CompleteServices from "./components/pages/CompleteServices";
import AssetList from "./components/pages/AssetList";
import AssetType from "./components/pages/AssetType";
import ExpenseList from "./components/pages/ExpenseList";
import ExpenseType from "./components/pages/ExpenseType";
import VendorDetails from "./components/pages/VendorDetails";
import Office_Expense from "./components/pages/Office_Expense";
import PayType from "./components/pages/PayType";
import IncomeList from "./components/pages/IncomeList";
import IncomeType from "./components/pages/IncomeType";
import Invoice from "./components/pages/Invoice";
import DailyReport from "./components/pages/DailyReport";
import EnquiryReport from "./components/pages/EnquiryReport";
import AttendanceReport from "./components/pages/AttendanceReport";
import ExpenseReport from "./components/pages/ExpenseReport";
import ProjectReport from "./components/pages/ProjectReport";
import StaffReport from "./components/pages/StaffReport";
import IncomeReport from "./components/pages/IncomeReport";
import Upcoming from "./components/pages/Upcoming";
import ExpectedHistory from "./components/pages/ExpectedHistroy";
import AddStaff from "./components/pages/AddStaff";
import UpdateStaff from "./components/pages/UpdateStaff";
import "./App.css";
import DailyTask from "./components/pages/DailyTask";
import Event from "./components/pages/Event";
import Charges from "./components/pages/Charges";
import EventTable from "./components/pages/EventTable";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />{" "}
        {/* Login page accessible to all */}
        {/* Use PrivateRoute to wrap protected routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Dashboard />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/role"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Role />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/addrole"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <AddRole />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/user"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <User />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/branch"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Branch />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/contactleads"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Contact_Leads />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/enquiry"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Enquiry />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/newenquiry"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <NewEnquiry />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/followsups"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Followsups />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/funnel"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Funnel />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/Walkin"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <WalkInDetails />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/sourcetype"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Sourcetype />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/Enquirypurpose"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <EnquiryPurpose />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/upcomings"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Upcoming />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/expected_histroy"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <ExpectedHistory />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/completed"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Completed />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/ongoing"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Ongoing />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/travelallowance"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Travel_Allowance />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/charge"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Charges />
                </Sidebar>
              }
            />
          }
        />

        <Route
          path="/designation"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Designation />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/leave"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Leave />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/salarydetails"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <SalaryDetails />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/holiday"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Holiday />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/purchaseorder"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <PurchaseOrder />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/staff"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Staff />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/addstaff"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <AddStaff />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/updatestaff/:id"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <UpdateStaff />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/attendance"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Attendance />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/event"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Event />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/eventTable"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <EventTable />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/dailytask"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <DailyTask />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/supplier"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Supplier />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/stock"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Stock />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/salesorder"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <SalesOrder />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/Quotation"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Quotation />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/customer"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Customer />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/salesinvoice"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <SalesInvoice />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/addsalesinvoice"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <AddSalesInvoice />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/upcomingservices"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <UpcomingServices />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/addpurchaseorder"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <AddPurchaseOrder />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Products />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/category"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Category />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/ongoingservices"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <OngoingServices />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/completeservices"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <CompleteServices />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/assetlist"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <AssetList />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/assettype"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <AssetType />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/expenselist"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <ExpenseList />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/expensetype"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <ExpenseType />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/vendordetails"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <VendorDetails />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/officeexpense"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Office_Expense />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/paytype"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <PayType />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/stocks"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Stock />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/incomelist"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <IncomeList />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/incometype"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <IncomeType />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/invoice"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <Invoice />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/dailyreport"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <DailyReport />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/enquriyreport"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <EnquiryReport />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/attendancereport"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <AttendanceReport />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/expensereport"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <ExpenseReport />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/projectreport"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <ProjectReport />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/staffreport"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <StaffReport />
                </Sidebar>
              }
            />
          }
        />
        <Route
          path="/incomereport"
          element={
            <PrivateRoute
              element={
                <Sidebar>
                  <IncomeReport />
                </Sidebar>
              }
            />
          }
        />
        {/* Add other protected routes here */}
      </Routes>
    </Router>
  );
};

export default App;
