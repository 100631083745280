function AttendanceReport() {
  return (
    <>
      <div className="container-fluid">
        <div
          className="card p-2"
          style={{
            marginTop: "-80px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div className="row">
            <h6 className="text-center">
              <b>Attendance Report</b>
            </h6>
          </div>
          <hr />
          <div className="row p-2">
            <div className="col-lg-2 col-md-3 col-sm-12 mx-3">
              <input type="date" className="form-control" />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12">
              <input type="date" className="form-control" />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12">
              <select className="form-control">
                <option value="">Company</option>
              </select>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12">
              <select className="form-control">
                <option value="">Select Status</option>
              </select>
            </div>

            <div className="col-lg-1 col-md-2 col-sm-12">
              <button className="btn btn-danger">Clear</button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-3 d-flex align-items-center">
              <p className="mb-0 mr-2">Show: </p>
              <select className="form-control mx-2" style={{ width: "50px" }}>
                {/* <option value={5}>5</option> */}
                <option value={10}>10</option>
                <option value={15}>20</option>
                <option value="">All</option>
              </select>
            </div>

            <div className="col-md-6">
              <button className="btn btn-secondary mx-2">Copy</button>
              <button className="btn btn-success mx-2">CSV</button>
              <button className="btn btn-danger mx-2">Excel</button>
              <button className="btn btn-info mx-2">PDF</button>
              <button className="btn btn-warning mx-2">Print</button>
            </div>

            <div className="col-md-3 d-flex align-items-center">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
              />
            </div>
          </div>
          <table
            id="tableId"
            className="table table-striped table-bordered mt-2"
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Mobile No</th>
                <th>Company</th>
                <th>Source Type</th>
                <th>Enquiry Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>VenuAravind</td>
                <td>9988776655</td>
                <td>HTML</td>
                <td>Google Ad</td>
                <td>November 30, 2024</td>
                <td>Qualified</td>
              </tr>
            </tbody>
          </table>

          <div className="row">
            <div className="col-lg-4">
              <div className="dataTables_info" role="status" aria-live="polite">
                Showing 1 to 1 entries
              </div>
            </div>
            <div className="col-lg-5"></div>
            <div className="col-lg-3">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className="">
                    <a className="page-link">Previous</a>
                  </li>

                  <li className="">
                    <a className="page-link">1</a>
                  </li>

                  <li className="">
                    <a className="page-link">Next</a>
                  </li>
                </ul>
              </nav>
            </div>

            {/* <div className="col-lg-5"></div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AttendanceReport;
