import axios from "axios";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";

function Products() {
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]); // Array to hold products data

  const [addData, setAddData] = useState({
    product_id: "",
    product_name: "",
    category: "",
    hsn_code: "",
    description: "",
    status: "",
  });

  const [updateData, setUpdateData] = useState({
    product_id: "",
    product_name: "",
    category: "",
    hsn_code: "",
    description: "",
    status: "",
  });

  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [deleteShowModal, setDeleteShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null); // Store the ID of the product to be deleted
  const [updateid, setUpdateId] = useState("");

  // Fetch Products Data
  const fetchProducts = async () => {
    try {
      const response = await axios.get(ApiUrl + "/product");
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Handle input change for adding a new product
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle Add Product Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(ApiUrl + "/product", addData);
      console.log("Product added:", response);
      setShowModal(false);
      setAddData({
        product_id: "",
        product_name: "",
        category: "",
        hsn_code: "",
        description: "",
        status: "",
      });
      fetchProducts();
    } catch (error) {
      console.log("Error adding product:", error);
    }
  };

  // Handle Update Button Click
  const handleUpdate = (product) => {
    setUpdateId(product._id); // Set the ID for updating
    setUpdateData(product); // Set the current product data
    setUpdateShowModal(true); // Open the update modal
  };

  // Handle Update Submit
  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        ApiUrl + `/product/${updateid}`,
        updateData
      );
      console.log("Product updated:", response);
      setUpdateShowModal(false);
      fetchProducts(); // Refresh the product list
      toast.success("Product updated successfully!");
    } catch (error) {
      console.log("Error updating product:", error);
    }
  };

  // Handle Delete Button Click
  const handleDelete = (id) => {
    setDeleteId(id); // Set the ID for deletion
    setDeleteShowModal(true); // Open the delete confirmation modal
  };

  // Handle Delete Submit
  const handleDeleteSubmit = async () => {
    try {
      await axios.delete(ApiUrl + `/product/${deleteId}`);
      setDeleteShowModal(false); // Close the delete modal
      fetchProducts(); // Refresh the product list
      toast.error("Product deleted successfully!");
    } catch (error) {
      console.log("Error deleting product:", error);
    }
  };

  return (
    <div>
      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/" className="text-secondary dashBoard_text">
              <b>Products / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Product List</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 d-flex">
          <h5 className="mt-2">Product</h5>
          <div className="mx-3">
            <button
              className="btn addBtn add mx-3"
              onClick={() => setShowModal(true)}
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                // onChange={(e) => {
                //   setItemsPerPage(e.target.value);
                // }}
              >
                {/* <option value='5'>5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    // value={searchQuery}
                    // onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Category</th>
                  <th>Product Id</th>
                  <th>Product Name</th>
                  <th>HSN Code</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={product._id}>
                    <td>{index + 1}</td>
                    <td>{product.category}</td>
                    <td>{product.product_id}</td>
                    <td>{product.product_name}</td>
                    <td>{product.hsn_code}</td>
                    <td>{product.description}</td>
                    <td>
                      <button
                        className={`btn ${
                          product.status === "Active"
                            ? "bg-success"
                            : "bg-warning"
                        } text-white`}
                      >
                        {product.status}
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn updelete"
                        onClick={() => handleUpdate(product)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btn delete mx-1"
                        onClick={() => handleDelete(product._id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add Product Modal */}
      <Modal
        className="addmodals"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Product</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="category"
                    value={addData.category}
                    onChange={handleInputChange}
                    required
                  />
                  <label className={addData.category ? "filled" : ""}>
                    Category
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    name="product_id"
                    value={addData.product_id}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Product Id</option>
                    <option value="001">001</option>
                    <option value="002">002</option>
                  </select>
                  {/* <label className={addData.category ? "filled" : ""}>
                    Product ID
                  </label> */}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="product_name"
                    value={addData.product_name}
                    onChange={handleInputChange}
                    required
                  />
                  <label className={addData.product_name ? "filled" : ""}>
                    Product Name
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="hsn_code"
                    value={addData.hsn_code}
                    onChange={handleInputChange}
                    required
                  />
                  <label className={addData.hsn_code ? "filled" : ""}>
                    HSN Code
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="description"
                    value={addData.description}
                    onChange={handleInputChange}
                    required
                  />
                  <label className={addData.description ? "filled" : ""}>
                    Description
                  </label>
                </div>
                <div className="floating-label">
                  <select
                    className="form-select"
                    name="status"
                    value={addData.status}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                  {/* <label className={addData.description ? "filled" : ""}>
                  Select Status
                  </label> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button className="submitbut" type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Update Product Modal */}
      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Product</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleUpdateSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Category"
                    name="category"
                    value={updateData.category}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, category: e.target.value })
                    }
                    required
                  />
                  <label className={addData.category ? "filled" : ""}>
                    Category
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    name="product_id"
                    value={updateData.product_id}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Product Id</option>
                    <option value="001">001</option>
                    <option value="002">002</option>
                  </select>
                  {/* <label className={addData.category ? "filled" : ""}>
                    Product ID
                  </label> */}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Product Name"
                    name="product_name"
                    value={updateData.product_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        product_name: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.product_name ? "filled" : ""}>
                    Product Name
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="HSN Code"
                    name="hsn_code"
                    value={updateData.hsn_code}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, hsn_code: e.target.value })
                    }
                    required
                  />
                  <label className={addData.hsn_code ? "filled" : ""}>
                    HSN Code
                  </label>
                </div>
                {/* <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={updateData.description}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                </div> */}
                <div className="floating-label">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={updateData.description}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={addData.description ? "filled" : ""}>
                  Description
                  </label>
                </div>
                <div className="floating-label">
                  <select
                    className="form-select"
                    name="status"
                    value={updateData.status}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, status: e.target.value })
                    }
                    required
                  >
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setUpdateShowModal(false)}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={deleteShowModal} onHide={() => setDeleteShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubmit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Products;
