import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ApiUrl from "./../Environment/ApiUrl";
import { toast } from "react-toastify";

function AddStaff() {
  const [activeStep, setActiveStep] = useState(0);
  const [addData, setAddData] = useState({
    branch_id: "",
    empid: "",
    name: "",
    fathername: "",
    email: "",
    official_email: "",
    personal_Mobileno: "",
    mobile: "",
    dateofbirth: "",
    interview_date: "",
    join_date: "",
    address: "",
    Permanent_Address: "",
    salary: "",
    designation: "",
    qualification: "",
    notes: "",
    timings: "",
    status: "",
    relive_date: "",
    documents: "",
    aadhar_number: "",
    photo_upload: "",

    deviceUserId: "",
  });
  const [imageFiles, setImageFiles] = useState(null);

  const [files, setFiles] = useState([]);
  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };
  console.log("llolllll", addData);

  const handleSubmit = async () => {
    try {
      // Create FormData
      const formData = new FormData();
      formData.append("branch_id", addData.branch_id);
      formData.append("empid", addData.empid);
      formData.append("name", addData.name);
      formData.append("email", addData.email);
      formData.append("official_email", addData.official_email);
      formData.append("fathername", addData.fathername);
      formData.append("mobile", addData.mobile);
      formData.append("personal_Mobileno", addData.personal_Mobileno);
      formData.append("dateofbirth", addData.dateofbirth);
      formData.append("interview_date", addData.interview_date);
      formData.append("join_date", addData.join_date);
      formData.append("salary", addData.salary);
      formData.append("designation", addData.designation);
      formData.append("qualification", addData.qualification);
      formData.append("notes", addData.notes);
      formData.append("timings", addData.timings);
      formData.append("status", addData.status);
      formData.append("aadhar_number", addData.aadhar_number);
      formData.append("relive_date", addData.relive_date);

      // Append files if any
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append("documents", files[i]);
        }
      }

      // Send the form data to the API using axios
      const response = await axios.post(ApiUrl + `/staff`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Required for file uploads
        },
      });

      // Handle the response status
      if (response.status === 200) {
        toast.success("Staff added successfully");
        navigate("/Staff"); // Navigate to the staff page
      }
    } catch (error) {
      toast.error("Staff addition failed");
      console.error("Error adding data:", error.message);
    }
  };

  const navigate = useNavigate(); // Navigation function

  // Handle "Next" click
  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep(1); // Go to Step 2
    } else if (activeStep === 1) {
      setActiveStep(2); // Go to Step 3
    }
  };

  // Handle "Back" click
  const handleBack = () => {
    if (activeStep === 1) {
      setActiveStep(0); // Go back to Step 1
    } else if (activeStep === 2) {
      setActiveStep(1); // Go back to Step 2
    }
  };

  // Handle "Cancel" click
  const handleCancel = () => {
    navigate("/staff"); // Navigate to the staff page
  };

  useEffect(() => {
    fetchDatas();
    fetchDesign();
    fetchD();
  }, []);

  //   const handleempid = (e) => {};
  const [generateempidData, setgenerateempidData] = useState([]);
  const fetchD = async () => {
    try {
      const response = await axios.get(ApiUrl + "/generate_empid");
      console.log("Response from server generate:", response.data.empid);
      setgenerateempidData(response.data.empid);
      addData.empid = response.data.empid;
      console.log("schudule", response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [isCheckBox, setIsCheckBox] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsCheckBox(e.target.checked); // Updates the state based on the checkbox status
  };
  const [responseDatas, setResponseDatas] = useState([]);
  const [responseDesignation, setResponseDesignation] = useState([]);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(ApiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDesign = async () => {
    try {
      const response = await axios.get(ApiUrl + "/designation");
      console.log("Response from server:", response.data);
      setResponseDesignation(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/staff" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>
            <Link to="/addstaff" className="text-danger dashBoard_text">
              <b>Add Staff</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <h3>Add Staff</h3>
        <div className="row mt-3">
          {activeStep === 0 && (
            <div className="col-lg-12">
              <div className="card p-2 pb-0">
                <h4 className="mt-1 mb-3">Step 1</h4>
                <div className="form-group">
                  <div className="row mb-5">
                    <div className="col-lg-6">
                      <div className="form-group ">
                        <div className="floating-label">
                          <select
                            className="form-select"
                            value={addData.branch_id}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                branch_id: e.target.value,
                              })
                            }
                            required
                          >
                            <option value=""> Branch </option>
                            {responseDatas.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.branch_id} - {item.branch_name}
                              </option>
                            ))}
                          </select>
                          <label className={addData.branch_id ? "filled" : ""}>
                            Branch
                          </label>
                        </div>

                        {generateempidData && (
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.empid}
                              // value= {generateempidData}
                              onChange={(e) => {
                                // handleempid(e); // Call your handleempid function
                                setAddData({
                                  ...addData,
                                  empid: e.target.value, // Update the empid field in the state
                                });
                              }}
                              required
                              disabled
                            />

                            <label className={addData.empid ? "filled" : ""}>
                              Staff Id
                            </label>
                          </div>
                        )}

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.name}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <label className={addData.name ? "filled" : ""}>
                            Name
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.fathername}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                fathername: e.target.value,
                              })
                            }
                            required
                          />
                          <label className={addData.fathername ? "filled" : ""}>
                            Father Name
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.department}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                department: e.target.value,
                              })
                            }
                            required
                          />
                          <label className={addData.department ? "filled" : ""}>
                            Select Department
                          </label>
                        </div>
                        {/* <div className="floating-label">
                          <select
                            className="form-select"
                            value={addData.designation}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                designation: e.target.value,
                              })
                            }
                            required
                          >
                            <option value=""> Branch </option>
                            {responseDesignation.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.designation}
                              </option>
                            ))}
                          </select>
                          <label
                            className={addData.designation ? "filled" : ""}
                          >
                            Select Designation
                          </label>
                        </div> */}
                        <div className="floating-label">
                          <select
                            className="form-select"
                            value={addData.designation} // Ensure that the correct value is selected
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                designation: e.target.value, // Store the selected designation ID
                              })
                            }
                            required
                          >
                            <option value="">Select Designation</option>
                            {responseDesignation.map((item, index) => (
                              <option key={index} value={item._id}>
                                {" "}
                                {/* Ensure the value is the _id */}
                                {item.designation}
                              </option>
                            ))}
                          </select>
                          <label
                            className={addData.designation ? "filled" : ""}
                          >
                            Select Designation
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.aadhar_number}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                aadhar_number: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={addData.aadhar_number ? "filled" : ""}
                          >
                            Aadhaar Number
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.qualification}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                qualification: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={addData.qualification ? "filled" : ""}
                          >
                            Qualification
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group ">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.mobile}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                mobile: e.target.value,
                              })
                            }
                            required
                          />
                          <label className={addData.mobile ? "filled" : ""}>
                            Official Mobile
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.personal_Mobileno}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                personal_Mobileno: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={
                              addData.personal_Mobileno ? "filled" : ""
                            }
                          >
                            Personal Mobile
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.official_email}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                official_email: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={addData.official_email ? "filled" : ""}
                          >
                            Official Email
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.email}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                email: e.target.value,
                              })
                            }
                            required
                          />
                          <label className={addData.email ? "filled" : ""}>
                            Personal Email
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            placeholder=""
                            value={addData.dateofbirth}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                dateofbirth: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={addData.dateofbirth ? "filled" : ""}
                          >
                            Date Of Birth
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.Permanent_Address}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                Permanent_Address: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={
                              addData.Permanent_Address ? "filled" : ""
                            }
                          >
                            Permanent Address
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.address}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                address: e.target.value,
                              })
                            }
                            required
                          />
                          <label className={addData.address ? "filled" : ""}>
                            Current Address
                          </label>
                        </div>
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData.deviceUserId}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                deviceUserId: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={addData.deviceUserId ? "filled" : ""}
                          >
                            Device User ID
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-3" style={{ marginLeft: "40%" }}>
                <Button
                  onClick={handleCancel}
                  className="mx-2"
                  style={{ width: "100px", height: "40px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleNext}
                  className="btn-secondary"
                  style={{ width: "100px", height: "40px" }}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {activeStep === 1 && (
            <div className="col-lg-12">
              <div className="card p-2 pb-4">
                <h4>Step 2</h4>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={addData.password}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              password: e.target.value,
                            })
                          }
                          required
                        />
                        <label className={addData.password ? "filled" : ""}>
                          Password
                        </label>
                      </div>
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="date"
                          placeholder=""
                          value={addData.interview_date}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              interview_date: e.target.value,
                            })
                          }
                          required
                        />
                        <label
                          className={addData.interview_date ? "filled" : ""}
                        >
                          Interview Date
                        </label>
                      </div>

                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="date"
                          placeholder=""
                          value={addData.join_date}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              join_date: e.target.value,
                            })
                          }
                          required
                        />
                        <label className={addData.join_date ? "filled" : ""}>
                          Joining Date
                        </label>
                      </div>
                      <div className="floating-label">
                        <select
                          className="form-select"
                          value={addData.marital_status}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              marital_status: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Martial</option>
                          <option value="Male">Married</option>
                          <option value="Female">UnMarried</option>
                        </select>
                        <label
                          className={addData.marital_status ? "filled" : ""}
                        >
                          Martial Status
                        </label>
                      </div>
                      <div className="floating-label">
                        <select
                          className="form-select"
                          value={addData.gender}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              gender: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        <label className={addData.gender ? "filled" : ""}>
                          Select Gender
                        </label>
                      </div>
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="file"
                          placeholder=""
                          value={addData.photo_upload}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              photo_upload: e.target.value,
                            })
                          }
                          required
                        />
                        <label className={addData.photo_upload ? "filled" : ""}>
                          Photo Upload
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="tel"
                          placeholder=""
                          value={addData.salary}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              salary: e.target.value,
                            })
                          }
                          required
                        />
                        <label className={addData.salary ? "filled" : ""}>
                          Salary
                        </label>
                      </div>
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={addData.timings}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              timings: e.target.value,
                            })
                          }
                          required
                        />
                        <label className={addData.timings ? "filled" : ""}>
                          Timings
                        </label>
                      </div>
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={addData.notes}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              notes: e.target.value,
                            })
                          }
                          required
                        />
                        <label className={addData.notes ? "filled" : ""}>
                          Notes
                        </label>
                      </div>
                      <div className="floating-label">
                        <select
                          className="form-select"
                          value={addData.status}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              status: e.target.value,
                            })
                          }
                          required
                        >
                          <option value=""> Select Status</option>
                          <option value="Active">Active</option>
                          <option value="InActive">InActive</option>
                          <option value="Relieve">Relieve</option>
                        </select>
                        <label className={addData.status ? "filled" : ""}>
                          Select Status
                        </label>
                      </div>
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="file"
                          placeholder=""
                          value={addData.documents}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              documents: e.target.value,
                            })
                          }
                          required
                        />
                        <label className={addData.documents ? "filled" : ""}>
                          Document Upload
                        </label>
                      </div>
                    </div>

                    {/* <Button onClick={handleBack}>Back</Button>
                    <Button onClick={handleNext}>Overall</Button> */}
                  </div>
                </div>
              </div>
              <div className="d-flex mt-3" style={{ marginLeft: "40%" }}>
                <Button
                  onClick={handleBack}
                  className="mx-2"
                  style={{ width: "100px", height: "40px" }}
                >
                  Back
                </Button>
                <Button
                  onClick={handleNext}
                  className="btn-secondary"
                  style={{ width: "100px", height: "40px" }}
                >
                  Overall
                </Button>
              </div>
            </div>
          )}

          {activeStep === 2 && (
            <div className="col-lg-12">
              <div className="container">
                <div className="row" style={{ marginTop: "" }}>
                  <div className="col-lg-3">
                    <div className="card carddetails p-2">
                      <div>
                        <div className="imgdetails">
                          <div className="imgdetails">
                            <img
                              src={imageFiles}
                              alt=""
                              className="img-fluid"
                              style={{
                                height: "200px",
                                width: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-center text-white">
                        <h6 className="profile-details bg-secondary">
                          <div className="row text-center">
                            <div className="col-lg-1">
                              <i class="fa-solid fa-user "></i>
                            </div>
                            <div className="col-lg-10 text-center">
                              <b>{addData.name || ""}</b>
                            </div>
                          </div>
                        </h6>
                        <h6 className="profile-details bg-secondary">
                          <div className="row text-center">
                            <div className="col-lg-1">
                              <i class="fa-solid fa-phone"></i>
                            </div>
                            <div className="col-lg-10 text-center">
                              <b>{addData.mobile}</b>
                            </div>
                          </div>
                        </h6>
                        <h6 className="profile-details bg-secondary">
                          <div className="row text-center">
                            <div className="col-lg-1">
                              <i class="fa-solid fa-id-card"></i>
                            </div>
                            <div className="col-lg-10 text-center">
                              <b>{addData.empid || ""}</b>
                            </div>
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="card cardborder">
                      <div className="lightblued p-2">
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Branch </strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.branch_id || ""}
                          </div>
                        </div>

                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Father Name </strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.fathername || ""}
                          </div>
                        </div>
                        <div className="row mt-1 p-1">
                          <div className="col-lg-5">
                            <strong>Qualification</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.qualification || ""}
                          </div>
                        </div>

                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Email</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.email}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Date of Birth</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.dateofbirth || ""}
                          </div>
                        </div>
                        <div className="row mt-1 p-1">
                          <div className="col-lg-5">
                            <strong>Address</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.address}
                          </div>
                        </div>

                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Martial Status </strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.marital_status}
                          </div>
                        </div>

                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Timings</strong>
                          </div>
                          <div className="col-lg-7 text-secondary">
                            {addData.timings}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Notes </strong>
                          </div>
                          <div className="col-lg-7 text-secondary">
                            {addData.notes}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card carddetails p-1">
                      <div className="row p-1">
                        <div className="col-lg-12 text-danger">
                          <b>Documents</b>
                        </div>
                      </div>
                    </div>
                    <div className="card carddetails details-data_overall p-1">
                      <div>
                        <img
                          // src={`${apiUrl}/uploads/${addData[0]?.documents}` || ""}
                          //   src={imageFile}
                          alt=""
                          className="img-fluid"
                          style={{
                            height: "200px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
                      <div className="card cardborder ">
                        {/* <b className="text-danger p-1">Topics Covered</b> */}
                      </div>
                      <div className="card cardborder p-1 Completed-profile ">
                        <div className="row details-datas ">
                          <div className="col-lg-6 mt-1">
                            <b> Status</b>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.status}
                          </div>
                        </div>
                        <div className="row mt-2 details-datas">
                          <div className="col-lg-6 mt-1">
                            <b>Interview Date</b>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.interview_date}
                          </div>
                        </div>
                        <div className="row mt-2 details-datas">
                          <div className="col-lg-6 mt-1">
                            <b>Joining Date</b>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.join_date}
                          </div>
                        </div>

                        <div className="row mt-2 details-datas">
                          <div className="col-lg-6 mt-1">
                            <b>Relieve Date</b>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.relive_date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="agree"
                    checked={isCheckBox}
                    required
                    onClick={handleCheckboxChange}
                  />
                  <label htmlFor="agree " className="mx-2">
                    I Agree to all conditions
                  </label>
                </div>

                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="success"
                  disabled={!isCheckBox}
                  //   onClick={handlesubmit}
                >
                  Submit
                </Button>
                <Button onClick={handleBack}>Tick Tok</Button>
              </div>
            </div>
          )}

          {/* 
          
          
          

           <div>
                    <input type="checkbox" id="agree" required />
                    <label htmlFor="agree">I Agree to all conditions</label>
                  </div>

                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit} variant="success">
                    Submit
                  </Button>
                  <Button onClick={handleBack}>Tick Tok</Button>
          
          
          
          
          */}
        </div>
      </div>
    </>
  );
}

export default AddStaff;
