import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../Environment/ApiUrl';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
// import { useTable, useFilters, useSortBy, usePagination } from 'react-table';
import Select from 'react-select';
import styled from 'styled-components';
// import { FaSearch } from 'react-icons/fa';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { createPortal } from 'react-dom';
// ////////////////

// Table Styles
const TableContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;

  // Apply striped rows styling
  tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray for even rows */
  }

  tr:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
  }
`;

const Th = styled.th`
  padding: 10px;
  background-color: #f2f2f2;
  text-align: left;
  cursor: pointer;
  position: relative;

`;

const Td = styled.td`
  padding: 10px;
  // border: 1px solid #ddd;
`;

// Striped Row Styling
const StripedRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9; /* Light gray for even rows */
  }
  &:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
  }
`;

const Pagination = styled.div`
  padding: 10px;
  text-align: center;
`;




// Global filter component
function GlobalFilter({ globalFilter, setGlobalFilter }) {
    return (
        <div style={{ marginBottom: "10px" }}>
            <input
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Search all columns..."
                style={{
                    padding: "5px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    width: "100%",
                }}
            />
        </div>
    );
}
// Default Column Filter (Icon-based)
const DefaultColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id }
}) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const options = useMemo(() => {
        const uniqueValues = Array.from(
            new Set(preFilteredRows.map(row => row.values[id]))
        ).map(value => ({ label: value, value }));
        return [{ label: 'All', value: '' }, ...uniqueValues];
    }, [id, preFilteredRows]);

    // Handle icon click
    const handleIconClick = () => {
        setIsActive(!isActive);
    };
    return (
        <div style={{ alignItems: 'center' }}>
            <div>
                <i
                    className="fa-solid fa-ellipsis-vertical"
                    onClick={handleIconClick}
                    style={{
                        cursor: 'pointer',
                        fontSize: '20px',
                        color: '#AC0000',
                    }}
                ></i>
            </div>
            <div>
                {isActive &&
                    createPortal(
                        <div
                            style={{
                                position: 'fixed',
                                zIndex: 9999,
                                left: '50%',
                                top: '50%',
                                width: '30%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    background: 'white',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                }}
                            >
                                <Select
                                    options={options}
                                    value={options.find((option) => option.value === filterValue)} // Use filterValue to maintain selected option
                                    onChange={(selectedOption) => {
                                        setFilter(selectedOption ? selectedOption.value : '');
                                        setSelectedValue(selectedOption ? selectedOption.label : null); // Update the selected value
                                        setIsActive(false); // Close the popup after selection
                                    }}
                                    isClearable
                                    isSearchable
                                    placeholder={`Filter ${id}`}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                                <button
                                    style={{
                                        marginTop: '10px',
                                        marginLeft: '150px',
                                        padding: '5px 10px',
                                        background: '#007bff',
                                        background: '#9B0000',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setIsActive(false)} // Close the popup
                                >
                                    Close
                                </button>
                            </div>
                        </div>,
                        document.body
                    )}
            </div>
        </div>
    );

};


function Category() {

    const [responseData, setResponseData] = useState([]);
    const [previllages, setprevillages] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [role_id, setroleid] = useState('');
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    // const data = useMemo(() => {
    //     return responseData.map(item => ({
    //         _id: item._id,
    //         category_name: item.category_name,
    //         prefix: item.prefix,
    //     }));
    // }, [responseData]);

    // Define the table columns
    const data = useMemo(() => {
        return responseData.map((item) => ({
            _id: item._id,
            category_name: item.category_name,
            prefix: item.prefix,
        }));
    }, [responseData]);

    // Define table columns
    const columns = useMemo(
        () => [
            {
                Header: "Category Name",
                accessor: "category_name",
                Filter: DefaultColumnFilter,
            },
            {
                Header: "Prefix",
                accessor: "prefix",
                Filter: DefaultColumnFilter,
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        setPageSize,
        page,
        canNextPage,
        canPreviousPage,
        nextPage,
        previousPage,
        pageOptions,
        gotoPage,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 5 },
            defaultColumn: { Filter: DefaultColumnFilter },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const [addData, setAddData] = useState({
        category_name: '',
        prefix: ''
    });

    const [updateData, setUpdateData] = useState({
        category_name: '',
        prefix: ''
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData();
        gettoken();

    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/category_list");
            console.log("Response from server:", response.data);
            setResponseData(response.data.reverse());
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };
    const gettoken = async () => {
        const token = localStorage.getItem('token');
        // console.log("the toekn in the localStorage is : ",token);
        try {
            const response = await axios.post(apiUrl + "/get_detail", { "token": token });
            console.log("token detail from server:", response.data);
            // setResponseData(response.data);

            console.log("The role id is : ", response.data.detail.rolename);
            setroleid(response.data.detail.rolename);
            console.log("the role_id is : ", role_id);

            localStorage.setItem('role_id', response.data.detail.rolename);

            const id = response.data.detail.rolename

            // getpages();

            if (id) {
                try {
                    const response = await axios.get(apiUrl + `/role_2/${id}`);

                    console.log("the reponse list in brach is : ", response.data.modules);

                    const modules = response.data.modules;

                    const filtered_access = modules.filter(item => item.name == "Branch")

                    console.log("the modules is : ", filtered_access[0].actions);

                    setprevillages(filtered_access[0].actions);

                    console.log("the assigned previllages is : ", previllages.add);

                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            }

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    }
    const handleNewButtonClick = () => {
        // if (previllages.add || previllages.all) {
            setShowModal(true);
        // } else {
        //     toast.error("You don't have the permission");
        // }

    };
    const handlesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                category_name: addData.category_name,
                prefix: addData.prefix,
            }
            const response = await axios.post(apiUrl + "/category_list", formData);
            console.log("Updated:", response);
            setShowModal(false);
            toast.success("added successfully!");
            setAddData({
                name: ''
            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };
    const [updateid, setUpdateId] = useState("");


    const handleUpdate = (_id, data) => {
        // if (previllages.edit || previllages.all) {
            setUpdateId(data._id)
            console.log(" the data to updata", data)
            setUpdateShowModal(true);
            setUpdateData({
                category_name: data.category_name,
                prefix: data.prefix,

            })
        // } else {
        //     toast.error("You don't have the permission");
        // }
    }
    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {

                category_name: updateData.category_name,
                prefix: updateData.prefix,

            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/category_list/${updateid}`, formData)
            console.log("response for update", response)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();

        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }
    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(apiUrl + `/category_list/${getdeleteid}`)
            console.log("delete data ", response)
            toast.error("Delete successfully!");
            setDeleteShowModals(false);
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }
    };
    const [deleteShowModals, setDeleteShowModals] = useState(false);
    const handleCloseModal = () => {
        setDeleteShowModals(false);
    }
    const [getdeleteid, setdeleteid] = useState('')
    const [getdeletename, setdeletename] = useState('')

    const handleOpenModal = (_id, category_name) => {

        // if (previllages.all || previllages.delete) {
            console.log(" the id", _id)
            console.log(" the name", category_name)
            setdeleteid(_id);
            setdeletename(category_name);
            setDeleteShowModals(true);
        // } else {
        //     toast.error("You don't have the permission");
        // }
    }
    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const filtered = rows.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        // setFilter('category_name', e.target.value);
        // setFilter('prefix', e.target.value);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    return (
        <div className='top'>
            <div className="" style={{ marginTop: '-80px' }}>
                <div className="row mb-3">
                    <div className="col-lg-11">
                        <Link to="/dashboard" className="text-secondary dashBoard_text">
                            <b>Dashboard / </b>
                        </Link>
                        <Link to="/course" className="text-secondary dashBoard_text">
                            <b>Course / </b>
                        </Link>
                        <Link to="" className="text-danger dashBoard_text">
                            <b>Category</b>
                        </Link>
                        {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
                    </div>
                </div>
            </div>
            <div className='d-flex'>
                <h5 className='mt-2'>Category</h5>
                <div className='mx-3'><button className='btn addBtn  add' onClick={handleNewButtonClick}>Add</button></div>
            </div>
            <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                        <div>
                            <select
                                className="form-control w-100 mx-2"
                                value={state.pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                                {[5, 10, 20].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">

                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">

                                    <input
                                        className="form-control w-full bg-white appearance-none pl-8 dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={state.globalFilter || ''}  // Use the globalFilter value
                                        onChange={(e) => setGlobalFilter(e.target.value)}  // Update global filter
                                    />


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <TableContainer>
                {/* Global Search */}
                {/* <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} /> */}

                {/* Table */}
                <Table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <div className='d-flex'>
                                            <div >{column.render("Header")}</div>
                                            <div className='px-3'>{column.canFilter ? column.render("Filter") : null}</div>
                                            <div > {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</div>
                                        </div>
                                    </Th>
                                ))}
                                <Th className='text-center'>Action</Th>
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                    ))}

                                    <Td className='text-center'>
                                        {/* Use row.original to get the entire row data */}
                                        <button
                                            className='btn updelete mx-2'
                                            onClick={() => handleUpdate(row.original._id, row.original)}
                                        >
                                            <i className="fa-solid fa-pen-to-square"></i>
                                        </button>
                                        <button
                                            className='btn delete'
                                            onClick={() => handleOpenModal(row.original._id, row.original.category_name)}
                                        >
                                            <i className="fa-solid fa-trash-can"></i>
                                        </button>
                                    </Td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                {/* Pagination */}
                {/* <Pagination>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </button>
        <span>
          Page {state.pageIndex + 1} of {pageOptions.length}
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </button>
        <select
          value={state.pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 20].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </Pagination> */}
            </TableContainer>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Category </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="floating-label">
                            <input
                                className="form-control"
                                type="text"
                                placeholder=''
                                value={addData.category_name}
                                onChange={(e) => setAddData({ ...addData, category_name: e.target.value })}
                                required
                            />
                            <label className={addData.category_name ? "filled" : ""}>
                                Category Name
                            </label>
                        </div>
                        <div className="floating-label">
                            <input
                                className="form-control"
                                type="text"
                                placeholder=''
                                value={addData.prefix}
                                onChange={(e) => setAddData({ ...addData, prefix: e.target.value })}
                                required
                            />
                            <label className={addData.prefix ? "filled" : ""}>
                                prefix
                            </label>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Other input fields */}
                    <div className="form-group">

                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.category_name} onChange={(e) => setUpdateData({ ...updateData, category_name: e.target.value })} required />
                            <label className={updateData.category_name ? "filled" : ""}>
                                Category_name
                            </label>
                        </div>

                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.prefix} onChange={(e) => setUpdateData({ ...updateData, prefix: e.target.value })} required />
                            <label className={updateData.prefix ? "filled" : ""}>
                                Prefix
                            </label>
                        </div>
                    </div>
                    {/* -------------------delete -------------------- */}
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>
            <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to Delete?</p>
                </Modal.Header>
                <Modal.Body>
                    <label className="form-label d-flex">
                        <h5 className='mx-1'>branch Name :</h5> <h5>{getdeletename} </h5>
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>
            {/* _------------------------------pagination button -------------------------------- */}
            <div className="mt-3 pb-5">
                <div className="row">
                    {/* Pagination controls */}
                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                {/* Previous button */}
                                <li
                                    className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    >
                                        Previous
                                    </button>
                                </li>

                                {/* Page numbers */}
                                {pageOptions.map((_, index) => (
                                    <li
                                        key={index}
                                        className={`mx-2 page-item ${state.pageIndex === index ? "active" : ""}`}
                                    >
                                        <button
                                            className="page-link"
                                            onClick={() => gotoPage(index)}
                                        >
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}

                                {/* Next button */}
                                <li
                                    className={`page-item ${!canNextPage ? "disabled" : ""}`}
                                >
                                    <button
                                        className="page-link"
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    {/* Page info */}
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark">
                                Page {state.pageIndex + 1} of {pageOptions.length}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Category
