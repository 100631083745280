import React, { useState, useEffect } from "react";
import { FaTh, FaBars } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Navbar from "../Navbar";
import "../../assets/css/Sidebar.css";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(false); // Close sidebar on route change
  }, [location]);

  const toggle = () => setIsOpen(!isOpen);

  const handleDropdownToggle = (index) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaTh className={location.pathname === "/" ? "text-white" : ""} />,
    },
    {
      path: "/role",
      name: "Roles",
      icon: (
        <i
          className={`fa-solid fa-layer-group ${
            location.pathname === "/role" ? "text-white" : ""
          }`}
        />
      ),
    },
    {
      path: "/user",
      name: "Users",
      icon: (
        <i
          className={`fa-solid fa-user-tie ${
            location.pathname === "/user" ? "text-white" : ""
          }`}
        />
      ),
    },
    {
      path: "/branch",
      name: "Branch",
      icon: (
        <i
          className={`fa-solid fa-sitemap ${
            location.pathname === "/branch" ? "text-white" : ""
          }`}
        />
      ),
    },
    {
      path: "/student",
      name: "Leads",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        {
          path: "/contactleads",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Contact Leads",
        },
        { path: "/enquiry",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap" ></i>
          ),
           name: "Enquiry" },
        {
          path: "/newenquiry",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "New Enquiry",
        },
       
        { path: "/followsups",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Followups" },
        { path: "/funnel",
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
           name: "Funnel" },
        { path: "/Walkin", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Direct Visit" },
        { path: "/sourcetype", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Sourcetype" },
        { path: "/enquirypurpose", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Enquirypurpose" },
      ],
    },
    {
      path: "/#",
      name: "Projects",
      icon: <i class="fa-solid fa-list-check"></i>,
      subItems: [
        { path: "/upcomings", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Expected" },
        { path: "/ongoing", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Ongoing" },
        { path: "/completed", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Completed" },
      ],
    },
    {
      path: "/#",
      name: "Sales",
      icon: <i class="fa-solid fa-sack-dollar"></i>,
      subItems: [
        { path: "/salesorder", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Sales Order" },
        { path: "/customer", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Customer" },
      ],
    },
    {
      path: "/#",
      name: "Services",
      icon: <i class="fa-solid fa-gear"></i>,
      subItems: [
        { path: "/upcomingservices", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Upcomings" },
        { path: "/ongoingservices", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Ongoing" },
        { path: "/completeservices", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Completed" },
      ],
    },

    {
      path: "/#",
      name: "Staff",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        { path: "/staff", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Staff" },
        { path: "/attendance", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Attendance" },
        { path: "/designation", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Designation" },
        { path: "/leave", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Leave" },
        { path: "/travelallowance", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Travel Allowance" },
        { path: "/salarydetails", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Salary Details" },
        { path: "/holiday", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Holiday" },
          { path: "/event", 
            icon: (
              <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
            ),
            name: "Event" },
          { path: "/dailytask", 
            icon: (
              <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
            ),
            name: "Daily Task" },
      ],
    },

    {
      path: "/stocks",
      name: "Stocks",
      icon: (
        <i
          className={`fa-solid fa-box ${
            location.pathname === "/stocks" ? "text-white" : ""
          }`}
        />
      ),
    },

    {
      path: "/#",
      name: "Products",
      icon: <i className="fa-solid fa-right-from-bracket"></i>,
      subItems: [
        { path: "/products", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Product List" },
        { path: "/category", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Category" },
      ],
    },
    {
      path: "/#",
      name: "Purchase",
      icon: <i class="fa-solid fa-cart-shopping"></i>,
      subItems: [
        { path: "/purchaseorder", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Purchase Order" },
        { path: "/supplier", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Supplier" },
      ],
    },
    {
      path: "/assetlist",
      name: "Asset List",
      icon: (
        <i
          className={`fa-regular fa-compass ${
            location.pathname === "/assetlist" ? "text-white" : ""
          }`}
        />
      ),
    },
    {
      path: "/#",
      name: "Expense",
      icon: <i class="fa-solid fa-bars"></i>,
      subItems: [
        { path: "/expenselist", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Expense List" },

        { path: "/vendordetails", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Vendor Details" },
          
        { path: "/officeexpense", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Office Expense" },
        { path: "/paytype", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Paytype" },
      ],
    },
    {
      path: "/incomelist",
      name: "Income List",
      icon: (
        <i
          className={`fa-solid fa-wallet ${
            location.pathname === "/incomelist" ? "text-white" : ""
          }`}
        />
      ),
    },

    {
      path: "/invoice",
      name: "Invoice",
      icon: (
        <i
          className={`fa-solid fa-file-invoice ${
            location.pathname === "/invoice" ? "text-white" : ""
          }`}
        />
      ),
    },

    {
      path: "/#",
      name: "Report",
      icon: <i class="fa fa-file"></i>,
      subItems: [
        { path: "/dailyreport", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Daily Report" },
        { path: "/enquriyreport", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Enquiry Report" },
        { path: "/attendancereport", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Attendance Report" },
        { path: "/expensereport", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Expense Report" },
        { path: "/projectreport", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Project Report" },
        { path: "/staffreport", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Staff Report" },
        { path: "/incomereport", 
          icon: (
            <i id="icons" className="fa-sharp fa-solid fa-window-minimize IconGap"></i>
          ),
          name: "Income Report" },
      ],
    },
    {
      path: "/logout",
      name: "Logout",
      icon: (
        <i
          className={`fa-solid fa-right-from-bracket ${
            location.pathname === "/logout" ? "text-white" : ""
          }`}
        />
      ),
    },
  ];

  if (
    ["/register", "*"].includes(location.pathname) ||
    location.pathname.startsWith("/invoice/invoiceprint/") ||
    location.pathname.startsWith("/invoice/bill/")
  ) {
    return (
      <main style={{ padding: "20px", marginTop: "0px" }}>{children}</main>
    );
  }

  return (
    <>
      <Navbar toggle={isOpen} />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <p>Are you sure you want to logout?</p>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary">Logout</Button>
        </Modal.Footer>
      </Modal>

      <div className="container-main">
        <div
          className="sidebar"
          style={{
            position: "fixed",
            top: "70px",
            bottom: 0,
            left: 0,
            width: isOpen ? "44px" : "250px",
            zIndex: 1000,
          }}
        >
          <div className="top_section">
            <p
              style={{ display: isOpen ? "none" : "block" }}
              className={`logo mx-4 ${isOpen ? "" : "d-flex"}`}
            >
              <div className="admincont">
                {" "}
                <img
                  src={require("../../assets/img/scimitar logo slogan.png")}
                  alt="Company Logo"
                  style={{
                    width: "200px",
                    marginTop: "5px",
                    marginLeft: "-30px",
                  }}
                  onClick={handleLogoClick}
                ></img>
              </div>
            </p>
            <div
              style={{ marginLeft: isOpen ? "7px" : "70px" }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>

          {menuItem.map((item, index) => (
            <div key={index}>
              {item.subItems ? (
                <>
                  <div
                    className="link"
                    onClick={() => handleDropdownToggle(index)}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className={`link_text ${isOpen ? "d-none" : ""}`}>
                      {item.name}
                    </div>
                    <div className="dropdown_icon">
                      {dropdownStates[index] ? (
                        <i className="fa-solid fa-chevron-up"></i>
                      ) : (
                        <i className="fa-solid fa-chevron-down"></i>
                      )}
                    </div>
                  </div>
                  {dropdownStates[index] && (
                    <div className="subMenu">
                      {item.subItems.map((subItem, subIndex) => (
                        <NavLink
                          key={subIndex}
                          to={subItem.path}
                          className="link submenu"
                          activeClassName="active"
                        >
                          <div className="icon">{subItem.icon}</div>
                          <div
                            className={`link_text ${isOpen ? "d-none" : ""}`}
                          >
                            {subItem.name}
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <NavLink
                  to={item.path}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{item.icon}</div>
                  <div className={`link_text ${isOpen ? "d-none" : ""}`}>
                    {item.name}
                  </div>
                </NavLink>
              )}
            </div>
          ))}
        </div>
        <main className={`main ${isOpen ? "isClose" : "isOpen"}`}>
          {children}
        </main>
      </div>
    </>
  );
};

export default Sidebar;
