import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";

function SalaryDetails() {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);
  return (
    <>
      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/staff" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Salary</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-9 d-flex">
          <h5 className="mt-2">Salary Details</h5>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show: </label>
            <select
              className="form-control w-100 mx-2"
             
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <form>
              <input
                className="form-control w-full bg-white pl-8"
                placeholder="Search ..."
                type="search"
               
              />
            </form>
          </div>
        </div>
      </div>

      <div className="card">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Staff ID</th>

              <th>Staff Name</th>
              <th>Designation</th>
              <th>Month & Year</th>
              <th>Actual Salary</th>
              <th>Paid Salary</th>
              <th>Status</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
          
            
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Salary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    
                    required
                  />
                  <label className="">Staff ID</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    
                    required
                  />
                  <label className="">Staff Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    
                    required
                  />
                  <label className="">Designation</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    
                    required
                  />
                  <label className="">Month & Year</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    
                    required
                  />
                  <label className="">Salary Per Day</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    
                    required
                  />
                  <label className="">Actual Salary</label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  
                  required
                />
                <label className="">Paid Salary</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                 
                  required
                />
                <label className="">Paid Date</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                 
                  required
                />
                <label className="">Detection</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  
                  required
                />
                <label className="">Present Day</label>
              </div>
              <div className="floating-label">
                {" "}
                <label className="form-label"></label>{" "}
                <select
                  className="form-control"
                 
                >
                  <option value="">Select Status</option>{" "}
                  <option value="Approved">Paid</option>{" "}
                  <option value="Pending">Pending</option>{" "}
                  <option value="Rejected">Process</option>{" "}
                </select>{" "}
              </div>

          
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                   
                    required
                  />
                  <label className="">Reason</label>
                </div>
           
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" >
            Close
          </Button>
          <Button variant="primary" >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li className="">
                  <button className="page-link" o>
                    Previous
                  </button>
                </li>

                <li className="">
                  <button className="page-link ">1</button>
                </li>

                <li className="">
                  <button className="page-link ">Next</button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">Page 1 of 1</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalaryDetails;
