// Login.js
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Login.css";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://bk.skimitarautomation.com:8001/api/auth/login",
        {
          username,
          password,
        }
      );

      // Store the token in localStorage after successful login
      localStorage.setItem("token", response.data.token);
      setErrorMessage("");
      // Redirect to the dashboard
      navigate("/dashboard");
    } catch (err) {
      setError("Invalid credentials");
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="row over fullimg">
        <div className="col-lg-12 col-md-12 col-sm-12 login_container">
          <div className="card formcard ">
            <div className="icon_container">
              <h3 className="text-center mt-3">
                {/* <img src={require("../../assets/img/scimitar_logo_slogan-removebg-preview.png")} alt="Company Logo" style={{ height: "100px" }}></img> */}
                <img
                  src={require("../../assets/img/scimitar logo slogan.png")}
                  alt="Company Logo"
                  style={{ height: "100px" }}
                ></img>
              </h3>
            </div>
            <form className="m-4" onSubmit={handleLogin}>
              <div className="mb-2">
                <label className="form-label login_txt">User Name</label>
                <div className="input-group">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Username"
                  className="form-control"
                  required
                />
                
                </div>
                
              </div>
              <div className="mb-3">
                <label className="form-label login_txt">Password</label>
                <div className="input-group">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="form-control"
                    required
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <i className="fa-regular fa-eye-slash"></i>
                    ) : (
                      <i className="fa-regular fa-eye"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="mb-2 mt-2 text-center">
                <button className="btn btn-dark sub_btn" type="submit">
                  Login
                </button>
              </div>
              {errorMessage && (
                <p className="text-danger text-center mt-4">{errorMessage}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
