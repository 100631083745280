// import { useState } from "react";
// import { Modal, Button } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";

// function AssetType() {
//   const [showModal, setShowModal] = useState(false);

//   return (
//     <>
//       <div className="top">
//         <div className="" style={{ marginTop: "-80px" }}>
//           <div className="row mb-3">
//             <div className="col-lg-11">
//               <Link to="/dashboard" className="text-secondary dashBoard_text">
//                 <b>Dashboard / </b>
//               </Link>
//               <Link to="" className="text-danger dashBoard_text">
//                 <b>Asset Type</b>
//               </Link>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-10 d-flex">
//             <h5 className="mt-2">Asset Type</h5>
//             <div className="mx-3">
//               <button
//                 className="btn btn-success add mx-3"
//                 onClick={() => setShowModal(true)}
//               >
//                 Add
//               </button>
//             </div>
//           </div>
//           <div className="col-lg-2">
//             {/* <button
//                 className="btn btn-secondary text-white"
//                 onClick={handleAssetType}
//               >
//                 Asset Type
//               </button> */}
//           </div>
//         </div>

//         {/* ------------ search and per page ----------------------------*/}

//         <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className="form-label text-dark mt-2">Show : </label>
//               <div>
//                 <select className="form-control w-100 mx-2">
//                   {/* <option value="5">5</option> */}
//                   <option value="10">10</option>
//                   <option value="20">20</option>
//                   <option value="50">50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">
//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                       placeholder="Search ..."
//                       type="search"
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* --------------------------------end------------------------------------------- */}

//         {/* Table */}
//         <div className="card">
//           <div className="mt-1">
//             <table className="table table-striped">
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   <th>Name</th>
//                   <th>Course</th>
//                   <th>Source Type</th>
//                   <th>Enquiry Date</th>
//                   <th>Notes</th>
//                   <th>Status</th>
//                   <th className="text-center">Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>1</td>
//                   <td>Siva</td>
//                   <td>Javascript</td>
//                   <td>Facebook</td>
//                   <td>01/12/2024</td>
//                   <td></td>

//                   <td>
//                     <button className="btn bg-success text-white">
//                       Qualified
//                     </button>
//                   </td>
//                   <td>
//                     <button className="btn updelete ">
//                       <i className="fa-solid fa-pen-to-square"></i>
//                     </button>

//                     <button className="btn delete mx-1">
//                       <i className="fa-solid fa-trash-can"></i>
//                     </button>

//                     <button className="btn btn-transpront">
//                       <i class="fa-solid fa-circle-info"></i>
//                     </button>
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

//         <div className="insermodal">
//           <Modal
//             className="addmodals"
//             show={showModal}
//             onHide={() => setShowModal(false)}
//           >
//             <Modal.Header closeButton>
//               <Modal.Title>Asset Type</Modal.Title>
//             </Modal.Header>

//             <form>
//               <Modal.Body>
//                 <div className="row">
//                   <div className="col-lg-6 ">
//                     <div className="floating-label ">
//                       <label className="form-label">
//                         <b></b>
//                       </label>
//                       <select className="form-select" placeholder="" required>
//                         <option value="">Select Branch </option>

//                         <option key="" value="">
//                           1
//                         </option>
//                       </select>
//                       <label className=""></label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder="Student Name"
//                         required
//                       />
//                     </div>

//                     <div className="floating-label mt-4">
//                       <input
//                         className="form-control"
//                         type="tel"
//                         placeholder="Mobile No"
//                         required
//                       />
//                     </div>

//                     <div className="floating-label mt-4">
//                       <input
//                         className="form-control"
//                         type="email"
//                         placeholder="Email"
//                         required
//                       />
//                     </div>

//                     <div className="floating-label">
//                       <label className="form-label"></label>
//                       <select className="form-select" placeholder="" required>
//                         <option value="">Select Source Type </option>

//                         <option key="" value="">
//                           1
//                         </option>
//                       </select>
//                     </div>
//                   </div>

//                   <div className="col-lg-6 ">
//                     <div className="floating-label">
//                       <label className="form-label"></label>
//                       <select className="form-select" required>
//                         <option value="">Select Course Category</option>

//                         <option>1</option>
//                       </select>
//                     </div>

//                     <div className="floating-label  mt-4">
//                       <select className="form-select" required>
//                         <option value="">Prefered Course</option>
//                       </select>
//                       <label className=""></label>
//                     </div>

//                     <div className="floating-label">
//                       <label className="">Enquiry Date</label>
//                       <input
//                         className="form-control"
//                         type="date"
//                         placeholder=""
//                         required
//                       />
//                     </div>

//                     <div className="floating-label mt-4">
//                       <label className="">Qualification</label>
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                     </div>

//                     <div className="floating-label mt-1">
//                       <label className="form-label">
//                         <b></b>
//                       </label>
//                       <select className="form-select" placeholder="" required>
//                         <option value="">Select Enquiry Purpose </option>
//                       </select>

//                       <label className=""></label>
//                     </div>
//                   </div>
//                 </div>
//               </Modal.Body>

//               <Modal.Footer>
//                 <Button
//                   className="cancelbut"
//                   variant="secondary"
//                   onClick={() => setShowModal(false)}
//                 >
//                   Cancel
//                 </Button>
//                 <Button className="submitbut" type="submit" variant="primary">
//                   Submit
//                 </Button>
//               </Modal.Footer>
//             </form>
//           </Modal>
//         </div>

//         {/* _------------------------------pagination button -------------------------------- */}
//         <div className="mt-3 pb-5">
//           <div className="row">
//             <div className="col-lg-10 col-sm-12">
//               <nav className="d-flex float-right">
//                 <ul className="pagination justify-content-center">
//                   <li className="">
//                     <button className="page-link">Previous</button>
//                   </li>

//                   <li className="">
//                     <button className="page-link ">1</button>
//                   </li>

//                   <li className="">
//                     <button className="page-link ">Next</button>
//                   </li>
//                 </ul>
//               </nav>{" "}
//             </div>
//             <div className="col-lg-2 col-sm-12 mt-1">
//               <div className="float-end">
//                 <span className="text-dark ">Page 1 of 1</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default AssetType;



import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
// import apiUrl from "../../environment/Api";
import apiUrl from "./../Environment/ApiUrl";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function AssetType() {
  const [responseData, setResponseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [branch, setbranch] = useState([]);

  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  const [addData, setAddData] = useState({
    name: "",
    asset_prefix: "",
  });

  const [updateData, setUpdateData] = useState({
    name: "",
    asset_prefix: "",
  });

  useEffect(() => {
    fetchData();
    fetchDataed();
    gettoken();
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  // }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/asset_type", {
        params: { data: localStorage.getItem("branch_id") },
      });
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);

      setroleid(response.data.detail.rolename);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);
          const modules = response.data.modules;
          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );

          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // const handleNewButtonClick = () => {
  //     if (previllages.add || previllages.all) {
  //     setShowModal(true);
  // }else{
  //     toast.error("You don't have the permission to add");
  // }
  // }

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    try {
      const formData = {
        // branch_id: addData.branch_id,
        name: addData.name,
        asset_prefix: addData.asset_prefix,
      };
      const response = await axios.post(apiUrl + "/asset_type", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        name: "",
        asset_prefix: ""
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    // if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log(" the data to updata", data);
      setUpdateShowModal(true);
      setUpdateData({
        name: data.name,
        asset_prefix: data.asset_prefix,
      });
    // } else {
    //   toast.error("You don't have the permission to update");
    // }
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        // branch_id: updateData.branch_id,
        name: updateData.name,
        asset_prefix: updateData.asset_prefix,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        apiUrl + `/Asset_type/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        apiUrl + `/Asset_type/${getdeleteid}`
      );
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");

  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, name) => {
    // if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", name);
      setdeleteid(_id);
      setdeletename(name);
      setDeleteShowModals(true);
    // } else {
    //   toast.error("You don't have the permission to delete");
    // }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.name) {
      tempErrors.name = "Asset Name is required.";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/assetlist" className="text-secondary dashBoard_text">
              <b>Asset List / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Asset Type</b>
            </Link>
          </div>
        </div>
      </div>
      {/* Table */}
      <div className="d-flex">
        <h5 className="mt-2">Asset type</h5>
        <div className="mx-3">
          {/* {previllages.add && ( */}
            <button className="btn addBtn add" onClick={() => setShowModal(true)}>
              Add
            </button>
          {/* )} */}
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                {/* <option value='5'>5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}
      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Branch</th> */}
                <th>Asset Type Name</th>
                <th>Asset prefix</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{item.branch_id.branch_name}</td> */}
                  <td>{item.name}</td>
                  <td>{item.asset_prefix}</td>
                  <td className="text-center">
                    {/* {previllages.edit && ( */}
                      <button
                        className="btn updelete mx-2"
                        onClick={() => handleUpdate(item._id, item)}
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </button>
                    {/* )} */}
                    {/* {previllages.delete && ( */}
                      <button
                        className="btn delete"
                        onClick={() => handleOpenModal(item._id, item.name)}
                      >
                        {" "}
                        <i class="fa-solid fa-trash-can"></i>
                      </button>
                    {/* )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for adding a new user */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Asset Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="floating-label">
              <input
                className="form-control"
                placeholder=""
                type="text"
                value={addData.name}
                onChange={(e) =>
                  setAddData({ ...addData, name: e.target.value })
                }
                required
              />
              <label className={addData.name ? "filled" : ""}>
                Asset Type Name
              </label>

              {errors.name && <div className="error-text">{errors.name}</div>}
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                placeholder=""
                type="text"
                value={addData.asset_prefix}
                onChange={(e) =>
                  setAddData({ ...addData, asset_prefix: e.target.value })
                }
                required
              />
              <label className={addData.asset_prefix ? "filled" : ""}>
                Asset Prefix
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Asset Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Other input fields */}

          <div className="form-group">
            {/* <div className="floating-label">
                            <input className="form-control" placeholder='' type="text" value={updateData.branch_id.branch_name} onChange={(e) => setUpdateData({ ...updateData, branch_id: e.target.value })} required />
                            <label className={updateData.branch_id.branch_name ? "filled" : ""}>
                                Branch
                            </label>
                        </div> */}

            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={updateData.name}
                onChange={(e) =>
                  setUpdateData({ ...updateData, name: e.target.value })
                }
                required
              />
              <label className={updateData.name ? "filled" : ""}>
                Asset Type Name
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={updateData.asset_prefix}
                onChange={(e) =>
                  setUpdateData({ ...updateData, asset_prefix: e.target.value })
                }
                required
              />
              <label className={updateData.asset_prefix ? "filled" : ""}>
                Asset Prefix
              </label>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Asset Type Name :</h5>{" "}
            <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* _------------------------------pagination button -------------------------------- */}

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </div>
  );
}

export default AssetType;
