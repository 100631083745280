// import { useState } from "react";
// import { Modal, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";

// function PayType() {
//   const [showModal, setShowModal] = useState(false);
//   return (
//     <>
//       <div className="top">
//         <div className="" style={{ marginTop: "-80px" }}>
//           <div className="row mb-3">
//             <div className="col-lg-11">
//               <Link to="/dashboard" className="text-secondary dashBoard_text">
//                 <b>Dashboard / </b>
//               </Link>
//               <Link to="" className="text-danger dashBoard_text">
//                 <b>PayType</b>
//               </Link>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-10 d-flex">
//             <h5 className="mt-2">PayType</h5>
//             <div className="mx-3">
//               <button
//                 className="btn btn-success add mx-3"
//                 onClick={() => setShowModal(true)}
//               >
//                 Add
//               </button>
//             </div>
//           </div>
//           <div className="col-lg-2">
//             <div>
//               <input
//                 type="file"
//                 id="file-upload"
//                 accept=".xls,.xlsx"
//                 style={{ display: "none" }}
//               />
//               <label htmlFor="file-upload" className="btn btn-secondary">
//                 File Upload
//               </label>
//             </div>
//           </div>
//         </div>

//         {/* ------------ search and per page ----------------------------*/}

//         <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className="form-label text-dark mt-2">Show : </label>
//               <div>
//                 <select className="form-control w-100 mx-2">
//                   {/* <option value="5">5</option> */}
//                   <option value="10">10</option>
//                   <option value="20">20</option>
//                   <option value="50">50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">
//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                       placeholder="Search ..."
//                       type="search"
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* --------------------------------end------------------------------------------- */}

//         {/* Table */}
//         <div className="card">
//           <div className="mt-1">
//             <table className="table table-striped">
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   <th>Name</th>
//                   <th>Course</th>
//                   <th>Source Type</th>
//                   <th>Enquiry Date</th>
//                   <th>Notes</th>
//                   <th>Status</th>
//                   <th className="text-center">Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>1</td>
//                   <td>Siva</td>
//                   <td>Javascript</td>
//                   <td>Facebook</td>
//                   <td>01/12/2024</td>
//                   <td></td>

//                   <td>
//                     <button className="btn bg-success text-white">
//                       Qualified
//                     </button>
//                   </td>
//                   <td>
//                     <button className="btn updelete ">
//                       <i className="fa-solid fa-pen-to-square"></i>
//                     </button>

//                     <button className="btn delete mx-1">
//                       <i className="fa-solid fa-trash-can"></i>
//                     </button>

//                     <button className="btn btn-transpront">
//                       <i class="fa-solid fa-circle-info"></i>
//                     </button>
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

//         <div className="insermodal">
//           <Modal
//             className="addmodals"
//             show={showModal}
//             onHide={() => setShowModal(false)}
//           >
//             <Modal.Header closeButton>
//               <Modal.Title>PayType</Modal.Title>
//             </Modal.Header>

//             <form>
//               <Modal.Body>
//                 <div className="row">
//                   <div className="col-lg-6 ">
//                     <div className="floating-label ">
//                       <label className="form-label">
//                         <b></b>
//                       </label>
//                       <select className="form-select" placeholder="" required>
//                         <option value="">Select Branch </option>

//                         <option key="" value="">
//                           1
//                         </option>
//                       </select>
//                       <label className=""></label>
//                     </div>

//                     <div className="floating-label">
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder="Student Name"
//                         required
//                       />
//                     </div>

//                     <div className="floating-label mt-4">
//                       <input
//                         className="form-control"
//                         type="tel"
//                         placeholder="Mobile No"
//                         required
//                       />
//                     </div>

//                     <div className="floating-label mt-4">
//                       <input
//                         className="form-control"
//                         type="email"
//                         placeholder="Email"
//                         required
//                       />
//                     </div>

//                     <div className="floating-label">
//                       <label className="form-label"></label>
//                       <select className="form-select" placeholder="" required>
//                         <option value="">Select Source Type </option>

//                         <option key="" value="">
//                           1
//                         </option>
//                       </select>
//                     </div>
//                   </div>

//                   <div className="col-lg-6 ">
//                     <div className="floating-label">
//                       <label className="form-label"></label>
//                       <select className="form-select" required>
//                         <option value="">Select Course Category</option>

//                         <option>1</option>
//                       </select>
//                     </div>

//                     <div className="floating-label  mt-4">
//                       <select className="form-select" required>
//                         <option value="">Prefered Course</option>
//                       </select>
//                       <label className=""></label>
//                     </div>

//                     <div className="floating-label">
//                       <label className="">Enquiry Date</label>
//                       <input
//                         className="form-control"
//                         type="date"
//                         placeholder=""
//                         required
//                       />
//                     </div>

//                     <div className="floating-label mt-4">
//                       <label className="">Qualification</label>
//                       <input
//                         className="form-control"
//                         type="text"
//                         placeholder=""
//                         required
//                       />
//                     </div>

//                     <div className="floating-label mt-1">
//                       <label className="form-label">
//                         <b></b>
//                       </label>
//                       <select className="form-select" placeholder="" required>
//                         <option value="">Select Enquiry Purpose </option>
//                       </select>

//                       <label className=""></label>
//                     </div>
//                   </div>
//                 </div>
//               </Modal.Body>

//               <Modal.Footer>
//                 <Button
//                   className="cancelbut"
//                   variant="secondary"
//                   onClick={() => setShowModal(false)}
//                 >
//                   Cancel
//                 </Button>
//                 <Button className="submitbut" type="submit" variant="primary">
//                   Submit
//                 </Button>
//               </Modal.Footer>
//             </form>
//           </Modal>
//         </div>

//         {/* _------------------------------pagination button -------------------------------- */}
//         <div className="mt-3 pb-5">
//           <div className="row">
//             <div className="col-lg-10 col-sm-12">
//               <nav className="d-flex float-right">
//                 <ul className="pagination justify-content-center">
//                   <li className="">
//                     <button className="page-link">Previous</button>
//                   </li>

//                   <li className="">
//                     <button className="page-link ">1</button>
//                   </li>

//                   <li className="">
//                     <button className="page-link ">Next</button>
//                   </li>
//                 </ul>
//               </nav>{" "}
//             </div>
//             <div className="col-lg-2 col-sm-12 mt-1">
//               <div className="float-end">
//                 <span className="text-dark ">Page 1 of 1</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default PayType;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "./../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function PayType() {
  const [responseData, setResponseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [previllages, setprevillages] = useState({});
  const [role_id, setroleid] = useState("");

  const [addData, setAddData] = useState({
    Payment_type: "",
  });

  const [updateData, setUpdateData] = useState({
    Payment_type: "",
  });

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    gettoken();
  }, []);

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    // console.log("the toekn in the localStorage is : ",token);
    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      // setResponseData(response.data);

      console.log("The role id is : ", response.data.detail.rolename);
      setroleid(response.data.detail.rolename);
      console.log("the role_id is : ", role_id);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      // getpages();

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);

          console.log("the reponse list in brach is : ", response.data.modules);

          const modules = response.data.modules;

          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );

          console.log("the modules is : ", filtered_access[0].actions);

          setprevillages(filtered_access[0].actions);

          console.log("the assigned previllages is : ", previllages.add);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/paytype");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }
    try {
      const formData = {
        Payment_type: addData.Payment_type,
      };
      const response = await axios.post(apiUrl + "/paytype", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        Payment_type: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log(" the data to updata", data);
    setUpdateShowModal(true);
    setUpdateData(data);
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        Payment_type: updateData.Payment_type,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        apiUrl + `/paytype/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(apiUrl + `/paytype/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");

  const handleOpenModal = (_id, Payment_type) => {
    console.log(" the id", _id);
    console.log(" the name", Payment_type);
    setdeleteid(_id);
    setdeletename(Payment_type);
    setDeleteShowModals(true);
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.Payment_type) {
      tempErrors.Payment_type = "Payment Type is required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Payment Type</b>
              </Link>
              {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="d-flex">
          <h5 className="mt-2">Payment Type </h5>
          <div className="mx-3">
            {/* {previllages.add && ( */}
              <button
                className="btn addBtn add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
            {/* )} */}
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                  }}
                >
                  {/* <option value='5'>5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------end------------------------------------------- */}
        <div className="card">
          <div className="mt-1">
            <table className="table table-striped ">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th> Payment Type</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>

              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.Payment_type}</td>

                    <td className="text-center">
                      {/* {previllages.edit && ( */}
                        <button
                          className="btn updelete mx-2"
                          onClick={() => handleUpdate(item._id, item)}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      {/* )} */}

                      {/* {previllages.delete && ( */}
                        <button
                          className="btn delete"
                          onClick={() =>
                            handleOpenModal(item._id, item.Payment_type)
                          }
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      {/* )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modal for adding a new user */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Payment Type </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={addData.Payment_type}
                  onChange={(e) =>
                    setAddData({ ...addData, Payment_type: e.target.value })
                  }
                  required
                />
                <label className={addData.Payment_type ? "filled" : ""}>
                  Payment type
                </label>

                {errors.Payment_type && (
                  <div className="error-text">{errors.Payment_type}</div>
                )}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handlesubmit}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isUpdateshowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Payment Type </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Other input fields */}

            <div className="form-group">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={updateData.Payment_type}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      Payment_type: e.target.value,
                    })
                  }
                  required
                />
                <label className={updateData.Payment_type ? "filled" : ""}>
                  Payment Type
                </label>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleupdatesubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ----------------delete */}

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to Delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1"> Payment Type :</h5>{" "}
              <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* _------------------------------pagination button -------------------------------- */}

        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li
                    className={` page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={goToPrevPage}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li
                      key={i}
                      className={`mx-2 page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link "
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link " onClick={goToNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>{" "}
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark ">
                  Page {currentPage} of {totalPages}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------------end ---------------------------------- */}
      </div>
    </>
  );
}

export default PayType;
