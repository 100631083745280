

import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios"; // Or fetch, depending on your preference

function Holiday() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [holidayData, setHolidayData] = useState([]);
  const [holidayForm, setHolidayForm] = useState({ Date: "", Holiday_name: "" });
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  // Fetch holiday data when component mounts
  useEffect(() => {
    fetchHolidays();
  }, []);

  // Fetch holidays data from the API
  const fetchHolidays = async () => {
    try {
      const response = await axios.get("http://localhost:8000/api/holiday");
      setHolidayData(response.data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  // Handle form field change
  const handleInputChange = (e) => {
    setHolidayForm({ ...holidayForm, [e.target.name]: e.target.value });
  };

  // Add holiday
  const handleAddHoliday = async () => {
    try {
      await axios.post("http://localhost:8000/api/holiday", holidayForm);
      fetchHolidays(); // Refresh the holiday list
      setShowAddModal(false);
    } catch (error) {
      console.error("Error adding holiday:", error);
    }
  };

  // Update holiday
  const handleUpdateHoliday = async () => {
    if (selectedHoliday) {
      console.log("Updating holiday with ID:", selectedHoliday._id); // Log the selected holiday's ID
      try {
        await axios.put(
          `http://localhost:8000/api/holiday/${selectedHoliday._id}`,
          holidayForm
        );
        fetchHolidays(); // Refresh the holiday list
        setShowUpdateModal(false);
      } catch (error) {
        console.error("Error updating holiday:", error);
      }
    }
  };

  // Delete holiday
  const handleDeleteHoliday = async () => {
    if (selectedHoliday) {
      console.log("Deleting holiday with ID:", selectedHoliday._id); // Log the selected holiday's ID
      try {
        await axios.delete(
          `http://localhost:8000/api/holiday/${selectedHoliday._id}`
        );
        fetchHolidays(); // Refresh the holiday list
        setShowDeleteModal(false);
      } catch (error) {
        console.error("Error deleting holiday:", error);
      }
    }
  };

  return (
    <>
      <div className="top">
        <div className="d-flex">
          <h5 className="mt-2">Holiday</h5>
          <div className="mx-3">
            <button
              className="btn addBtn add mx-3"
              onClick={() => setShowAddModal(true)}
            >
              Add
            </button>
          </div>
        </div>

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  // onChange={(e) => {
                  //   setItemsPerPage(e.target.value);
                  // }}
                >
                  {/* <option value='5'>5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      // value={searchQuery}
                      // onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Holiday</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {holidayData.map((holiday, index) => (
                  <tr key={holiday._id}>
                    <td>{index + 1}</td>
                    <td>{holiday.Date}</td>
                    <td>{holiday.Holiday_name}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-primary mx-1"
                        onClick={() => {
                          setSelectedHoliday(holiday);
                          setShowUpdateModal(true);
                          console.log("Edit button clicked for ID:", holiday._id); // Log the ID when clicking edit
                        }}
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btn btn-danger mx-1"
                        onClick={() => {
                          setSelectedHoliday(holiday);
                          setShowDeleteModal(true);
                          console.log("Delete button clicked for ID:", holiday._id); // Log the ID when clicking delete
                        }}
                      >
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Add Holiday Modal */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Holiday</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  name="Date"
                  value={holidayForm.Date}
                  onChange={handleInputChange}
                  required
                />
                <label>Date</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  name="Holiday_name"
                  value={holidayForm.Holiday_name}
                  onChange={handleInputChange}
                  required
                />
                <label>Holiday Name</label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddHoliday}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Update Holiday Modal */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Holiday</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  name="Date"
                  value={holidayForm.Date}
                  onChange={handleInputChange}
                  required
                />
                <label>Date</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  name="Holiday_name"
                  value={holidayForm.Holiday_name}
                  onChange={handleInputChange}
                  required
                />
                <label>Holiday Name</label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdateHoliday}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Holiday Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Holiday</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the holiday "{selectedHoliday?.Holiday_name}"?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteHoliday}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Holiday;
