import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";

function Staff() {
  const navigate = useNavigate();
  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/staff");
      setResponseData(response.data);
    } catch (error) {
      console.error("Error fetching branch data:", error.message);
    }
  };

  useEffect(() => {
    fetchData(); // Call fetchData when the component mounts
  }, []);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const filtered = (responseData || []).filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleAdd = () => {
    navigate("/addstaff");
  };

  const handleUpdate = (_id, data) => {
    navigate(`/updatestaff/${_id}`);
  };

  const handleStaffInfo = (id) => {
    navigate(`/staffinfo/${id}`);
  };

  const handleOpenModal = (id, name) => {
    setdeleteid(id);
    setdeletename(name);
    setDeleteShowModals(true);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(ApiUrl + `/staff/${getdeleteid}`);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData(); // Re-fetch data after deleting
    } catch (error) {
      console.error("Error deleting data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  return (
    <>
      <div className="top">
        <div style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Staff</b>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex">
              <h4 className="mt-2">Staff</h4>
              <button className="btn addBtn add mx-3" onClick={handleAdd}>
                Add
              </button>
            </div>
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}
        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select className="form-control w-100 mx-2">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white"
                      placeholder="Search ..."
                      type="search"
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Staff Table */}

        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Branch</th>
                  <th>Staff Id</th>
                  <th>Name</th>
                  <th>Mobile No</th>
                  <th>Designation</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.branch_id[0]?.branch_name}</td>
                    <td>{item.empid}</td>
                    <td>{item.name}</td>
                    <td>{item.mobile}</td>
                    <td></td>

                    <td className="text-center">
                      {item.status === "Active" ? (
                        <p className="text-white green-label">
                          {item.branch_id[0]?.status}
                        </p>
                      ) : (
                        <p className="text-white red-label">{item.status}</p>
                      )}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn updelete mx-2"
                        onClick={() => handleUpdate(item._id, item)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>

                      <button
                        className="btn delete"
                        onClick={() => handleOpenModal(item._id, item.empid)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>

                      <button
                        className="btn"
                        onClick={() => handleStaffInfo(item._id)}
                      >
                        <i className="fa-solid fa-circle-info"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li>
                    <button className="page-link">Previous</button>
                  </li>

                  <li>
                    <button className="page-link">1</button>
                  </li>

                  <li>
                    <button className="page-link">Next</button>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark">Page 1 of 1</span>
              </div>
            </div>
          </div>
        </div>

        {/* Delete Modal */}
        <Modal show={deleteShowModals} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete {getdeletename}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Staff;
