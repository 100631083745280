import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import ApiUrl from "../Environment/ApiUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Stock() {
  const [showModal, setShowModal] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const [addData, setAddData] = useState({
    goods_id: "",
    name: "",
    category: "",
    mrp: "",
    buying_price: "",
    sales_price: "",
    description: "",
    notes: "",
    status: "",
    buying_quantity: "",
    solding_quantity: "",
    available_quantity: "",
  });

  const handlesubmit = async (e) => {
    e.preventDefault();
    console.log("Updated", setAddData);
    try {
      const formData = {
        goods_id: addData.goods_id,
        name: addData.name,
        category: addData.category,
        mrp: addData.mrp,
        buying_price: addData.buying_price,
        sales_price: addData.sales_price,
        description: addData.description,
        notes: addData.notes,
        status: addData.status,
        buying_quantity: addData.buying_price,
        solding_quantity: addData.solding_quantity,
        available_quantity: addData.available_quantity,
      };
      const response = await axios.post(ApiUrl + "/stock", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        goods_id: "",
        name: "",
        category: "",
        mrp: "",
        buying_price: "",
        sales_price: "",
        description: "",
        notes: "",
        status: "",
        buying_quantity: "",
        solding_quantity: "",
        available_quantity: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(ApiUrl + "/stock");
  //     console.log("Response from server:", response.data);
  //     setResponseData(response.data.reverse());
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };
  useEffect(() => {
    fetchData();
  }, []); // This will run when the component mounts

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiUrl + "/stock");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse()); // Update state with the fetched data
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateData, setUpdateData] = useState({
    goods_id: "",
    name: "",
    category: "",
    mrp: "",
    buying_price: "",
    sales_price: "",
    description: "",
    notes: "",
    status: "",
    buying_quantity: "",
    solding_quantity: "",
    available_quantity: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  const [updateid, setUpdateId] = useState("");
  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log(" the data to updata", data);
    setUpdateShowModal(true);
    setUpdateData(data);
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        goods_id: updateData.goods_id,
        name: updateData.name,
        category: updateData.category,
        mrp: updateData.mrp,
        buying_price: updateData.buying_price,
        sales_price: updateData.sales_price,
        description: updateData.description,
        notes: updateData.notes,
        status: updateData.status,
        buying_quantity: updateData.buying_price,
        solding_quantity: updateData.solding_quantity,
        available_quantity: updateData.available_quantity,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(ApiUrl + `/stock/${updateid}`, formData);
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const [deleteShowModals, setDeleteShowModals] = useState(false);

  const handleOpenModal = (_id, goods_id) => {
    console.log(" the id", _id);
    console.log(" the name", goods_id);
    setdeleteid(_id);
    setdeletename(goods_id);
    setDeleteShowModals(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(ApiUrl + `/stock/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Stock</b>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 d-flex">
            <h5 className="mt-2">Stock</h5>
            <div className="mx-3">
              <button
                className="btn addBtn add mx-3"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
            </div>
          </div>
          <div className="col-lg-2">
            <div>
              <input
                type="file"
                id="file-upload"
                accept=".xls,.xlsx"
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload" className="btn btn-secondary">
                File Upload
              </label>
            </div>
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select className="form-control w-100 mx-2">
                  {/* <option value="5">5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------end------------------------------------------- */}

        {/* Table */}
        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Goods Id</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>M.R.P</th>
                  <th>Buy price</th>
                  <th>Sale Price</th>
                  <th>Total Qty</th>
                  <th>Available Qty</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.goods_id}</td>
                    <td>{item.name}</td>
                    <td>{item.category}</td>
                    <td>{item.mrp}</td>
                    <td>{item.buying_price}</td>
                    <td>{item.sales_price}</td>
                    <td>{item.total_quantity}</td>
                    <td>{item.available_quantity}</td>
                    <td>
                      <button
                        className="btn updelete "
                        onClick={() => handleUpdate(item._id, item)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>

                      <button
                        className="btn delete mx-1"
                        onClick={() => handleOpenModal(item._id, item.goods_id)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>

                      <button className="btn btn-transpront">
                        <i class="fa-solid fa-circle-info"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <div className="insermodal">
          <Modal
            className="addmodals"
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Stock</Modal.Title>
            </Modal.Header>

            <form>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.goods_id}
                        onChange={(e) =>
                          setAddData({ ...addData, goods_id: e.target.value })
                        }
                        required
                      />
                      <label className={addData.goods_id ? "filled" : ""}>
                        Goods ID
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={addData.name}
                        onChange={(e) =>
                          setAddData({ ...addData, name: e.target.value })
                        }
                        required
                      />
                      <label className={addData.name ? "filled" : ""}>
                        Name
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.category}
                        onChange={(e) =>
                          setAddData({ ...addData, category: e.target.value })
                        }
                        required
                      />
                      <label className={addData.category ? "filled" : ""}>
                        Category
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.mrp}
                        onChange={(e) =>
                          setAddData({ ...addData, mrp: e.target.value })
                        }
                        required
                      />
                      <label className={addData.mrp ? "filled" : ""}>MRP</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.buying_price}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            buying_price: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.buying_price ? "filled" : ""}>
                        Buying Price
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.sales_price}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            sales_price: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.sales_price ? "filled" : ""}>
                        Sales Price
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.description}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            description: e.target.value,
                          })
                        }
                        required
                      />
                      <label className={addData.description ? "filled" : ""}>
                        Description
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.notes}
                        onChange={(e) =>
                          setAddData({ ...addData, notes: e.target.value })
                        }
                        required
                      />
                      <label className={addData.notes ? "filled" : ""}>
                        Notes
                      </label>
                    </div>

                    <div className="floating-label">
                      <select className="form-control" required>
                        <option value="">Select Option</option>
                        <option value="optionA">Google Ad</option>
                        <option value="optionB">Website</option>
                        <option value="optionC">Facebook</option>
                        <option value="optionC">Instagram</option>
                        <option value="optionC">Linked In</option>
                        <option value="optionC">Referral</option>
                      </select>
                      <label>Select Status</label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.buying_quantity}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            buying_quantity: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.buying_quantity ? "filled" : ""}
                      >
                        Buying Quantity
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.solding_quantity}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            solding_quantity: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.solding_quantity ? "filled" : ""}
                      >
                        Solding Quantity
                      </label>
                    </div>

                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="tel"
                        placeholder=""
                        value={addData.available_quantity}
                        onChange={(e) =>
                          setAddData({
                            ...addData,
                            available_quantity: e.target.value,
                          })
                        }
                        required
                      />
                      <label
                        className={addData.available_quantity ? "filled" : ""}
                      >
                        Available Quantity
                      </label>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  className="cancelbut"
                  variant="secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="submitbut"
                  type="submit"
                  variant="primary"
                  onClick={handlesubmit}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>

        {/* ///////////////////////////////////////////////////////////////////////////////// */}

        <Modal
          show={isUpdateshowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Source Type </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.goods_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        goods_id: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.goods_id ? "filled" : ""}>
                    Goods ID
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.name}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, name: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.name ? "filled" : ""}>
                    Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.category}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, category: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.category ? "filled" : ""}>
                    Category
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.mrp}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, mrp: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.mrp ? "filled" : ""}>MRP</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.buying_price}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        buying_price: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.buying_price ? "filled" : ""}>
                    Buying Price
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.sales_price}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        sales_price: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.sales_price ? "filled" : ""}>
                    Sales Price
                  </label>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.description}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.description ? "filled" : ""}>
                    Description
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.notes}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, notes: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Notes
                  </label>
                </div>

                <div className="floating-label">
                  <select className="form-control" required>
                    <option value="">Select Option</option>
                    <option value="optionA">Google Ad</option>
                    <option value="optionB">Website</option>
                    <option value="optionC">Facebook</option>
                    <option value="optionC">Instagram</option>
                    <option value="optionC">Linked In</option>
                    <option value="optionC">Referral</option>
                  </select>
                  {/* <label>Select Status</label> */}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.buying_quantity}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        buying_quantity: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.buying_quantity ? "filled" : ""}>
                    Buying Quantity
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.solding_quantity}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        solding_quantity: e.target.value,
                      })
                    }
                    required
                  />
                  <label
                    className={updateData.solding_quantity ? "filled" : ""}
                  >
                    Solding Quantity
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={updateData.available_quantity}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        available_quantity: e.target.value,
                      })
                    }
                    required
                  />
                  <label
                    className={updateData.available_quantity ? "filled" : ""}
                  >
                    Available Quantity
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleupdatesubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1">Source Type :</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* _------------------------------pagination button -------------------------------- */}
        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li className="">
                    <button className="page-link">Previous</button>
                  </li>

                  <li className="">
                    <button className="page-link ">1</button>
                  </li>

                  <li className="">
                    <button className="page-link ">Next</button>
                  </li>
                </ul>
              </nav>{" "}
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark ">Page 1 of 1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stock;
