import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../Environment/ApiUrl';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";


function DailyTask() {


    const [responseData, setResponseData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUpdateshowModal, setUpdateShowModal] = useState(false);
    const [branch, setbranch] = useState([]);
    const [staffId, Setstaffid] = useState([]);

    const [role_id, setroleid] = useState('');
    const [previllages, setprevillages] = useState({})


    const [addData, setAddData] = useState({




        staff_id: '',

        date: '',
        Description: '',
        notes: '',
        status: ''
    });


    const [updateData, setUpdateData] = useState({


        staff_id: '',

        date: '',
        Description: '',
        notes: '',
        status: ''
    });

    useEffect(() => {
        fetchData();
        fetchDataed();
        gettoken();
        fetchDatastaff();
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);





    // const fetchData = async () => {
    //     try {
    //         // Retrieve branch details and employee name from localStorage
    //         const branchId = localStorage.getItem("branch_id");
    //         const branchName = localStorage.getItem("branch_name");
    //         const empName = localStorage.getItem("username");

    //         // Check if the condition to fetch all data is met
    //         if (branchId === null || branchName === "Branches" || empName === undefined) {
    //             // Fetch all attendance data if condition is true
    //             const response = await axios.get(apiUrl + "/dailytask");
    //             console.log("Response from server with all attendance data:", response.data);
    //             setResponseData(response.data.reverse());
    //         } else {
    //             // Fetch data based on branchId and empid (from localStorage)
    //             const response = await axios.get(apiUrl + "/dailytask", {
    //                 params: { data: branchId },
    //             });
    //             console.log("Response from server with filtered attendance data:", response.data);

    //             // Get the empid from localStorage
    //             const localEmpid = localStorage.getItem("username");

    //             // Filter data based on the empid from localStorage
    //             const filteredData = response.data.filter(item => item.staff_id === localEmpid);

    //             setResponseData(filteredData.reverse());
    //         }
    //     } catch (error) {
    //         console.error("Error fetching data:", error.message);
    //     }
    // };





    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + "/dailytask");
            console.log("Response from server:", response.data);
            setResponseData(response.data.reverse());
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const fetchDataed = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Response from server:", response.data);
            setbranch(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchDatastaff = async () => {
        try {
            const response = await axios.get(apiUrl + "/staff");
            console.log("Response from server staff:", response.data);
            Setstaffid(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const gettoken = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(apiUrl + "/get_detail", { "token": token });
            console.log("token detail from server:", response.data);


            setroleid(response.data.detail.rolename);

            localStorage.setItem('role_id', response.data.detail.rolename);

            const id = response.data.detail.rolename

            if (id) {
                try {
                    const response = await axios.get(apiUrl + `/role_2/${id}`);


                    const modules = response.data.modules;

                    const filtered_access = modules.filter(item => item.name == "Branch")


                    setprevillages(filtered_access[0].actions);



                } catch (error) {
                    console.error("Error fetching data:", error.message);
                }
            }

        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    }






    // const handleNewButtonClick = () => {
    //     if (previllages.add || previllages.all) {
    //     setShowModal(true);
    // }else{
    //     toast.error("You don't have the permission to add");
    // }
    // }

    const handlesubmit = async (e) => {
        e.preventDefault();


        try {
            const formData = {




                staff_id: addData.staff_id,

                date: addData.date,
                Description: addData.Description,
                notes: addData.notes,
                status: addData.status,
            }
            const response = await axios.post(apiUrl + "/dailytask", formData);
            console.log("Updated:", response.data.date);
            setShowModal(false);
            toast.success("added successfully!");
            setAddData({


                staff_id: '',

                date: '',
                Description: '',
                notes: '',
                status: ''
            });
            fetchData();
        } catch (error) {
            console.error("Error updating data:", error.message);
        }
    };


    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (_id, data) => {
        // if (previllages.edit || previllages.all) {
            setUpdateId(_id);
            console.log("the data to update", data);
            setUpdateShowModal(true);
    
            const formattedDate = new Date(data.date).toISOString().split("T")[0]; // Format to YYYY-MM-DD
    
            setUpdateData({
                staff_id: data.staff_id,
                date: formattedDate,
                Description: data.Description,
                notes: data.notes,
                status: data.status,
            });
        // } else {
        //     toast.error("You don't have the permission to update");
        // }
    };
    



    const handleupdatesubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = {


                staff_id: updateData.staff_id,

                date: updateData.date,
                Description: updateData.Description,
                notes: updateData.notes,
                status: updateData.status,
            }
            console.log("the update submit data", formData)
            const response = await axios.put(apiUrl + `/dailytask/${updateid}`, formData)
            console.log("response for update", response.data.date)
            setUpdateShowModal(false)
            toast.success("Update successfully!");
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }



    const handleDelete = async (id) => {
        try {

            const response = await axios.delete(apiUrl + `/dailytask/${getdeleteid}`)
            console.log("delete data ", response)
            toast.error("Delete successfully!");
            setDeleteShowModals(false);
            fetchData();
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    };


    const [deleteShowModals, setDeleteShowModals] = useState(false);
    const handleCloseModal = () => {
        setDeleteShowModals(false);
    }

    const [getdeleteid, setdeleteid] = useState('')

    const [getdeletename, setdeletename] = useState('')
    const handleOpenModal = (_id, name) => {
        if (previllages.all || previllages.delete) {
            console.log(" the id", _id)
            console.log(" the name", name)
            setdeleteid(_id);
            setdeletename(name);
            setDeleteShowModals(true);
        } else {
            toast.error("You don't have the permission to delete");
        }
    }


    // the pagination ---------------------------------------------------------------------
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Search
    const filtered = currentItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (typeof value === "number" && value.toString().includes(searchQuery))
        )
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(responseData.length / itemsPerPage);

    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage === totalPages ? prevPage : prevPage + 1
        );
    };

    const goToPrevPage = () => {
        setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
    };
    // the pagination  end ---------------------------------------------------------------------


    // const formatDatefitching = (isoDate) => {
    //     console.log("the is date ", isoDate);
    //     if (!isoDate) return ""; // Return an empty string if no date is provided
    //     const date = new Date(isoDate);

    //     const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    //     const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    //     const year = date.getFullYear();

    //     return `${month}-${day}-${year}`; // Format as "month-day-year"
    // };

    const formatDatefitching  = (isoDate) => {
        if (!isoDate) return ""; // Return an empty string if no date is provided
        const date = new Date(isoDate);
    
        const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`; // Format as "day-month-year"
      };

      

    useEffect(() => {
        // Get the current date
        const currentDate = new Date().toISOString().split('T')[0]; // Format to YYYY-MM-DD
        setAddData((prevData) => ({ ...prevData, date: currentDate }));
    }, []);


    useEffect(() => {
        const currentDate = new Date().toISOString().split("T")[0]; // Format to YYYY-MM-DD
        setUpdateData((prevData) => ({
            ...prevData,
            date: prevData.date || currentDate, // Only set if date is not already defined
        }));
    }, []);
    

    return (
        <div className='top'>
            <div className="" style={{ marginTop: '-80px' }}>
                <div className="row mb-3">
                    <div className="col-lg-11">
                        <Link to="/dashboard" className="text-secondary dashBoard_text">
                            <b>Dashboard / </b>
                        </Link>
                        <Link to="/assetlist" className="text-secondary dashBoard_text">
                            <b>Staff / </b>
                        </Link>
                        <Link to="" className="text-danger dashBoard_text">
                            <b>DailyTask</b>
                        </Link>
                    </div>
                </div>
            </div>
            {/* Table */}
            <div className='d-flex'>
                <h5 className='mt-2'>Daily Task</h5>
                <div className='mx-3'>
                    {/* {previllages.add && ( */}
                        <button className='btn add addBtn' onClick={() => setShowModal(true)}>Add</button>
                    {/* )} */}
                </div>
            </div>

            {/* ------------ search and per page ----------------------------*/}

            <div className="mb-3 mt-3">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                        <label className='form-label text-dark mt-2'>Show : </label>
                        <div>
                            <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                                {/* <option value='5'>5</option> */}
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-4 col-sm-1"></div>
                    <div className="col-lg-3 col-md-4 col-sm-7">

                        <div className="w-full flex-1">
                            <form>
                                <div className="relative">

                                    <input
                                        className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                                        placeholder="Search ..."
                                        type="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------end------------------------------------------- */}
            <div className='card'>
                <div className="mt-1">
                    <table className="table table-striped">
                        <thead>
                            <tr>

                                <th>S.No</th>
                                <th>StaffId</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th>Notes</th>
                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtered.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.staff_id}</td>

                                    <td>{item.Description}</td>
                                    <td>{formatDatefitching(item.date)}</td>
                                    <td>{item.notes}</td>

                                    <td className='text-center'>
                                        {/* {previllages.edit && ( */}
                                            <button className='btn updelete mx-2' onClick={() => handleUpdate(item._id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                                            {/* )} */}
                                        {/* {previllages.delete && 
                                        ( */}
                                        <button className='btn delete'
                                            onClick={() => handleOpenModal(item._id, item.name)}
                                        > <i class="fa-solid fa-trash-can"></i></button>
                                     {/* )} */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal for adding a new user */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Daily Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-group">



                        <div className="floating-label">
                            <select
                                className="form-control"
                                value={addData.staff_id}
                                onChange={(e) => setAddData({ ...addData, staff_id: e.target.value })}
                                required
                            >
                                <option value="">Select Staff</option>
                                {staffId.map((item, index) => (
                                    <option key={index} value={item.empid}>
                                        {item.empid} - {item.name}
                                    </option>
                                ))}
                            </select>
                            <label className={addData.staff_id ? "filled" : ""}>

                            </label>
                        </div>

                        {/* 
                        <div className="floating-label">
                            <input className="form-control" placeholder='' type="text" value={addData.staff_name} onChange={(e) => setAddData({ ...addData, staff_name: e.target.value })} required />
                            <label className={addData.staff_name ? "filled" : ""}>
                                Staff Name
                            </label>


                        </div> */}

                        <div className="floating-label">
                            <input
                                className="form-control"
                                placeholder=""
                                type="date"
                                value={addData.date}
                                onChange={(e) => setAddData({ ...addData, date: e.target.value })}
                                required
                            />
                            <label className={addData.date ? "filled" : ""}>
                                Date
                            </label>
                        </div>


                        <div className="floating-label">
                            <input className="form-control" placeholder='' type="text" value={addData.Description} onChange={(e) => setAddData({ ...addData, Description: e.target.value })} required />
                            <label className={addData.Description ? "filled" : ""}>
                                Description
                            </label>


                        </div>
                        <div className="floating-label">
                            <input className="form-control" placeholder='' type="text" value={addData.notes} onChange={(e) => setAddData({ ...addData, notes: e.target.value })} required />
                            <label className={addData.notes ? "filled" : ""}>
                                Notes
                            </label>


                        </div>

                        <div className="floating-label">
                            <select className="form-control" value={addData.status} onChange={(e) => setAddData({ ...addData, status: e.target.value })}>
                                <option value="">Select Status</option>
                                <option value="Schedule">Schedule</option>
                                <option value="Inprogress">Inprogress</option>
                                <option value="completed">completed</option>
                                <option value="Incompleted">Incompleted</option>
                            </select>
                            <label className={addData.status ? "filled" : ""}>
                                Select Status
                            </label>

                        </div>



                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update DailyTask</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* Other input fields */}



                    <div className="form-group">




                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.staff_id} onChange={(e) => setUpdateData({ ...updateData, staff_id: e.target.value })} required />
                            <label className={updateData.staff_id ? "filled" : ""}>
                                Staff Id
                            </label>
                        </div>




                        <div className="floating-label">
                            <input className="form-control" type="date" placeholder='' value={updateData.date} onChange={(e) => setUpdateData({ ...updateData, date: e.target.value })} required />
                            <label className={updateData.date ? "filled" : ""}>
                                Date
                            </label>
                        </div>



                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.Description} onChange={(e) => setUpdateData({ ...updateData, Description: e.target.value })} required />
                            <label className={updateData.Description ? "filled" : ""}>
                                Description
                            </label>
                        </div>

                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.notes} onChange={(e) => setUpdateData({ ...updateData, notes: e.target.value })} required />
                            <label className={updateData.notes ? "filled" : ""}>
                                Notes
                            </label>
                        </div>


                        {/* <div className="floating-label">
                            <select className="form-control" value={updateData.status} onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}>
                                <option value="">Select Status</option>
                                <option value="Approved">Approved</option>
                                <option value="Pending">Pending</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                            <label className={updateData.status ? "filled" : ""}>
                                Select Status
                            </label>
                        </div> */}



                        <div className="floating-label">
                            <select className="form-control" value={updateData.status} onChange={(e) => setUpdateData({ ...updateData, status: e.target.value })}>
                                <option value="">Select Status</option>
                                <option value="Schedule">Schedule</option>
                                <option value="Inprogress">Inprogress</option>
                                <option value="completed">completed</option>
                                <option value="Incompleted">Incompleted</option>
                            </select>
                            <label className={updateData.status ? "filled" : ""}>
                                Select Status
                            </label>

                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
                    <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
                </Modal.Footer>
            </Modal>





            {/* ----------------delete */}

            <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
                <Modal.Header>
                    <p>Are you sure you want to Delete?</p>
                </Modal.Header>
                <Modal.Body>


                    <label className="form-label d-flex">
                        <h5 className='mx-1'>Asset Type Name :</h5> <h5>{getdeletename} </h5>
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>
            {/* _------------------------------pagination button -------------------------------- */}


            <div className="mt-3 pb-5">
                <div className="row">

                    <div className="col-lg-10 col-sm-12">
                        <nav className="d-flex float-right">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={` page-item ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link" onClick={goToPrevPage}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i}
                                        className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <button
                                            className="page-link "
                                            onClick={() => paginate(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <button className="page-link " onClick={goToNextPage}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>{" "}
                    </div>
                    <div className="col-lg-2 col-sm-12 mt-1">
                        <div className="float-end">
                            <span className="text-dark ">
                                Page {currentPage} of {totalPages}
                            </span>
                        </div>
                    </div>

                </div>
            </div>


            {/* ---------------------------------------end ---------------------------------- */}


        </div>
    )
}


export default DailyTask
