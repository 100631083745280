import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../Environment/ApiUrl";
import "../../assets/css/Expenselist.css";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

function ExpenseList() {
  const [responseData, setResponseData] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [branch, setbranch] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [officeexpense, setOfficeexpense] = useState([]);
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});
  const [paymenttype, setpaymenttype] = useState([]);
  // current date//////////////////////////////
  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Convert input to Date if it's not already
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [rolename, setrolename] = useState([]);
  useEffect(() => {
    fetchData();
    const rolename = localStorage.getItem("rolename");
    setrolename(rolename);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/expense_list");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    type: "",
    date: formatDate(new Date()),
    notes: "",
    expense_type: "",
    amount: "",
    from: "",
    paidto: "",
    paytype: "",
  });

  useEffect(() => {
    fetchDatas();
    fetchDataed();
    gettoken();
    fetchVendorDetails();
    paymenttyperoute();
    fetchExpenseDetails();
  }, []);
  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/expense_type");
      console.log("Response from server type:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // const fetchDataed = async () => {
  //   try {
  //     const response = await axios.get(apiUrl + "/Branch");
  //     console.log("Response from server:", response.data);
  //     setbranch(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };
  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data); // Ensure you set the data correctly
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const paymenttyperoute = async () => {
    try {
      const response = await axios.get(apiUrl + "/paytype");
      console.log("Response from server:", response.data);
      setpaymenttype(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchExpenseDetails = async () => {
    try {
      const response = await axios.get(apiUrl + "/Officeexpense");
      console.log("Response from expense_details API:", response.data);
      setOfficeexpense(response.data); // Set office expense data
    } catch (error) {
      console.error("Error fetching office expense data:", error.message);
    }
  };

  // Function to fetch vendor details
  const fetchVendorDetails = async () => {
    try {
      const response = await axios.get(apiUrl + "/vendar_details");
      console.log("Response from vendar_details API:", response.data);
      setVendor(response.data); // Set vendor data
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);

      setroleid(response.data.detail.rolename);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);

          const modules = response.data.modules;

          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );

          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    // if (previllages.edit || previllages.all) {
    setUpdateId(_id);

    const date = new Date(data.date);
    if (isNaN(date)) {
      console.error("Invalid date:", data.date);
      return;
    }
    setUpdateShowModal(true);
    const formattedDate = date.toLocaleDateString("en-CA");
    console.log("the data type ", data);
    setUpdateData({
      branch_id: data.branch_id?._id,
      branch_name: data.branch_id?.branch_name,
      // type: data.type[0]._id,
      type: data.type.length != 0 ? data.type[0].type : "-",
      // type: data.type,
      date: formattedDate,
      expense_type: data.expense_type,
      notes: data.notes,
      amount: data.amount,
      from: data.from,
      paidto: data.paidto,
      paytype: data.paytype,
    });
    // } else {
    //   toast.error("You don't have the permission to update");
    // }
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id,
        // type: updateData.type[0]._id
        // type: updateData.type,
        type: updateData.type.length != 0 ? updateData.type[0].type : "-",
        date: updateData.date,
        notes: updateData.notes,
        expense_type: updateData.expense_type,
        amount: updateData.amount,
        from: updateData.from,
        paidto: updateData.paidto,
        paytype: updateData.paytype,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        apiUrl + `/expense_list/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (_id) => {
    try {
      await axios.delete(apiUrl + `/expense_list/${getdeleteid}`);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting role:", error.message);
    }
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };
  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);

    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero for single-digit days
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero for single-digit months
    const year = date.getFullYear();

    return `${day}-${month}-${year}`; // Format as "day-month-year"
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, notes) => {
    // if (previllages.all || previllages.delete) {
    console.log(" the id", _id);
    console.log(" the name", notes);
    setdeleteid(_id);
    setdeletename(notes);
    setDeleteShowModals(true);
    // } else {
    //   toast.error("You don't have the permission");
    // }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  const navigate = useNavigate();

  const handleExpenseType = () => {
    navigate("/expensetype");
  };

  const [addData, setAddData] = useState({
    branch_id: "",
    type: "",
    date: "",
    notes: "",
    expense_type: "",
    amount: "",
    from: "",
    paidto: "",
    paytype: "",
  });

  const handleDateChange = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      date: e.target.value, // Input type=date handles the format
    }));
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        branch_id: addData.branch_id,
        type: addData.type ? addData.type : "",
        date: addData.date,
        notes: addData.notes,
        expense_type: addData.expense_type,
        amount: addData.amount,
        from: addData.from,
        paidto: addData.paidto,
        paytype: addData.paytype,
      };

      const response = await axios.post(apiUrl + "/expense_list", formData);
      console.log("Addeddddddddd:", response);

      // Reset the form and close the modal
      setShowModal(false);
      toast.success("Added successfully!");
      fetchData(); // Fetch updated data
      setAddData({
        branch_id: "",
        type: "",
        date: "",
        expense_type: "",
        notes: "",
        amount: "",
        from: "",
        paidto: "",
        paytype: "",
      });
    } catch (error) {
      console.error("Error adding data:", error.message);
      toast.error("Failed to add expense!"); // Optionally show error message
    }
  };

  useEffect(() => {
    if (addData.expense_type === "Office expense") {
      fetchExpenseDetails(); // Fetch office expenses
    } else if (addData.expense_type === "Vendor expense") {
      fetchVendorDetails(); // Fetch vendor details
    }
  }, [addData.expense_type]);

  useEffect(() => {
    if (updateData.expense_type === "Office expense") {
      fetchExpenseDetails(); // Fetch office expenses
    } else if (updateData.expense_type === "Vendor expense") {
      fetchVendorDetails(); // Fetch vendor details
    }
  }, [updateData.expense_type]); // Re-run whenever `expense_type` changes

  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Expense List</b>
            </Link>
            {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10">
          <div className="d-flex">
            <h5 className="mt-2">Expense List</h5>
            <div className="mx-3">
              {/* {previllages.add && (
               */}
              <button
                className="btn addBtn add"
                onClick={() => setShowModal(true)}
              >
                Add
              </button>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-secondary" onClick={handleExpenseType}>
            Expense Type
          </button>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                {/* <option value='5'>5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                {rolename === "superadmin" && <th>Branch</th>}
                <th>Expense Type</th>
                <th>Type</th>

                <th>Paid To</th>
                <th>Date</th>

                <th>Amount</th>
                <th>Pay Type</th>
                <th className="text-center action">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>

                  {rolename === "superadmin" && (
                    <td>{item.branch_id.branch_name}</td>
                  )}
                  <td>{item.expense_type}</td>
                  {/* <td>{item.type ? item.type.type : "-"}</td> */}
                  <td>{item.type[0]?.type || "-"}</td>

                  {/* <td>{item.notes}</td> */}
                  <td>{item.paidto}</td>
                  <td>{formatDatefitching(item.date)}</td>
                  {/* <td>{new Date(item.date).toLocaleDateString()}</td> */}
                  <td>{item.amount}</td>
                  <td>{item.paytype}</td>
                  <td className="text-center">
                    {/* {previllages.edit && ( */}
                    <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdate(item._id, item)}
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    {/* )} */}
                    {/* {previllages.delete && ( */}
                    <button
                      className="btn delete"
                      onClick={() => handleOpenModal(item._id, item.notes)}
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                    {/* )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Expense List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.branch_id}
                    onChange={(e) =>
                      setAddData({ ...addData, branch_id: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Branch</option>
                    {branch.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.branch_name}
                      </option>
                    ))}
                  </select>
                  <label className={addData.branch_id ? "filled" : ""}>
                    Select Branch
                  </label>
                </div>

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={addData.expense_type}
                    onChange={(e) =>
                      setAddData({ ...addData, expense_type: e.target.value })
                    }
                  >
                    <option value="">Expense type</option>
                    <option value="Office expense">Office expense</option>
                    <option value="Vendor expense">Vendor expense</option>
                  </select>
                  <label className={addData.expense_type ? "filled" : ""}>
                    Expense type
                  </label>
                </div>

                {addData.expense_type === "Office expense" && (
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={addData.type}
                      onChange={(e) =>
                        setAddData({ ...addData, type: e.target.value })
                      }
                    >
                      <option value="">Select Type</option>
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    <label className={addData.type ? "filled" : ""}>
                      Select Type
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.paidto}
                    onChange={(e) =>
                      setAddData({ ...addData, paidto: e.target.value })
                    }
                    required
                  >
                    <option value="" disabled>
                      {" "}
                      Select{" "}
                      {addData.expense_type === "Office expense"
                        ? "Office"
                        : "Vendor"}
                    </option>

                    {addData.expense_type === "Office expense" &&
                      officeexpense.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}

                    {addData.expense_type === "Vendor expense" &&
                      vendor.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <label className={addData.paidto ? "filled" : ""}>
                    Select paid To
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    value={addData.date}
                    // onChange={handleDateChange}
                    onChange={(e) =>
                      setAddData({ ...addData, date: e.target.value })
                    }
                    required
                  />
                  <label className={addData.date ? "filled" : ""}>Date</label>
                  {/* {errors.date && (
                    <div className="error-text">{errors.date}</div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="number"
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({ ...addData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.amount ? "filled" : ""}>
                    Amount
                  </label>
                  {/* {errors.amount && (
                    <div className="error-text">{errors.amount}</div>
                  )} */}
                </div>

                {/* Paid To Dropdown */}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={addData.paytype}
                    onChange={(e) =>
                      setAddData({ ...addData, paytype: e.target.value })
                    }
                  >
                    <option value="">Select Paytype </option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={addData.paytype ? "filled" : ""}></label>
                  {/* {errors.paytype && (
                    <div className="error-text">{errors.paytype}</div>
                  )} */}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    value={addData.notes}
                    onChange={(e) =>
                      setAddData({ ...addData, notes: e.target.value })
                    }
                    required
                  />
                  <label className={addData.notes ? "filled" : ""}>Notes</label>
                  {/* {errors.notes && (
                    <div className="error-text">{errors.notes}</div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isUpdateshowModal}
        onHide={() => setUpdateShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Expense list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                {/* Other input fields */}

                {/* <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.branch_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Branch</option>
                    {branch.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.branch_name}
                      </option>
                    ))}
                  </select>

                  <label className={updateData.branch_id ? "filled" : ""}>
                    Branch
                  </label>
                </div> */}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.branch_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Branch</option>
                    {branch.length > 0 ? (
                      branch.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.branch_name}
                        </option>
                      ))
                    ) : (
                      <option value="">Loading branches...</option> // Show loading option if data is not available
                    )}
                  </select>
                </div>
                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={updateData.expense_type}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        expense_type: e.target.value,
                      })
                    }
                  >
                    <option value="">Expense type</option>
                    <option value="Office expense">Office expense</option>
                    <option value="Vendor expense">Vendor expense</option>
                  </select>
                  <label className={updateData.expense_type ? "filled" : ""}>
                    Expense type
                  </label>
                </div>

                {updateData.expense_type === "Office expense" && (
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={updateData.type}
                      placeholder=""
                      onChange={(e) =>
                        setUpdateData({ ...updateData, type: e.target.value })
                      }
                    >
                      {/* {responseDatas.map((item, index) => (
                        <option key={index} value={item.type}>
                          {item.type}
                        </option>
                      ))} */}
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.type}
                        </option>
                      ))}
                    </select>
                    <label className={updateData.type ? "filled" : ""}>
                      Type
                    </label>
                  </div>
                )}

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.paidto}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paidto: e.target.value })
                    }
                    required
                  >
                    {/* <option value="">Select Name</option> */}
                    <option value="">
                      Select{" "}
                      {updateData.expense_type === "Office expense"
                        ? "Office"
                        : "Vendor"}
                    </option>
                    {/* Render options based on selected expense_type */}
                    {updateData.expense_type === "Office expense" &&
                      officeexpense.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    {updateData.expense_type === "Vendor expense" &&
                      vendor.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <label className={updateData.paidto ? "filled" : ""}>
                    {/* Optionally you can add custom label text */}
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={updateData.date}
                    // onChange={handleDateChange}
                    required
                  />
                  <label className={updateData.date ? "filled" : ""}>
                    Date
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-control"
                    value={updateData.paytype}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paytype: e.target.value })
                    }
                  >
                    <option value="">Select Paytype </option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.paytype ? "filled" : ""}></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.notes}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, notes: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.notes ? "filled" : ""}>
                    Notes
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to Delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </div>
  );
}

export default ExpenseList;
