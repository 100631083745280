import React from "react";
import "../../assets/css/Quotation.css";


function Quotation() {
    return (
        <div className="mainsection">

            <div className="row">
                <div className="col-lg-5"></div>
                <div className="col-lg-3">
                    <h5 className="head" style={{ textDecoration: "underline" }}>
                        New Quotation
                    </h5>
                </div>
                <div className="col-lg-3 mt-2">
                    <select className="form-control" style={{ width: "150px", }}>
                        <option value="">Select dropdown</option>
                        <option value="option1">Project</option>
                        <option value="option2">Sales / Service</option>
                        {/* <option value="option3"></option> */}
                    </select>
                </div>
                <div className="col-lg-1"></div>

            </div>

            {/* first row  */}
            <div className="row first">
                <div className="col-lg-4">
                    <label>Quotation No:</label>
                    <input
                        type="text"
                        value="QUO0001"
                        className="form-control"
                    ></input>
                </div>
                <div className="col-lg-4">
                    <label>Date:</label>
                    <input
                        type="date"
                        // value={''}
                        className="form-control"
                    ></input>
                </div>
                <div className="col-lg-4">
                    <label>Valid Till Date:</label>
                    <input
                        type="date"
                        value={''}
                        className="form-control"
                    ></input>
                </div>
            </div>
            {/* the secon row  */}

            <div className="row second">
                <div className="col-lg-6">
                    <h1> Client Details (Quotation for)</h1>
                    <div className="row">
                        <div
                            className="col-lg-5 col-md-5 col-sm-12  "
                            style={{ textAlign: "left" }}
                        >
                            <p>Client Business Name</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"
                                value="siva"
                                className="form-control"
                            ></input>
                        </div>

                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p> Mobile / Contact</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"
                                value="9865946454"
                                className="form-control"
                            ></input>
                        </div>


                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>Offical Email</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"
                                value="Siva@gamil.com"
                                className="form-control"
                            ></input>
                        </div>

                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>GSTIN</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"
                                value="GSTIN926BS01"
                                className="form-control"
                            ></input>
                        </div>

                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>Address</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"
                                value="13/B anna nagar "
                                className="form-control"
                            ></input>
                        </div>


                    </div>

                </div>


                <div className="col-lg-6">
                    <h1> Quotation (Created By)</h1>
                    <div className="row">


                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>Staff ID</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"
                                value="SAV0001"
                                className="form-control"
                            ></input>
                        </div>

                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>Staff Name</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"
                                value="Guna"
                                className="form-control"
                            ></input>
                        </div>
                    </div>
                </div>
            </div>
            {/* ------------------------------------------- */}

            <div className="card invoice_card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th>S.NO</th>
                                    <th>Products</th>
                                    <th>Hsn/Sac</th>
                                    <th>GST</th>
                                    <th>Qty</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                    <th>CGST</th>
                                    <th>SGST</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr> <td>1</td>
                                    <td>Product Name </td>
                                    <td>00123</td>
                                    <td>18%</td>
                                    <td>2</td>
                                    <td>500</td>
                                    <td>1000.00</td>
                                    <td>46</td>
                                    <td>46</td>
                                    <td>590</td>
                                    <td>
                                        <i
                                            class="fa-sharp-duotone fa-solid fa-circle-xmark mx-2 fs-2"
                                            style={{ color: "red" }}
                                            onClick={''}
                                        ></i>

                                    </td>
                                </tr>

                            </tbody>


                        </table>
                    </div>
                </div>
            </div>

            {/* --------------------------------------------------------------------- */}
            <div className="row">
                <div className=" col-lg-10"></div>
                <div className=" col-lg-2">
                    <button
                        type="button"
                        className="btn btn-secondary Add"
                        onClick={''}
                    >
                        Add New
                    </button>
                </div>
            </div>

            {/* ---------------------------------------------------------------------- */}
            <div className=" row  second">
                <div className="col-lg-6">
                    <h3> Terms & Condition </h3>
                    <div>
                        <textarea rows="4" cols="50">

                        </textarea>
                    </div>
                    <div className="col-lg-10">
                        <label>Notes:</label>
                        <input
                            type="text"

                            className="form-control mx-1"
                        ></input>
                    </div>

                    {/* add file  */}
                    <div className="row mt-3">

                        <div
                            className="col-lg-4 col-md-5 col-sm-12 mt-2 "
                            style={{ textAlign: "left" }}
                        >
                            <h2>Add Attachments :</h2>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="file"

                                className="form-control"
                            ></input>
                        </div>
                        <div
                            className="col-lg-1 "></div>
                    </div>


                </div>
                <div className="col-lg-6">
                    <div className="row">
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>Sub Total</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="number"

                                className="form-control"
                            ></input>
                        </div>

                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>SGST</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="number"

                                className="form-control"
                            ></input>
                        </div>

                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>CGST</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"

                                className="form-control"
                            ></input>
                        </div>


                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>Discount</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"

                                className="form-control"
                            ></input>
                        </div>


                        {/* -------------------------------- */}
                        <div
                            className="col-lg-5 col-md-5 col-sm-12 "
                            style={{ textAlign: "left" }}
                        >
                            <p>Net Total</p>
                        </div>
                        <div
                            className="col-lg-7 col-md-7 col-sm-12"
                            style={{ textAlign: "right" }}
                        >
                            <input
                                style={{ width: "80%" }}
                                placeholder=""
                                type="text"

                                className="form-control"
                            ></input>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6">
                    <div
                        style={{ marginLeft: "75px" }}
                        className="d-flex justify-content-evenly mt-3 buttonstyle "
                    >
                        <button
                            type="button"
                            className="btn btn-danger invoice-button"
                            onClick={''}
                        >
                            Clear
                        </button>
                        <button
                            type="button"
                            className="btn btn-info text-white invoice-button"
                            style={{ backgroundColor: "#007BFF" }}
                            onClick={''}
                        >
                            Preview
                        </button>

                        <button
                            type="button"
                            className="btn btn-secondary invoice-button"
                            onClick={''}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-success invoice-button"
                            onClick={''}
                        >
                            Back
                        </button>
                    </div>
                </div>

            </div>




        </div >

    )
}



export default Quotation;