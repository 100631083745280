function DailyReport() {
  return (
    <>
      <div
        className="card p-2"
        style={{
          marginTop: "-80px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 pt-2 text-left">
            <h6 className="px-2 pt-2">
              <b>Daily Report</b>
            </h6>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-6 pt-2  d-flex"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div>
              <p className="pt-2">
                <b>Date</b>
              </p>
            </div>
            <input
              style={{ width: "200px", height: "40px" }}
              type="date"
              className="form-control mx-2"
            />
          </div>
        </div>

        <hr></hr>
        <div className="row p-3">
          <div className="col-lg-3 col-md-3 col-sm-12 text-center ">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">Opening Balance</h6>
              <h3 className="">Rs. 06</h3>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">Today Income</h6>
              <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">Cash</p>
                  <h5 className="">Rs. </h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">UPI</p>
                  <h5 className="">Rs. </h5>
                </div>
              </div>
              {/* <h3 className="">Rs. 06</h3> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-12">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">Today Expense</h6>
              <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">Cash</p>
                  <h5 className="">Rs. </h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">UPI</p>
                  <h5 className="">Rs. </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row p-3">
          <div className="col-lg-3 col-md-3 col-sm-12 text-center ">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">Closing Balance</h6>
              <h3 className="">Rs. 06</h3>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">To Convert</h6>
              <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">Cash</p>
                  <h5 className="">Rs. </h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">UPI</p>
                  <h5 className="">Rs. </h5>
                </div>
              </div>
              {/* <h3 className="">Rs. 06</h3> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-12">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">
                Student and Staff
              </h6>
              <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">Cash</p>
                  <h5 className="">Rs. </h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">UPI</p>
                  <h5 className="">Rs. </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        {/* <div className="row text-center p-2">
          <div className="col-lg-4">
            <p className="">
              <b>Date</b>
            </p>
            <input type="date" className="form-control"></input>
          </div>
          <div className="col-lg-2">
            <p className="">
              <b>Click</b>
            </p>
            <button className="btn border">
              <i class="fa-solid fa-filter text-danger"></i>
            </button>
          </div>
        </div> */}
      </div>
      <div className="container mt-5 bg-white">
        {/* Enquiry Report */}

        <div className="">
          <h6 className="text-center">
            <b>Enquiry Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select className="form-control mx-2" style={{ width: "80px" }}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value="">All</option>
                </select>
              </div>

              <div className="col-md-6 text-center">
                <button className="btn btn-secondary2 mx-2">Copy</button>
                <button
                  className="btn btn-success2 mx-2"
                  // onClick={handleCSVExport1}
                >
                  CSV
                </button>
                <button className="btn btn-excel2 mx-2">Export</button>
                <button className="btn btn-info2 mx-2">PDF</button>
                <button className="btn btn-warning2 mx-2">Print</button>
              </div>

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="enquiryTable">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Mobile No</th>
                      <th>Company</th>
                      <th>Source Type</th>
                      <th>Enquiry Date</th>

                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-10 "></div>
              <div className="col-lg-2 mt-3 pb-5">
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    <li className="">
                      <button className="page-link">Previous</button>
                    </li>

                    <li className="">
                      <button className="page-link">1</button>
                    </li>

                    <li className="">
                      <button className="page-link">Next</button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* Repeat similar structure for Expense Report */}
        <div className="mt-5">
          <h6 className="text-center">
            <b>Sales Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select className="form-control mx-2" style={{ width: "80px" }}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value="">All</option>
                </select>
              </div>

              <div className="col-md-6 text-center">
                <button className="btn btn-secondary2 mx-2">Copy</button>
                <button
                  className="btn btn-success2 mx-2"
                  // onClick={handleCSVExport1}
                >
                  CSV
                </button>
                <button className="btn btn-excel2 mx-2">Export</button>
                <button className="btn btn-info2 mx-2">PDF</button>
                <button className="btn btn-warning2 mx-2">Print</button>
              </div>

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="attendanceTable">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Branch</th>
                      <th>Employee ID</th>
                      <th>Name</th>
                      <th>Date</th>
                      <th>Login</th>
                      <th>Logout</th>
                      <th>Work Hours</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-3 pb-5">
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                  <li className="">
                    <button className="page-link">Previous</button>
                  </li>

                  <li className="">
                    <button className="page-link">1</button>
                  </li>

                  <li className="">
                    <button className="page-link">Next</button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* Repeat similar structure for Expense Report */}
        <div className="mt-5">
          <h6 className="text-center">
            <b>Expense Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select className="form-control mx-2" style={{ width: "80px" }}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value="">All</option>
                </select>
              </div>

              {/* <div className="col-md-6 text-center"> */}
              <div className="col-md-6 text-center">
                <button className="btn btn-secondary2 mx-2">Copy</button>
                <button
                  className="btn btn-success2 mx-2"
                  // onClick={handleCSVExport1}
                >
                  CSV
                </button>
                <button className="btn btn-excel2 mx-2">Export</button>
                <button className="btn btn-info2 mx-2">PDF</button>
                <button className="btn btn-warning2 mx-2">Print</button>
              </div>
              {/* </div> */}

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="incomeTable">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Branch</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Amount</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Paytype</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-9"></div>
              <div className="col-lg-3">
                <div className="mt-3 pb-5">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="">
                        <button className="page-link">Previous</button>
                      </li>

                      <li className="">
                        <button className="page-link">1</button>
                      </li>

                      <li className="">
                        <button className="page-link">Next</button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Repeat similar structure for Income Report */}
        <div className="mt-5">
          <h6 className="text-center">
            <b>Income Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select className="form-control mx-2" style={{ width: "80px" }}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value="">All</option>
                </select>
              </div>

              <div className="col-md-6 text-center">
                <button className="btn btn-secondary2 mx-2">Copy</button>
                <button className="btn btn-success2 mx-2">CSV</button>
                <button className="btn btn-excel2 mx-2">Export</button>
                <button className="btn btn-info2 mx-2">PDF</button>
                <button className="btn btn-warning2 mx-2">Print</button>
              </div>

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="incomeTable">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Branch Id</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Paytype</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-9"></div>
              <div className="col-lg-3">
                <div className="mt-3 pb-5">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="">
                        <button className="page-link">Previous</button>
                      </li>

                      <li className="">
                        <button className="page-link">1</button>
                      </li>

                      <li className="">
                        <button className="page-link">Next</button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DailyReport;
