import { Link } from "react-router-dom";
import "../../assets/css/ExpectedHistory.css";
function ExpectedHistory() {
  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Projects / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Expected / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Project Histroy</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex">
          <h5 className="mt-2">Project Histroy</h5>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-2">
            <div className="imgdetails">
              {/* <img
                className="profileimg"
                src={require("../../assets/img/icon.jpg")}
                alt="Profile"
              /> */}
              <h1 className="text-center fs-3 mb-4">Client Details</h1>
            </div>
            <div className="text-start mt-2 text-dark">
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-user px-3"></i>
                  <p>Siva</p>
                </div>
              </h6>
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-phone px-3"></i>
                  {/* {studentss[0]?.mobile_no} */}
                  <p>9865946454</p>
                </div>
              </h6>
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-id-card px-3"></i>
                  {/* {studentss[0]?.Prefered_course} */}
                  <p>Siva@gamil.com</p>
                </div>
              </h6>
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-book px-3"></i>
                  {/* {studentss[0]?.Source_type} */}
                  <p>GSTIN926BS01</p>
                </div>
              </h6>
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-calendar-days px-3"></i>
                  {/* {formatDate(studentss[0]?.Enquiry_date)} */}
                  <p>13/B anna nagar </p>
                </div>
              </h6>
              <h6 className="profile-detailsed">
                <div className="d-flex">
                  <i className="fa-solid fa-recycle px-3"></i>
                  No. of Attempts:
                  {/* {lead.length} */}
                  <p>6</p>
                </div>
              </h6>
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-sm-9 col-md-9">
          <div className="row cardborder">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>History</b>
                </div>
              </div>
            </div>

            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>QN No.</th>
                    <th>Send By</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Notes</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>03</td>
                    <td>Siva</td>
                    <td>20-01-2025</td>
                    <td>10000</td>
                    <td>Service</td>
                    <td>Verified</td>

                    <td className="text-center">
                      <button className="btn delete">
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpectedHistory;
