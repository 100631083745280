import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Dashboard.css";

function Dashboard() {
  // Data for the chart
  const horizontal_bar_data = [
    ["Month", "Population"],
    ["Jan", 1000],
    ["Feb", 1170],
    ["Mar", 660],
    ["Apr", 1030],
    ["May", 1200],
    ["Jun", 950],
    ["Jul", 1300],
  ];

  // Options for the chart
  const horizontal_bar_options = {
    title: "No of Students",
    curveType: "function",
    legend: { position: "bottom" },
    pointSize: 10, // Add points on the line
    pointsVisible: true, // Ensure points are visible
    colors: ["#E1C704"],
  };

  const line_chart_data = [
    ["Month", "Students"],
    ["Jan", 1000],
    ["Feb", 1170],
    ["Mar", 660],
    ["Apr", 1030],
    ["May", 1200],
    ["Jun", 950],
    ["Jul", 1300],
  ];

  // Options for the chart
  const line_chart_options = {
    title: "No of Students",
    curveType: "function",
    legend: { position: "bottom" },
    pointSize: 10, // Add points on the line
    pointsVisible: true, // Ensure points are visible
    colors: ["#E1C704"],
  };

  const double_bar_data = [
    ["Month", "Revenue", "Expense"],
    ["Jan", 1000, 1200],
    ["Feb", 1170, 1300],
    ["Mar", 660, 900],
    ["Apr", 1030, 1150],
    ["May", 1200, 1400],
    ["Jun", 950, 1100],
    ["Jul", 1300, 1500],
  ];

  const double_bar_options = {
    title: "Active students per month",
    curveType: "function",
    legend: { position: "bottom" },
    pointSize: 10, // Add points on the line
    pointsVisible: true, // Ensure points are visible
    // colors: ['#FF5733', '#33C4FF'], // Custom colors for 2010 (orange) and 2020 (blue)
    colors: ["#f1e101", "#cfab07"], // Custom colors for 2010 (orange) and 2020 (blue)
  };

  const navigate = useNavigate();


  

  return (
    <>
      <div
        className="container-fluid"
        style={{ background: "#ebebeb", marginTop: "-80px" }}
      >
        <div className="mt-2">
          <h5>
            <b>Dashboard</b>
          </h5>
        </div>

        <div className="row  ">
          <div className="col-lg-12  col-md-8 col-sm-6">
            <div className="row">
              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/newenquiry")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-solid fa-graduation-cap iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Leads</b>
                    </h6>
                  </div>
                </div>
              </div>

              {/* Course */}

              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/followsups")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-solid fa-user-plus iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Pending Followups</b>{" "}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/upcomings")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-brands fa-readme iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Project due Followups</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/products")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-solid fa-toggle-on iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Product</b>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12  col-md-8 col-sm-6 mt-2">
            <div className="row">
              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/upcomings")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className="fa-solid fa-chalkboard-user iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Projects</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2 "
                  onClick={() => navigate("/upcomingservices")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className=" fa-solid fa-gears iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Services</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2 "
                  onClick={() => navigate("/incomelist")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className=" fa-solid fa-hand-holding-dollar iconcards iconCOlors"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Income</b>
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div
                  className="card one p-2"
                  onClick={() => navigate("/expenselist")}
                >
                  <div className="d-flex">
                    <div
                      className="card hello"
                      style={{
                        padding: "8px",
                        width: "45px",
                        marginTop: "10px",
                        height: "45px",
                      }}
                    >
                      <i
                        className=" fa-solid fa-scale-unbalanced iconcards iconCOlors"
                        style={{
                          color: "white",

                          fontSize: "22px",
                          border: "none",
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="enq mt-1">
                    <h6 className="name">
                      <b>Expense</b>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* charts */}

        <div className="Row">
          <div className="col-lg-12 pt-2">
            <div className=" charts-container">
              <div className="col-lg-6 px-2">
                <div className="col-lg-12">
                  <h5 className="pb-2">
                    Leads Conversion{" "}
                    <span className="sixmonth">last 6 Month</span>{" "}
                  </h5>
                </div>
                <div className="card studentcard ">
                  {/* <div className="gradients">
                  <StackedAreaChart /> 
                </div> */}
                  <Chart
                    chartType="LineChart" // Use the selected chart type
                    width="100%"
                    height="400px"
                    data={line_chart_data}
                    options={line_chart_options}
                  />
                </div>
              </div>

              <div className="col-lg-6 salescard ">
                <div className="d-flex">
                  <div className="">
                    <h5 className="pb-2">Revenue / Expense</h5>
                  </div>
                  
                </div>
                <div className="card ">
                  {/* <div className="">
                  <DoubleBarChart />
                </div> */}
                  <Chart
                    chartType="ColumnChart" // Use the selected chart type
                    width="100%"
                    height="400px"
                    data={double_bar_data}
                    options={double_bar_options}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* charts end */}

        {/* card part 2 */}


        <div className="row mt-4">
        <div className="col-lg-12">
          <h5 className="">Pending Payment</h5>
          <div className="card p-3 mb-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="card p-3">
                  <h5 className="text-center  text-white p-3" style={{backgroundColor: "#DCC005"}}>
                    Salem
                  </h5>
                  <hr></hr>
                  <div className="card">
                    <div className="row details-data">
                      <div className="col-lg-6 mt-1">
                        <b>Pending Payment</b>
                      </div>
                      <div className="col-lg-6 text-secondary">
                        {/* {paymentoverallDetails?.fees || ""} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card p-3">
                  <h5 className="text-center  text-white p-3"  style={{backgroundColor: "#DCC005"}}>
                    Erode
                  </h5>
                  <hr></hr>
                  <div className="card">
                    <div className="row details-data">
                      <div className="col-lg-6 mt-1">
                        <b>Pending Payment</b>
                      </div>
                      <div className="col-lg-6 text-secondary">
                        {/* {paymentoverallDetails?.fees || ""} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        
      </div>
      </div>
    </>
  );
}

export default Dashboard;
