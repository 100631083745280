import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import apiUrl from "./../Environment/ApiUrl";
import { toast } from "react-toastify";

function Role() {
  const [showModals, setShowModals] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [values, setAll] = useState([]);
  const [deleteName, setDeleteName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const navigate = useNavigate();

  const handleAddRole = () => {
    navigate("/addrole");
  };

  const handleUpdatePopup = (_id) => navigate(`/Editrole/${_id}`);

  const getAll = async () => {
    try {
      const responsed = await axios.get(apiUrl + "/role_2");
      console.log("The response get:", responsed);

      if (responsed && responsed.status === 200) {
        const filteredData = responsed.data
          .map((role) => {
            const filteredModules = role.modules.filter((module) => {
              return Object.values(module.actions).includes(true);
            });
            return { ...role, modules: filteredModules };
          })
          .reverse();

        setAll(filteredData);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleDelete = async () => {
    try {
      console.log("the delete id ", deleteId);
      await axios.delete(apiUrl + `/role_2/${deleteId}`);

      setShowModals(false);
      getAll();
      setDeleteId("");
      setDeleteName("");
      toast.error("Role deleted successfully");
    } catch (err) {
      console.error("Error deleting role:", err);
    }
  };

  const handleRoleDeletePopup = (id, rolename) => {
    setDeleteId(id);
    setDeleteName(rolename);
    setShowModals(true);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = values.slice(indexOfFirstItem, indexOfLastItem);

  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const totalPages = Math.ceil(values.length / itemsPerPage);

  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Roles</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="top mt-4">
        <div className="d-flex align-items-center mb-3">
          <h5 className="mt-2">Roles</h5>

          <button className="btn addBtn add mx-3" onClick={handleAddRole}>
            Add
          </button>
        </div>

        {/* Search and pagination */}
        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show:</label>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => setItemsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-7 offset-lg-7">
              <input
                className="form-control"
                placeholder="Search..."
                type="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="card">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Role Name</th>
                  <th>Module</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{item.rolename}</td>
                    <td>
                      {item.modules?.map((module, idx) => (
                        <span key={idx}>
                          {module.name}
                          {idx !== item.modules.length - 1 && ", "}
                        </span>
                      ))}
                    </td>
                    <td className="d-flex justify-content-center">
                      {/* {item.rolename !== 'superadmin' && previllages.edit && ( */}
                      <button
                        className="btn btn-primary mx-1"
                        onClick={() => handleUpdatePopup(item._id)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      {/* )} */}
                      {/* {item.rolename !== 'superadmin' && previllages.delete && ( */}
                      <button
                        className="btn btn-danger mx-1"
                        onClick={() =>
                          handleRoleDeletePopup(item._id, item.rolename)
                        }
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                      {/* )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-9"></div>
          <div className="col-lg-3">
            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, idx) => (
                  <li
                    key={idx}
                    className={`page-item ${currentPage === idx + 1 ? "" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(idx + 1)}
                    >
                      {idx + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal
        className="modal"
        show={showModals}
        onHide={() => setShowModals(false)}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Role Name:</h5> <h5>{deleteName}</h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModals(false)}
          >
            Back
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Role;
